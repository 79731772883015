import React from 'react';

import { FlyoutMenu, FlyoutMenuIcon, FlyoutMenuItem } from '~/components/shared/FlyoutMenu';
import { PencilIcon } from '~/components/shared/svg';
import { PERMISSIONS, useProfileContext } from '~/services/profile';

export type EditFlyoutMenuProps<T> = {
  menuText?: string;
  onEdit: (value: T) => void;
  permission?: keyof typeof PERMISSIONS;
  width?: string;
  row: {
    original: T;
  };
};

function EditFlyoutMenu<T>(props: EditFlyoutMenuProps<T>) {
  const { onEdit, menuText = 'Edit', permission, row, width } = props;
  const profileSvc = useProfileContext();

  return (
    <FlyoutMenu data={row.original}>
      <FlyoutMenuItem onClick={onEdit} width={width} visible={!permission || profileSvc.has(permission)}>
        <FlyoutMenuIcon>
          <PencilIcon size={18} />
        </FlyoutMenuIcon>
        {menuText}
      </FlyoutMenuItem>
    </FlyoutMenu>
  );
}

export default EditFlyoutMenu;
