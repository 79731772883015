import React, { ReactElement, useContext } from 'react';
import { ErrorMessage, FormikContext } from 'formik';
import styled from 'styled-components';

import Error from './ErrorMessage';
import FieldLabel from './FieldLabel';

type InputGroupProps<InnerComponentProps> = {
  component?: React.ComponentType<InnerComponentProps>;
  getOptionLabel?: (option: any) => string;
  getOptionValue?: (option: any) => any;
  label?: string;
  loading?: boolean;
  name: string;
  onBlur?: (val: string) => void;
  onChange?: InnerComponentProps extends { onChange?: any } ? InnerComponentProps['onChange'] : never;
  onEndReached?: () => void;
  onInputChange?: (inputValue: string) => void;
  visible?: boolean;
  children?: React.ReactNode;
  labelComponent?: (props: any) => React.ReactNode;
  placeholder?: string;
} & InnerComponentProps; //& InnerComponentProps because properties also get passed along to inner component

function InputGroup<InnerComponentProps>(props: InputGroupProps<InnerComponentProps>) {
  const { children, loading, onChange, onBlur, ...rest } = props;
  const { label = '', name, component, labelComponent, visible = true } = rest;

  let ComponentObject: React.ComponentType<any> | undefined = undefined;

  if (component) {
    ComponentObject = component;
  }

  const LabelComponentObject = labelComponent || FieldLabel;
  const context = useContext(FormikContext);

  return (
    <InputGroupContainer {...rest} visible={visible}>
      {!!label && <LabelComponentObject {...rest}>{label}</LabelComponentObject>}

      {ComponentObject && (
        <ComponentObject loading={loading} onBlur={onBlur} onChange={onChange} {...rest} visible={visible} />
      )}
      {React.Children.map(children, (child) => {
        if (typeof child === 'string') {
          return child;
        }
        return React.cloneElement(child as React.ReactElement, {
          loading,
          onBlur,
          onChange,
          ...rest,
          ...(child as ReactElement<any>).props,
        });
      })}

      {context && (
        <ErrorMessageContainer>
          <ErrorMessage name={name} component={Error} />
        </ErrorMessageContainer>
      )}
    </InputGroupContainer>
  );
}

export default InputGroup;

const InputGroupContainer = styled.div<{ visible?: boolean }>`
  display: ${({ visible }) => (visible ? 'initial' : 'none')};
  width: 100%;
`;

export const ErrorMessageContainer = styled.div`
  margin-top: 6px;
  min-height: 20px;
  white-space: pre-line;
`;
