import React, { useMemo } from 'react';
import styled from 'styled-components';

import { CompletedStatusIcon, ErrorStatusIcon, PendingStatusIcon } from '~/components/shared/svg';
import { BodySmallBold, Label } from '~/components/shared/typography';
import { IMPORT_PROCESS_STEPS, ImportStepStatus } from '~/constants/importSteps';
import { capitalize } from '~/helpers';
import { ImportStep } from '~/models/imports';
import { colors } from '~/styles/theme';

import StatusStepResults from './StatusStepResults';

const STATUS_ICON_MAP = {
  [ImportStepStatus.COMPLETED]: CompletedStatusIcon,
  [ImportStepStatus.ERROR]: ErrorStatusIcon,
  [ImportStepStatus.RUNNING]: PendingStatusIcon,
  [ImportStepStatus.PENDING]: PendingStatusIcon,
};

export interface StatusDetailsCardProps {
  step: ImportStep;
}

function StatusDetailsCard({ step }: StatusDetailsCardProps) {
  const { apiType, status } = step;

  const stepStatus = capitalize(status);
  const formattedImportProcessType = IMPORT_PROCESS_STEPS[apiType as keyof typeof IMPORT_PROCESS_STEPS];
  const Icon = useMemo(() => {
    return STATUS_ICON_MAP[status as keyof typeof STATUS_ICON_MAP] || PendingStatusIcon;
  }, [status]);

  return (
    <CardContainer>
      <HeaderContainer data-cy='statusDetailsCardHeader'>
        <IconContainer>
          <Icon />
        </IconContainer>
        <div>
          <BodySmallBold>{formattedImportProcessType}</BodySmallBold>
          <StyledFullWidthLabel color={colors.black50}>{stepStatus}</StyledFullWidthLabel>
        </div>
      </HeaderContainer>
      {status && step.hasResults && <StatusStepResults step={step} />}
    </CardContainer>
  );
}

export default StatusDetailsCard;

const CardContainer = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.black10};
  border-radius: 4px;
  width: 500px;
  margin-bottom: 8px;
`;

const HeaderContainer = styled.div`
  padding: 24px;
  display: flex;
`;

export const IconContainer = styled.div`
  margin-right: 16px;
`;

export const StyledFullWidthLabel = styled(Label)`
  width: 100%;
  margin-top: 8px;
`;
