import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import {
  Form,
  FormHeader,
  FormSection,
  Input,
  InputGroup,
  MultiSelect,
  MultiSelectLabel,
  RadioButtonGroup,
  SectionHeader,
  Select,
} from '~/components/shared/form';
import { OWNER_CLIENT_TYPES } from '~/constants/clientTypes';
import { PROVIDER } from '~/constants/groupTypes';
import { SKILLED_NURSING_FACILITY } from '~/constants/locationTypes';
import { fetchClients } from '~/ducks/admin/clients';
import { fetchGroupTypes } from '~/ducks/admin/groupTypes';
import { fetchLocationTypes } from '~/ducks/admin/locationTypes';
import { fetchRoles } from '~/ducks/admin/roles';
import { fetchCredentials } from '~/ducks/credentials';
import { addToast } from '~/ducks/toasts';
import { getDisplayName, getId, getKind, getName } from '~/helpers';
import { useAsyncGroupOptions, useAsyncOptions, useDeepEffect, useThunk } from '~/lib/hooks';
import { usePrevious } from '~/lib/hooks';

const mapDispatchToProps = {
  addToast,
};

const connector = connect(null, mapDispatchToProps);

export type UserFormProps = ConnectedProps<typeof connector> & {
  onCancel: () => void;
};

function UserForm(props: UserFormProps) {
  const { onCancel } = props;

  const {
    dirty,
    initialValues,
    isSubmitting,
    isValid,
    setFieldTouched,
    setFieldValue,
    setTouched,
    setValues,
    status: { isEdit },
    submitForm,
    touched,
    values,
  } = useFormikContext<any>();

  const { client, enabledProviderTypes, groupType, isAdmin, selectedGroups, selectedProviderTypes } = values;

  const credentialFieldName = 'credential';
  const emailFieldName = 'email';
  const groupTypeFieldName = 'groupType';
  const groupsFieldName = 'selectedGroups';
  const nameFieldName = 'name';
  const actingClientFieldName = 'actingClient';
  const roleFieldName = 'role';
  const selectedProviderTypesFieldName = 'selectedProviderTypes';
  const forbiddenRoles = ['Olio Admin', 'View Only Olio Admin', 'Bot'];
  const forbiddenRolesAdmin = ['Bot'];
  const clientInitialValueChanged = initialValues?.client !== client;
  const groupTypeInitialValueChanged = initialValues?.groupType !== groupType;

  const { data: credentials } = useThunk(fetchCredentials);
  const asyncClientOptions = useAsyncOptions(fetchClients);
  const asyncGroupOptions = useAsyncGroupOptions({
    condition: !isAdmin && client?.id && Boolean(groupType),
    params: {
      clientId: client?.id,
      // filter by `groupType` if the client is a provider
      // filter by `type` if the client is a manager
      type: client?.isPostAcute ? undefined : groupType,
      groupType: client?.isPostAcute ? enabledProviderTypes?.map((provider: any) => provider.id) : undefined,
      include: 'groupType',
    },
  });

  const asyncRoleOptions = useAsyncOptions(fetchRoles, {
    params: {
      'name.not': (client?.isAdmin ? forbiddenRolesAdmin : forbiddenRoles).join(','),
    },
  });

  const asyncProviderOptions = useAsyncOptions(fetchGroupTypes, {
    condition: !isAdmin && !!client?.leaf,
    params: {
      type: PROVIDER,
      client: client?.id,
    },
  });

  const { data: groupTypes, setData: setGroupTypes } = useThunk(fetchLocationTypes, [client?.id], {
    condition: Boolean(client?.id),
    params: { clientId: client?.id },
  });

  const isDisabled = !dirty || !isValid || isSubmitting;
  const showGroupType = groupTypes.length > 0 && !client?.isPostAcute;

  const setField = (fieldName: string, value: any) => {
    setFieldValue(fieldName, value, false);
    setFieldTouched(fieldName, false, false);
  };

  const handleSaveAndAddAnother = () => {
    setFieldValue('shouldNavigate', false, false);
    submitForm().then(() => {
      setValues({
        ...values,
        [nameFieldName]: '',
        [credentialFieldName]: '',
        [emailFieldName]: '',
      });

      setTouched({
        ...touched,
        [nameFieldName]: false,
        [credentialFieldName]: false,
        [emailFieldName]: false,
      });
    });
  };

  const handleCreateUser = () => {
    setFieldValue('shouldNavigate', true, false);
    submitForm();
  };

  useDeepEffect(() => {
    if (!isEdit || (isEdit && clientInitialValueChanged)) {
      if (client?.isPostAcute) {
        setField(groupTypeFieldName, SKILLED_NURSING_FACILITY);
      } else {
        setField(groupTypeFieldName, null);
      }
      setField(actingClientFieldName, null);
      setField(groupsFieldName, []);
    }
  }, [client]);

  useDeepEffect(() => {
    if (!isEdit || (isEdit && groupTypeInitialValueChanged)) {
      setField(groupsFieldName, []);
      setField(roleFieldName, null);
    }
  }, [groupType]);

  useDeepEffect(() => {
    if (groupTypes.some((type: any) => type.isSNF)) {
      setGroupTypes(groupTypes.reverse());

      if (!groupType) {
        setFieldValue(groupTypeFieldName, SKILLED_NURSING_FACILITY);
      }
    }
  }, [groupTypes]);

  const prevSelectedProviderTypes = usePrevious(selectedProviderTypes);

  useEffect(() => {
    if (prevSelectedProviderTypes?.length > selectedProviderTypes?.length) {
      const selectedProviderTypesIds = new Set(selectedProviderTypes.map(({ id }: any) => id));

      setValues({
        ...values,
        selectedGroups: selectedGroups.filter(({ groupTypeId }: any) => selectedProviderTypesIds.has(groupTypeId)),
      });
    }
  }, [selectedProviderTypes]);

  const byTitle = ({ title }: any) => title;

  return (
    <Form>
      <FormHeader title={isEdit ? 'Edit User' : 'New User'} />
      <FormSection>
        <SectionHeader>Contact Information</SectionHeader>
        <InputGroup name={nameFieldName} label='Name' placeholder='User name' component={Input} />
        <InputGroup
          name={credentialFieldName}
          isClearable
          label='Credentials (optional)'
          data-cy='credential'
          placeholder='Select credentials'
          options={credentials}
          getOptionLabel={byTitle}
          getOptionValue={getId}
          component={Select}
        />
        <InputGroup name={emailFieldName} label='Email' placeholder='your_email@example.com' component={Input} />
      </FormSection>
      <FormSection>
        <SectionHeader>User Access</SectionHeader>

        <InputGroup
          {...asyncClientOptions}
          name='client'
          label='Client'
          data-cy='client'
          placeholder='Select client'
          getOptionLabel={getName}
          getOptionValue={getId}
          component={Select}
        />

        <InputGroup
          disabled
          options={[]}
          name={actingClientFieldName}
          label='Current Selected View'
          data-cy={actingClientFieldName}
          placeholder='Select view'
          getOptionLabel={getName}
          getOptionValue={getId}
          component={Select}
          visible={isEdit}
        />

        <InputGroup
          {...asyncRoleOptions}
          name={roleFieldName}
          label='Role'
          data-cy={roleFieldName}
          disabled={!values.client}
          placeholder='Select role'
          getOptionLabel={getName}
          getOptionValue={getId}
          component={Select}
        />

        <InputGroup
          name={groupTypeFieldName}
          disabled={!groupTypes.length}
          label='What type of group is the user associated with?'
          data-cy={groupTypeFieldName}
          getOptionLabel={getName}
          getOptionValue={getKind}
          options={groupTypes}
          component={RadioButtonGroup}
          visible={showGroupType}
        />

        <InputGroup
          {...asyncProviderOptions}
          name={selectedProviderTypesFieldName}
          data-cy={selectedProviderTypesFieldName}
          label='Associated Care Options'
          placeholder='All Care Options'
          getOptionLabel={getDisplayName}
          getOptionValue={getId}
          component={MultiSelect}
          labelComponent={MultiSelectLabel}
          selectedCountDefault='All'
          visible={client?.isAcute ? OWNER_CLIENT_TYPES.includes(client?.clientType) : !isAdmin && !!client?.leaf}
          closeMenuOnSelect={false}
        />

        <InputGroup
          {...asyncGroupOptions}
          closeMenuOnSelect={false}
          name={groupsFieldName}
          label='Associated Groups (select all that apply)'
          placeholder='All Groups'
          data-cy='groups'
          component={MultiSelect}
          labelComponent={MultiSelectLabel}
          selectedCountDefault={'All'}
          visible={!isAdmin && !!client?.leaf}
        />
      </FormSection>
      <StyledButtonGroup isEdit={isEdit}>
        {!isEdit && (
          <Button
            color='primaryWhite'
            data-cy='saveAndAddAnotherButton'
            onClick={handleSaveAndAddAnother}
            disabled={isDisabled}
            text='Save and Add Another'
          />
        )}
        <ButtonGroup>
          <Button color='transparent' onClick={onCancel} text='Cancel' />
          <Button onClick={handleCreateUser} disabled={isDisabled} text={isEdit ? 'Update User' : 'Create User'} />
        </ButtonGroup>
      </StyledButtonGroup>
    </Form>
  );
}

const StyledButtonGroup = styled.div<{ isEdit: boolean }>`
  display: flex;
  justify-content: ${({ isEdit }) => (isEdit ? 'end' : 'space-between')};
  width: 100%;
`;

export default connector(UserForm);
