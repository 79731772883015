import React, { useContext } from 'react';
import * as _ from 'lodash-es';

import { PERMISSIONS, ProfileContext } from '~/services/profile';

type ProtectedRouteElementProps<InnerRouteProps> = {
  component: React.ComponentType<InnerRouteProps>;
  flag?: string;
  permissions?: keyof typeof PERMISSIONS | (keyof typeof PERMISSIONS)[];
  shouldDeny?: boolean;
} & InnerRouteProps;

function ProtectedRouteElement<InnerRouteProps>({
  component,
  flag,
  permissions,
  shouldDeny,
  ...rest
}: ProtectedRouteElementProps<InnerRouteProps>) {
  const Component = component as any;
  const permissionsService = useContext(ProfileContext);
  const permissionSet = _.castArray(permissions);

  if (!permissionsService.hasAll(permissionSet)) {
    return null;
  }

  if (flag && !permissionsService.hasFlag(flag)) {
    return null;
  }

  if (shouldDeny) {
    return null;
  }

  return <Component {...rest} />;
}

export default ProtectedRouteElement;
