import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash-es';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button } from '~/components/shared/buttons';
import { MainPage } from '~/components/shared/pageLayout';
import { PlusIcon } from '~/components/shared/svg';
import TabFilters from '~/components/shared/TabFilters';
import { ControlledTable } from '~/components/shared/table';
import { ACUTE_CLIENT_TYPES } from '~/constants/clientTypes';
import { PROGRESS } from '~/constants/questionTemplateTypes';
import { fetchClassifications } from '~/ducks/admin/classifications';
import { fetchClients } from '~/ducks/admin/clients';
import {
  clearFilters,
  getProgressTemplatesFilters,
  getProgressTemplatesFiltersForRequest,
  setFilter,
} from '~/ducks/progressTemplatesFilters';
import {
  clearQuestionTemplates,
  fetchQuestionTemplates,
  getQuestionTemplates,
  getQuestionTemplatesLoaded,
  getQuestionTemplatesPageCount,
} from '~/ducks/questionTemplates';
import { useModel, useThunk } from '~/lib/hooks';
import useTabs, { TabDef } from '~/lib/hooks/useTabs';
import { QuestionTemplate } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import colors from '~/styles/theme/colors';

import ProgressTemplatesFilterBar from './ProgressTemplatesFilterBar';
import progressTemplatesTableColumns from './progressTemplatesTableColumns';

const mapStateToProps = (state: any) => ({
  filters: getProgressTemplatesFilters(state),
  filtersForRequest: getProgressTemplatesFiltersForRequest(state),
  loaded: getQuestionTemplatesLoaded(state),
  pageCount: getQuestionTemplatesPageCount(state),
  templates: getQuestionTemplates(state),
});

const mapDispatchToProps = {
  clearFilters,
  clearQuestionTemplates,
  fetchQuestionTemplates,
  setFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ProgressTemplatesProps = ConnectedProps<typeof connector> & {
  tabDefs: TabDef[];
};

function ProgressTemplates({
  filters,
  filtersForRequest,
  loaded,
  tabDefs,
  pageCount,
  ...props
}: ProgressTemplatesProps) {
  const navigate = useNavigate();
  const profileSvc = useProfileContext();
  const canAddTemplates = profileSvc.has(PERMISSIONS.adminQuestionTemplateCreate);
  const { data: clients, loaded: clientsLoaded } = useThunk(fetchClients, [], {
    params: { clientType: ACUTE_CLIENT_TYPES.join(',') },
  });
  const { data: classifications, loaded: classificationsLoaded } = useThunk(fetchClassifications);
  const [pagingFilters, setPagingFilters] = useState({});
  const optionsLoaded = clientsLoaded && classificationsLoaded;
  const debouncedFetchQuestionTemplates = useCallback(
    _.debounce((params) => props.fetchQuestionTemplates(params), 50),
    []
  );
  const templates = useModel(QuestionTemplate, props.templates);
  const baseUrl = '/question-templates/progress';
  const handleAdd = useCallback(() => navigate(`${baseUrl}/new`), []);
  const handleEdit = useCallback((template: any) => {
    navigate(`${baseUrl}/${template.id}/edit`);
  }, []);

  useEffect(() => {
    props.clearQuestionTemplates();
    debouncedFetchQuestionTemplates({ ...pagingFilters, ...filtersForRequest, type: PROGRESS });
  }, [pagingFilters, filtersForRequest]);

  const handlePagingFiltersChange = useCallback((newPagingFilters: any) => {
    setPagingFilters(newPagingFilters);
  }, []);

  const columns = useMemo(() => progressTemplatesTableColumns({ profileSvc, onEdit: handleEdit }), []);

  const addTemplateButton = (
    <Button onClick={handleAdd} text='Add Template' icon={<PlusIcon size={14} fill={colors.white} />} />
  );

  const tabinfo = useTabs(tabDefs, 'Progress Templates');

  return (
    <MainPage
      title='Question Templates'
      headerWhite
      headerContent={
        <Fragment>
          <ProgressTemplatesFilterBar
            clearFilters={props.clearFilters}
            clients={clients}
            classifications={classifications}
            filters={filters}
            optionsLoaded={optionsLoaded}
            setFilter={props.setFilter}
          />
          <TabFilters {...tabinfo} />
        </Fragment>
      }
      rightContent={canAddTemplates && addTemplateButton}>
      <ControlledTable
        data={templates}
        defaultSortBy={'clientName asc'}
        loading={!loaded}
        columns={columns}
        filters={filters}
        onPagingFiltersChange={handlePagingFiltersChange}
        pageCount={pageCount}
      />
    </MainPage>
  );
}

export default connector(ProgressTemplates);
