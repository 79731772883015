import React from 'react';
import styled from 'styled-components';

import colors from '~/styles/theme/colors';

import { Label } from './typography';

export const ICON_ALIGN = {
  left: 'left',
  right: 'right',
};

type ChipProps = {
  backgroundColor?: string;
  bold?: boolean;
  className?: string;
  fontSize?: string;
  icon?: React.ReactElement;
  iconAlign?: keyof typeof ICON_ALIGN;
  iconCursor?: string;
  iconPadding?: string;
  label: string;
  lineHeight?: string;
  onClick?: () => void;
  padding?: string;
  textColor?: string;
  textMargin?: string;
  textPadding?: string;
  truncateText?: boolean;
};

const Chip: React.FC<ChipProps> = (props) => {
  const {
    className,
    label,
    icon,
    iconCursor,
    iconPadding,
    bold,
    fontSize,
    padding,
    onClick,
    backgroundColor = colors.secondaryOrange,
    textColor = colors.white,
    textMargin = '4px',
    textPadding = '0px',
    truncateText = true,
    lineHeight = '16px',
    iconAlign = ICON_ALIGN.left as keyof typeof ICON_ALIGN,
  } = props;

  const chipTextProps = {
    bold,
    color: textColor,
    fontSize,
    icon,
    iconAlign,
    lineHeight,
    textMargin,
    textPadding,
    truncateText,
  };

  const iconContainer = (
    <IconContainer cursor={iconCursor} iconAlign={iconAlign} iconPadding={iconPadding} onClick={onClick}>
      {icon}
    </IconContainer>
  );

  return (
    <ChipContainer className={className} padding={padding} color={backgroundColor}>
      {icon && iconAlign === ICON_ALIGN.left && iconContainer}
      <ChipText {...chipTextProps}>{label}</ChipText>
      {icon && iconAlign === ICON_ALIGN.right && iconContainer}
    </ChipContainer>
  );
};

export default Chip;

const ChipContainer = styled.div<{
  padding?: string;
  color?: string;
}>`
  display: flex;
  align-items: center;
  background-color: ${({ color }) => color};
  border-radius: 24px;
  padding: ${({ padding }) => padding};
  align-self: flex-start;
`;

const ChipText = styled(Label)<{
  fontSize?: string;
  truncateText?: boolean;
  icon?: React.ReactElement | (() => React.ReactElement);
  iconAlign: keyof typeof ICON_ALIGN;
  textMargin?: string;
  bold?: boolean;
  textPadding?: string;
}>`
  display: block;
  font-size: ${({ fontSize }) => fontSize};

  ${({ truncateText }) =>
    truncateText &&
    `
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  `};

  ${({ icon, iconAlign, textMargin }) =>
    icon &&
    `
    margin-${iconAlign}: ${textMargin};
  `};

  ${({ bold }) =>
    bold &&
    `
    font-weight: 700;
  `};

  ${({ iconAlign, textPadding }) =>
    iconAlign === ICON_ALIGN.left &&
    `
    padding-right: ${textPadding};
  `};

  ${({ iconAlign, textPadding }) =>
    iconAlign === ICON_ALIGN.right &&
    `
    padding-left: ${textPadding};
  `};
`;

const IconContainer = styled.div<{
  cursor?: string;
  iconAlign: keyof typeof ICON_ALIGN;
  iconPadding?: string;
}>`
  display: flex;
  cursor: ${({ cursor }) => cursor};

  ${({ iconAlign, iconPadding }) =>
    iconAlign === ICON_ALIGN.left &&
    `
    padding-right: ${iconPadding};
  `};

  ${({ iconAlign, iconPadding }) =>
    iconAlign === ICON_ALIGN.right &&
    `
    padding-left: ${iconPadding};
  `};
`;
