/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash-es';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SortingState } from '@tanstack/react-table';

import { ControlledTable } from '~/components/shared/table';
import { SEARCH } from '~/constants/filterKeysConstants';
import { fetchAttrs, getAttrs, getAttrsLoaded, getAttrsPageCount, getAttrsTotalRecords } from '~/ducks/admin/attrs';
import { clearFilters, getAttrsFilters, getAttrsFiltersForRequest, setFilter } from '~/ducks/admin/attrsFilters';
import { useModel } from '~/lib/hooks';
import { Attr } from '~/models';
import { useProfileContext } from '~/services/profile';

import attributesTableColumns from './attributesTableColumns';

const mapStateToProps = (state: any) => ({
  attributes: getAttrs(state),
  loaded: getAttrsLoaded(state),
  pageCount: getAttrsPageCount(state),
  filters: getAttrsFilters(state),
  filtersForRequest: getAttrsFiltersForRequest(state),
  totalRecords: getAttrsTotalRecords(state),
});

const mapDispatchToProps = {
  fetchAttrs,
  clearFilters,
  setFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type AttributesTableProps = ConnectedProps<typeof connector>;

function AttributesTable({ ...props }: AttributesTableProps) {
  const profileSvc = useProfileContext();
  const [pagingFilters, setPagingFilters] = useState({});
  const [tableProps, setTableProps] = useState({ setSorting: (_sort: SortingState) => {} });
  const debouncedFetchAttrs = useCallback(
    _.debounce((params: any) => props.fetchAttrs(params), 50),
    []
  );
  const attributes = useModel(Attr, props.attributes);
  const navigate = useNavigate();

  useEffect(() => {
    debouncedFetchAttrs({
      ...pagingFilters,
      ...props.filtersForRequest,
      include: 'client,name',
    });
  }, [pagingFilters, props.filtersForRequest]);

  const handlePagingFiltersChange = useCallback(
    (newPagingFilters: { page: number; pageSize: number; sortBy: string }) => {
      setPagingFilters(newPagingFilters);
    },
    []
  );

  const handleEditAttr = (attr: Attr) => navigate(`/attributes/${attr.id}/edit`);

  const columns = useMemo(
    () =>
      attributesTableColumns({
        profileSvc,
        onEdit: handleEditAttr,
      }),
    []
  );

  const defaultSortBy = [{ id: 'name', desc: false }];
  const searchValue: any = props.filters[SEARCH as keyof typeof props.filters];

  useEffect(() => {
    const sortBy = searchValue ? [{ id: SEARCH, desc: true }] : defaultSortBy;

    tableProps.setSorting(sortBy);
  }, [searchValue]);

  return (
    <ControlledTable<Attr>
      data={attributes}
      defaultSortBy={'name asc'}
      loading={!props.loaded}
      onInitialize={setTableProps}
      columns={columns}
      filters={props.filters}
      onPagingFiltersChange={handlePagingFiltersChange}
      pageCount={props.pageCount}
    />
  );
}

export default connector(AttributesTable);
