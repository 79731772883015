import React, { ReactNode, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import styled from 'styled-components';

import menuIcon from '~/assets/images/menu.png';
import menuIconHorizontal from '~/assets/images/menu-horizontal.svg';

import { FlyoutMenuItemProps } from './FlyoutMenuItem';

export const HORIZONTAL = 'horizontal';
export const VERTICAL = 'vertical';

export type FlyoutMenuProps<T> = {
  children: ReactNode;
  data: T;
  iconOrientation?: typeof HORIZONTAL | typeof VERTICAL;
};

const isHorizontal = (iconOrientation: typeof HORIZONTAL | typeof VERTICAL) => iconOrientation === HORIZONTAL;

function FlyoutMenu<T>({ children, data, iconOrientation = VERTICAL }: FlyoutMenuProps<T>) {
  const [showFlyoutMenu, setShowFlyoutMenu] = useState(false);

  const handleMenuClick = () => {
    setShowFlyoutMenu(!showFlyoutMenu);
  };

  const handleHideFlyoutMenu = () => {
    setShowFlyoutMenu(false);
  };

  return (
    /* Need the div so menu items position correctly */
    <div data-cy={'flyoutMenu'}>
      <Icon
        onClick={handleMenuClick}
        iconOrientation={iconOrientation}
        src={isHorizontal(iconOrientation) ? menuIconHorizontal : menuIcon}
      />

      {showFlyoutMenu && (
        <OutsideClickHandler onOutsideClick={handleHideFlyoutMenu}>
          <FlyoutMenuItems
            iconOrientation={iconOrientation}
            onMouseLeave={handleHideFlyoutMenu}
            data-cy={'flyoutMenuItems'}>
            {React.Children.map(
              children as React.ReactElement<FlyoutMenuItemProps<T>>[],
              (child) =>
                child &&
                React.cloneElement(child, {
                  onClick: () => {
                    child.props.onClick?.(data);
                    handleHideFlyoutMenu();
                  },
                })
            )}
          </FlyoutMenuItems>
        </OutsideClickHandler>
      )}
    </div>
  );
}

export default FlyoutMenu;

const Icon = styled.img<{
  iconOrientation: typeof HORIZONTAL | typeof VERTICAL;
}>`
  cursor: pointer;
  width: 12px;
`;

const FlyoutMenuItems = styled.div<{
  iconOrientation: typeof HORIZONTAL | typeof VERTICAL;
}>`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.black10};
  border-radius: 3px;
  box-shadow: 0px 6px 16px -8px ${({ theme }) => theme.colors.black25};
  position: absolute;
  right: ${({ iconOrientation }) => (isHorizontal(iconOrientation) ? '5px' : '10px')};
  text-align: left;
  z-index: 100;

  & > * {
    padding: 12px;
  }

  & > *:first-child {
    margin-top: 0px;
  }

  & > *:last-child {
    margin-bottom: 0px;
  }
`;
