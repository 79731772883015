import React from 'react';
import { Form as FormikForm } from 'formik';
import styled, { css } from 'styled-components';

const formStyles = css<{ maxWidth: number }>`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || '640px'};
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: flex-end;
`;

export const FormContainer = styled.div`
  ${formStyles};
`;

const Form = styled(FormikForm)`
  ${formStyles};
` as unknown as React.FC<
  Omit<
    React.ComponentProps<typeof FormikForm>,
    'placeholder' | 'onPointerEnterCapture' | 'onPointerLeaveCapture' //react 19 issue
  >
>;

export default Form;
