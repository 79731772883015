import React, { useCallback } from 'react';
import styled from 'styled-components';

import { colors } from '~/styles/theme';

import { BodySmall } from '../typography';

export type TabType = {
  label: string;
  value: string;
};

interface TabProps {
  active: boolean;
  onClick: (tab: TabType) => void;
  tab: TabType;
}

function Tab({ tab, active = false, onClick }: TabProps) {
  const { label, ...rest } = tab;

  const handleClick = useCallback(() => {
    onClick(tab);
  }, [onClick, tab]);

  return (
    <TabContainer onClick={handleClick} active={active} {...rest}>
      <BodySmall color={active ? colors.primaryBlue : colors.black} fontWeight={active ? 'bold' : 'inherit'}>
        {label}
      </BodySmall>
    </TabContainer>
  );
}

export default Tab;

const TabContainer = styled.div<{ active: boolean }>`
  cursor: pointer;
  padding: 10px 10px ${({ active }) => (active ? '7px' : '10px')} 10px;
  border-bottom: ${({ active }) => (active ? `3px solid ${colors.primaryBlue}` : 'none')};
`;
