import React from 'react';
import { useFormikContext } from 'formik';

import { getProp } from '~/helpers';

import FieldLabel from '../FieldLabel';

interface MultiSelectLabelProps {
  getSelectedCountLabel?: (selectedCount: number) => string;
  name?: string;
  selectedCountDefault?: number | string;
  children: React.ReactNode;
}

function MultiSelectLabel({
  getSelectedCountLabel = (selectedCount: number) => `${selectedCount} selected`,
  name = '',
  selectedCountDefault = 0,
  children,
}: MultiSelectLabelProps) {
  const { values } = useFormikContext();
  const selectedCount = getProp(values, name)?.length || selectedCountDefault;
  const selectedCountLabel = getSelectedCountLabel(selectedCount as number);
  const fullLabel = `${children}${children && selectedCountLabel && ' - '}${selectedCountLabel}`;

  return <FieldLabel name={name}>{fullLabel}</FieldLabel>;
}

export default MultiSelectLabel;
