import React from 'react';
import styled from 'styled-components';

import { useDeepEffect } from '~/lib/hooks';
import useField from '~/lib/hooks/useField';

interface ToggleGroupProps {
  autoSelectFirstOption?: boolean;
  disabled?: boolean;
  getOptionLabel: (option: any) => string;
  getOptionValue: (option: any) => string;
  hasError?: boolean;
  menuPlacement?: string;
  onChange: (val: any, name: string, setValue: (value: any) => void) => void;
  options: Array<{ label: string; value: string }>;
  visible?: boolean;
}

function ToggleGroup(props: ToggleGroupProps) {
  const {
    autoSelectFirstOption = true,
    disabled = false,
    getOptionLabel = (option) => option?.label,
    getOptionValue = (option) => option?.value,
    onChange = () => undefined,
    options,
    visible = true,
  } = props;

  const [field, meta, helpers] = useField(props);
  const { name } = field;

  const handleChange = (val: any) => {
    if (disabled) return;

    helpers.setValue(val);
    onChange(val, name, helpers.setValue);
  };

  const areEqual = (option: any, otherOption: any) =>
    option && otherOption && getOptionValue(option) === getOptionValue(otherOption);
  const handleOptionsChange = () => {
    const optionValueChanged = !(
      areEqual(meta.value, options[0]) ||
      (options.length && areEqual(meta.value, getOptionValue(options[0])))
    );

    if (autoSelectFirstOption && options.length > 0 && optionValueChanged) {
      handleChange(options[0]);
    }
  };

  useDeepEffect(handleOptionsChange, [autoSelectFirstOption, options, disabled]);

  const renderButton = (option: any) => {
    const optionValue = getOptionValue(option);
    const selectedValue = (meta.value && getOptionValue(meta.value)) || meta.value;
    const handleOnClick = () => handleChange(option);

    return (
      <Button
        key={optionValue}
        className={(!disabled && optionValue === selectedValue && 'active') || ''}
        data-cy={optionValue}
        disabled={disabled}
        onClick={handleOnClick}>
        {getOptionLabel(option)}
      </Button>
    );
  };

  return <Container>{visible && options.map((option) => renderButton(option))}</Container>;
}

export default ToggleGroup;

const Container = styled.div`
  display: flex;
  width: 100%;
  border-radius: 4px;
`;

const Button = styled.div<{
  disabled: boolean;
}>`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 40px;
  color: ${({ disabled, theme }) => (disabled ? theme.colors.black25 : theme.colors.black)};
  background-color: ${({ disabled, theme }) => (disabled ? theme.colors.black10 : theme.colors.white)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-size: 16px;
  font-weight: bold;
  border-right: 1px solid;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: ${({ disabled, theme }) => (disabled ? theme.colors.black10 : theme.colors.black25)};
  &:first-child {
    border-left: 1px solid ${({ disabled, theme }) => (disabled ? theme.colors.black10 : theme.colors.black25)};
    border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
  }
  &:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  &.active {
    background-color: ${({ theme }) => theme.colors.primaryBlue};
    border-color: ${({ theme }) => theme.colors.primaryBlue};
    color: ${({ theme }) => theme.colors.white};
  }
`;
