import * as Yup from 'yup';

import { AFFILIATE, PHYSICIAN_TEAM } from '~/constants/locationTypes';

export default () =>
  Yup.lazy(({ groupType }) => {
    return Yup.object().shape({
      name: Yup.string().required('Group name required'),
      address: [PHYSICIAN_TEAM, AFFILIATE].includes(groupType?.apiName)
        ? null
        : Yup.object().shape({
            streetAddress: Yup.string().required('Street address required'),
            streetAddressLine2: Yup.string().nullable(),
            city: Yup.string().required('City is required'),
            state: Yup.object()
              .shape({ value: Yup.string() })
              .test('state', 'State is required', ({ value }) => !!value),
            zip: Yup.string().required('Zip code is required'),
          }),
      client: Yup.object()
        .shape({
          id: Yup.string(),
          name: Yup.string(),
        })
        .test('client', 'Client is required', ({ id }) => !!id),
      groupType: Yup.object()
        .shape({
          id: Yup.string(),
          displayName: Yup.string(),
        })
        .test('groupType', 'Group Type is required', ({ id }) => !!id),
      networkClients: Yup.array()
        .of(
          Yup.object().shape({
            id: Yup.string(),
            name: Yup.string(),
          })
        )
        .test(
          'networkClients',
          'Group Client cannot be in Associated Clients',
          (value, context) => !value.map((client) => client.id).includes(context.parent.client.id)
        ),
      timezone: Yup.object()
        .shape({
          value: Yup.string(),
        })
        .test('timezone', 'Timezone is required', ({ value }) => !!value),
    });
  });
