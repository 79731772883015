import QuestionInput, { Form, QuestionInputProps } from './QuestionInput';

type Props = QuestionInputProps & {
  form: Form;
};

const YesNoQuestion = (props: Props) => {
  const {
    field: { name, value },
    form,
  } = props;

  const handleConfigChange = (inputVal: string) => {
    form.setFieldValue(name, { ...value, config: { positive: inputVal } });
  };

  return (
    <QuestionInput
      {...props}
      configLabel='Positive Answer'
      configName='positive'
      handleConfigChange={handleConfigChange}
    />
  );
};

export default YesNoQuestion;
