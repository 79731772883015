import React from 'react';

import theme from '~/styles/theme';

interface ResendInvitationIconProps {
  fill?: string;
  secondaryFill?: string;
  size?: number;
}

const ResendInvitationIcon: React.FC<ResendInvitationIconProps> = ({ fill = theme.colors.black, size = 12 }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <title>icon / send</title>
      <g id='Iconography' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' opacity='0.5'>
        <g id='icons' transform='translate(-316.000000, -166.000000)' fill={fill}>
          <g id='icon-/-send' transform='translate(316.000000, 166.000000)'>
            <path
              d='M0.433001581,10.6884574 L3.24460546,12.8181658 L13.0639438,8.4540093 L5.45177452,14.4536335 L5.45177452,21.8170566 C5.45224624,22.2751973 5.73888623,22.6842429 6.16935082,22.841064 C6.59981541,22.997885 7.08239994,22.8690728 7.37745587,22.5185947 L10.7924035,18.4610202 L17.8896031,23.780927 C18.1888022,24.0059379 18.5824728,24.0618346 18.9324812,23.9290039 C19.2824896,23.7961732 19.539954,23.4931661 19.6145335,23.1263035 L23.9786839,1.30552091 C24.0574125,0.912825385 23.9146541,0.508562278 23.6067879,0.252386295 C23.2989217,-0.00378968751 22.8754453,-0.0706916349 22.5036011,0.0781018865 L0.682849191,8.80641494 C0.318425358,8.95344166 0.0612220916,9.28508375 0.0095082582,9.67463173 C-0.0422055752,10.0641797 0.119560717,10.4514416 0.433001581,10.6884574 Z'
              id='Fill'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default ResendInvitationIcon;
