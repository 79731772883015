import { createSelector, createSlice } from '@reduxjs/toolkit';

import { ALL_OPTION, CLIENT, EPISODE, GROUP_TYPE, PLAN_TYPE } from '~/constants/filterKeysConstants';
import { rootPathnameChanged } from '~/ducks/navigation';

export const initialState = {
  [CLIENT]: ALL_OPTION,
  [EPISODE]: ALL_OPTION,
  [GROUP_TYPE]: ALL_OPTION,
  [PLAN_TYPE]: ALL_OPTION,
};

const sliceName = 'progressTemplates/filters';

const progressTemplatesFiltersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setFilter: (state, action) => {
      const { filterType, value } = action.payload;

      state[filterType] = value;
    },
    clearFilters: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(rootPathnameChanged, () => initialState);
  },
});

export const getProgressTemplatesFilters = (state) => state[sliceName];

export const getProgressTemplatesFiltersForRequest = createSelector(
  getProgressTemplatesFilters,
  (progressTemplatesFilters) => {
    return Object.entries(progressTemplatesFilters)
      .filter(([_, filter]) => filter.id)
      .reduce((acc, [filterKey, filter]) => ({ ...acc, [filterKey]: `${filter.id}` }), {});
  }
);

export const { clearFilters, setFilter } = progressTemplatesFiltersSlice.actions;

export default progressTemplatesFiltersSlice;
