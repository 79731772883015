import React from 'react';

import colors from '~/styles/theme/colors';

type DetailsIconProps = {
  fill?: string;
  size?: number;
};

const DetailsIcon: React.FC<DetailsIconProps> = ({ fill = colors.black50, size = 24 }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 16 16'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'>
      <g
        id='ProposedMVP_Walkthrough'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'>
        <g id='ImportHistory' transform='translate(-1017.000000, -343.000000)' stroke={fill}>
          <g id='bullet-list-67' transform='translate(1018.000000, 344.000000)'>
            <circle id='Oval' strokeWidth='1.5' cx='1.4' cy='1.4' r='1.4'></circle>
            <circle id='Oval' strokeWidth='1.5' cx='1.4' cy='7' r='1.4'></circle>
            <circle id='Oval' strokeWidth='1.5' cx='1.4' cy='12.6' r='1.4'></circle>
            <line x1='5.6' y1='1.86666667' x2='14' y2='1.86666667' id='Path' strokeWidth='1.75'></line>
            <line x1='5.6' y1='7.46666667' x2='14' y2='7.46666667' id='Path' strokeWidth='1.75'></line>
            <line x1='5.6' y1='13.0666667' x2='14' y2='13.0666667' id='Path' strokeWidth='1.75'></line>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default DetailsIcon;
