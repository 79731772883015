import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { InlineInputGroups, InputGroup, Select } from '~/components/shared/form';
import Search from '~/components/shared/Search';
import { ALL_OPTION, CLIENT, SEARCH } from '~/constants/filterKeysConstants';
import { useDebounce } from '~/lib/hooks';
import { Client } from '~/models';

interface ClassificationFilterBarProps {
  clearFilters: () => void;
  clients: { id: string; name: string }[];
  filters: any;
  setFilter: (filter: { filterType: string; value: any }) => void;
}

function ClassificationFilterBar({ filters, clients, ...props }: ClassificationFilterBarProps) {
  const [search, setSearch] = useState(filters[SEARCH]);
  const debouncedSearch = useDebounce(search);

  const clientOptions = useMemo(() => {
    return [ALL_OPTION, ...clients.map((client) => ({ id: client.id, name: client.name }))] as Client[];
  }, [clients]);

  const updateFilter = (key: string, val: Client) => props.setFilter({ filterType: key, value: val });

  useEffect(() => {
    updateFilter(SEARCH, search);
  }, [debouncedSearch]);

  const handleSearchChange = (e: any) => setSearch(e.currentTarget.value);
  const handleOptionChange = (value: Client, filterType: string) => {
    updateFilter(filterType, value);
  };
  const handleSearchClear = () => setSearch('');

  const byName = ({ name }: { name: string }) => name;
  const byId = ({ id }: Client) => id;

  return (
    <FiltersContainer>
      <StyledSearch value={search} placeholder='Search...' onChange={handleSearchChange} onClear={handleSearchClear} />

      <InputGroup
        label='Client'
        name={CLIENT}
        value={filters[CLIENT]}
        options={clientOptions}
        getOptionLabel={byName}
        getOptionValue={byId}
        onChange={handleOptionChange}
        component={Select<Client>}
      />
    </FiltersContainer>
  );
}

export default ClassificationFilterBar;

const FiltersContainer = styled(InlineInputGroups)`
  & > * {
    max-width: 230px;
    margin-bottom: 24px;
  }
`;

const StyledSearch = styled(Search)`
  margin-right: 24px;
`;
