import { createContext, useContext } from 'react';

import ProfileService, { PERMISSIONS } from './ProfileService';

//this unusual type coercion is not a problem because ProfileContext is always provided with a valid profileService.
const ProfileContext = createContext<ProfileService>(null as unknown as ProfileService);

const useProfileContext = () => useContext(ProfileContext);

export { PERMISSIONS, ProfileContext, ProfileService, useProfileContext };
