import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Button } from '~/components/shared/buttons';
import { MainPage } from '~/components/shared/pageLayout';
import { PlusIcon } from '~/components/shared/svg';
import { ControlledTable } from '~/components/shared/table';
import { fetchClassifications } from '~/ducks/admin/classifications';
import { fetchClients, getClients } from '~/ducks/admin/clients';
import {
  clearFilters,
  getClassificationFilters,
  getClassificationFiltersForRequest,
  setFilter,
} from '~/ducks/classificationFilters';
import { useModel, useThunk } from '~/lib/hooks';
import { Classification, Client } from '~/models';
import { Paginated } from '~/models/Paginated';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import colors from '~/styles/theme/colors';

import ClassificationFilterBar from './ClassificationFilterBar';
import ClassificationModal from './ClassificationModal';
import classificationsTableColumns from './classificationsTableColumns';
import { classificationPageTitle, classificationTitle } from './helpers';

const mapStateToProps = (state: any) => ({
  filters: getClassificationFilters(state),
  filtersForRequest: getClassificationFiltersForRequest(state),
  clients: getClients(state),
});

const mapDispatchToProps = {
  clearFilters,
  fetchClients,
  fetchClassifications,
  setFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ClassificationsProps = ConnectedProps<typeof connector>;

function Classifications(props: ClassificationsProps) {
  const { filters, filtersForRequest } = props;
  const profileSvc = useProfileContext();
  const canAddClassifications = profileSvc.has(PERMISSIONS.adminClassificationCreate);
  let { type } = useParams<{ type: string }>();

  type = type?.replace('-', '_'); //emulate old router behavior

  const addEditTitle = useMemo(() => classificationTitle(type), [type]);
  const pageTitle = useMemo(() => classificationPageTitle(type), [type]);
  const [pageCount, setPageCount] = useState(0);
  const [pagingFilters, setPagingFilters] = useState<object>({});
  const [showModal, setShowModal] = useState(false);
  const [currentClassificationId, setCurrentClassificationId] = useState<string | null>(null);

  const addNewClassification = useCallback(() => setShowModal(true), []);

  const handleEditClassification = useCallback((classification: { id: string }) => {
    setCurrentClassificationId(classification.id);
    setShowModal(true);
  }, []);

  const handleCancel = useCallback(() => {
    setCurrentClassificationId(null);
    setShowModal(false);
  }, []);

  useEffect(() => {
    props.fetchClients(undefined);
  }, []);

  useEffect(() => {
    props.clearFilters();
  }, [type]);

  const { data: classifications, loaded: classificationsLoaded } = useThunk(
    fetchClassifications,
    [pagingFilters, filtersForRequest, type, showModal],
    {
      condition: !showModal,
      onSuccess: (payload: Paginated<Classification>) => {
        setPageCount(payload?.meta?.totalPages);
      },
      params: { ...pagingFilters, ...filtersForRequest, type: type },
      debounce: 50,
    }
  );

  const clients = useModel(Client, props.clients);

  const handlePagingFiltersChange = useCallback((newPagingFilters: object) => {
    setPagingFilters(newPagingFilters);
  }, []);

  const addClassificationButton = (
    <Button
      onClick={addNewClassification}
      icon={<PlusIcon size={14} fill={colors.white} />}
      text={`Add ${addEditTitle}`}
    />
  );

  const columns = useMemo(
    () =>
      classificationsTableColumns({
        profileSvc,
        onEdit: handleEditClassification,
        type: addEditTitle,
      }),
    []
  );

  return (
    <MainPage title={pageTitle} rightContent={canAddClassifications && addClassificationButton}>
      <ClassificationFilterBar
        clearFilters={props.clearFilters}
        clients={clients}
        filters={filters}
        setFilter={props.setFilter}
      />
      <ControlledTable
        data={classifications}
        defaultSortBy={'name asc'}
        loading={!classificationsLoaded}
        columns={columns}
        filters={filters}
        onPagingFiltersChange={handlePagingFiltersChange}
        pageCount={pageCount}
      />
      <ClassificationModal
        isOpen={showModal}
        onCancel={handleCancel}
        type={type ?? ''}
        title={addEditTitle}
        classificationId={currentClassificationId}
      />
    </MainPage>
  );
}

export default connector(Classifications);
