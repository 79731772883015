import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { addToast } from '~/ducks/toasts';
import { createAsyncThunk } from '~/lib';
import { Group } from '~/models';
import { Paginated } from '~/models/Paginated';
import { adminGroupsApi } from '~/services/api';

import { API_STATES, createApiHasStatusSelector } from '../api';
import PaginationState from '../PaginationState';

const sliceName = 'admin/groups';

export const createGroup = createAsyncThunk(`${sliceName}/create`, async (payload) => {
  const { data } = await adminGroupsApi.create.invoke(payload);

  return data;
});

export const fetchGroup = createAsyncThunk<Group>(
  `${sliceName}/fetchById`,
  async ({ id, ...params }) => {
    const { data } = await adminGroupsApi.fetchById.invoke(id, params);

    return data;
  },
  {
    modelClass: Group,
  } as any
);

export const fetchGroups = createAsyncThunk<Paginated<Group>>(
  `${sliceName}/fetch`,
  async (params) => {
    const defaults = { pageSize: 5000, sortBy: 'name asc' };
    const { data } = await adminGroupsApi.fetch.invoke({ ...defaults, ...params });

    return data;
  },
  {
    defaultValue: [],
    modelClass: Group,
  } as any
);

export const updateGroup = createAsyncThunk(`${sliceName}/update`, async (payload) => {
  const { data } = await adminGroupsApi.update.invoke(payload.id, payload);

  return data;
});

export const deleteGroup = createAsyncThunk(`${sliceName}/delete`, async (payload, { dispatch }) => {
  const { data } = await adminGroupsApi.delete.invoke(payload).catch((e) => {
    dispatch(addToast({ text: 'There was an error archiving the group. Please try again.' }));
    throw e;
  });

  return data;
});

export const groupsAdapter = createEntityAdapter();

export const initialState = groupsAdapter.getInitialState({
  ids: [],
  entities: {},
  pagination: new PaginationState(),
});

const groupsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearGroups: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchGroups.fulfilled, (state, { payload: { links, meta, data } }) => {
      state.pagination = { links, meta } as PaginationState;
      groupsAdapter.setAll(state, data);
    });
    builder.addCase(deleteGroup.fulfilled, groupsAdapter.upsertOne);
  },
});

export const { clearGroups } = groupsSlice.actions;

const getGroupsState = (state: any) => state[sliceName];

export const { selectAll: getGroups } = groupsAdapter.getSelectors(getGroupsState);

export const getGroupsLoaded = createApiHasStatusSelector(fetchGroups, [API_STATES.complete, API_STATES.failed]);

export const getGroupsPageCount = (state: any) => getGroupsState(state).pagination.meta.totalPages;
export const getGroupsTotalRecords = (state: any) => getGroupsState(state).pagination.meta.totalRecords;

export default groupsSlice;
