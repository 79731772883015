import React, { useMemo } from 'react';
import { FormikProps, withFormik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import CircleSpinner from '~/components/shared/CircleSpinner';
import { FormPage } from '~/components/shared/pageLayout';
import {
  createImportConfiguration,
  fetchImportConfiguration,
  updateImportConfiguration,
} from '~/ducks/admin/importConfigurations';
import { addToast } from '~/ducks/toasts';
import { unwrapResult } from '~/lib';
import { useThunk } from '~/lib/hooks';
import { ImportedPatient } from '~/models/importConfigurations';

import ImportConfigurationForm from './ImportConfigurationForm';
import { importConfigurationValidation } from './importConfigurationValidation';

const mapDispatchToProps = {
  addToast,
  createImportConfiguration,
  fetchImportConfiguration,
  updateImportConfiguration,
};

const connector = connect(null, mapDispatchToProps);

type EditImportConfigurationProps = ConnectedProps<typeof connector>;

function EditImportConfiguration(props: EditImportConfigurationProps) {
  const { id } = useParams<{ id?: string }>();
  const navigate = useNavigate();

  const { data: importConfig, loaded } = useThunk(fetchImportConfiguration, [id], {
    condition: Boolean(id),
    params: { id, include: 'client' },
  });

  const goToImportConfigs = () => navigate('/clients/import-configurations');

  const handleSubmit = (values: any, { setSubmitting }: FormikProps<any>) => {
    const config = new ImportedPatient(values).serialize();
    const request = id ? props.updateImportConfiguration : props.createImportConfiguration;

    request(config)
      .then(unwrapResult)
      .then(goToImportConfigs)
      .catch((e: any) => {
        const errorMessage =
          e?.response?.data?.message ||
          `There was an error ${id ? 'updating' : 'creating'} the import configuration. Please try again.`;

        props.addToast({ text: errorMessage });
      })
      .then(() => props.addToast({ text: `Import Configuration successfully ${id ? 'updated' : 'added'}!` }))
      .finally(() => setSubmitting(false));
  };

  const initialValues = useMemo(() => {
    const config = new ImportedPatient(importConfig as any).toFormValues();

    return { ...config, rawConfig: config.config };
  }, [importConfig]);

  const formikOptions = useMemo(
    () => ({
      enableReinitialize: true,
      handleSubmit,
      mapPropsToValues: () => initialValues,
      validationSchema: importConfigurationValidation,
    }),
    [importConfig]
  );

  const FormikUserForm = useMemo(
    () => withFormik(formikOptions as any)(ImportConfigurationForm),
    [formikOptions]
  ) as any;

  if (Boolean(id) && !loaded) return <CircleSpinner centered />;

  return (
    <FormPage>
      <FormikUserForm onCancel={goToImportConfigs} />
    </FormPage>
  );
}

export default connector(EditImportConfiguration);
