import React from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

import Chip from '~/components/shared/Chip';
import { Checkbox } from '~/components/shared/form';
import { EditedPermissionsIcon } from '~/components/shared/svg';
import { BodySmallBold } from '~/components/shared/typography';
import { Permission, User } from '~/models';
import colors from '~/styles/theme/colors';

interface PermissionsListProps {
  category: string;
  permissions: Permission[];
}

function PermissionsList({ category, permissions }: PermissionsListProps) {
  const {
    status: formStatus,
    values: { user },
  } = useFormikContext<{
    user: User;
  }>();

  return (
    <Container>
      <StyledBodySmallBold>{category}</StyledBodySmallBold>
      {permissions.map((permission) => {
        const isPermissionEdited =
          Boolean((user.permissions as { [key: string]: boolean })[permission.name]) !==
          Boolean(user.role?.permissions[permission.name]);

        return (
          <CheckboxContainer key={permission.name}>
            <StyledCheckbox
              disabled={formStatus.disabled}
              label={permission.label}
              name={`user.permissions.${permission.name}`}
            />
            {isPermissionEdited && <StyledChip icon={<EditedPermissionsIcon fill={colors.black} size={16} />} />}
          </CheckboxContainer>
        );
      })}
    </Container>
  );
}

export default PermissionsList;

const StyledBodySmallBold = styled(BodySmallBold)`
  color: ${({ theme }) => theme.colors.primaryBlue};
  display: flex;
  flex-direction: column;
`;

const Container = styled.div`
  margin: 40px 0px 12px 0px;

  & > ${StyledBodySmallBold}:not(:first-child) {
    margin: 24px 0px 12px 0px;
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  margin: 16px 0px;
  text-align: center;
`;

const StyledCheckbox = styled(Checkbox).attrs({
  checkedColor: colors.primaryBlue,
  labelSize: '12px',
  size: 14,
  labelMargin: '0px',
  style: {
    checkboxContainer: {
      marginRight: '16px',
    },
  },
})``;

const StyledChip = styled(Chip).attrs({
  backgroundColor: colors.accentYellow,
  bold: true,
  fontSize: '8px',
  lineHeight: '12px',
  label: 'Modified',
  iconPadding: '0px',
  padding: '0px 7px',
  textColor: colors.black,
  textMargin: '0px',
  textPadding: '4px',
})`
  border-radius: 12px;
`;
