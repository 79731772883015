import React, { Fragment, useState } from 'react';

import { MainPage } from '~/components/shared/pageLayout';
import TabFilters from '~/components/shared/TabFilters';
import useTabs, { TabDef } from '~/lib/hooks/useTabs';
import { Collaboration } from '~/models';

import CollaborationModal from './CollaborationModal';
import CollaborationsFilterBar from './CollaborationsFilterBar';
import CollaborationsTable from './CollaborationsTable';

interface CollaborationsListProps {
  tabDefs: TabDef[];
}

function CollaborationsList({ tabDefs }: CollaborationsListProps) {
  const [showCollaborationModal, setShowCollaborationModal] = useState(false);
  const [currentClient, setCurrentClient] = useState(new Collaboration());
  const handleCollaborationCancel = () => setShowCollaborationModal(false);
  const handleCollaborationEdit = (client: Collaboration) => {
    setShowCollaborationModal(true);
    setCurrentClient(client);
  };

  const tabinfo = useTabs(tabDefs, 'Collaborations');

  return (
    <Fragment>
      <MainPage
        title='Clients'
        headerWhite
        headerContent={
          <Fragment>
            <CollaborationsFilterBar />
            <TabFilters {...tabinfo} />
          </Fragment>
        }>
        <CollaborationsTable onCollaborationEdit={handleCollaborationEdit} />
      </MainPage>
      <CollaborationModal show={showCollaborationModal} client={currentClient} onCancel={handleCollaborationCancel} />
    </Fragment>
  );
}

export default CollaborationsList;
