/* eslint-disable @typescript-eslint/no-explicit-any */
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { createAsyncThunk } from '~/lib';
import { Attr, AttrMatchOption } from '~/models';
import { IAttrOptions } from '~/models/Attr';
import { Paginated } from '~/models/Paginated';
import { adminAttrsApi } from '~/services/api';

import { API_STATES, createApiHasStatusSelector } from '../api';
import PaginationState from '../PaginationState';

const SLICE_NAME = 'admin/attrs';

const cancellableFetchAttrs = adminAttrsApi.fetch.cancellable();

export const fetchAttrs = createAsyncThunk<Paginated<IAttrOptions>>(
  `${SLICE_NAME}/fetch`,
  async (params) => {
    const res = cancellableFetchAttrs(params).then((arg) => arg as any);

    return (await res).data;
  },
  {
    defaultValue: [],
    modelClass: Attr,
  } as any
);

export type FetchAttrAPIParams = {
  id: string;
  include: string;
};

export const fetchAttr = createAsyncThunk(
  `${SLICE_NAME}/fetchById`,
  async ({ id, ...params }: FetchAttrAPIParams) => {
    const res = await adminAttrsApi.fetchById.invoke(id, params);

    return res.data;
  },
  {
    modelClass: Attr,
  } as any
);

export type FetchAttrMatchesParams = {
  id: string;
  search: string;
};

export const fetchAttrMatches = createAsyncThunk(
  `${SLICE_NAME}/fetchById/matches`,
  async ({ id, ...params }: FetchAttrMatchesParams) => {
    const res = await adminAttrsApi.fetchMatchesById.invoke({ id, ...params });

    return res.data;
  },
  {
    defaultValue: [],
    modelClass: AttrMatchOption,
  } as any
);

export type CreateAttrAPIParams = {
  clientId: string;
  name: string;
  displayName: string | null;
  olioAttr: string | null;
  visible: boolean;
  active: boolean;
  matchRules?: Record<string, unknown>;
};

export const createAttr = createAsyncThunk(`${SLICE_NAME}/create`, async (params: CreateAttrAPIParams) => {
  const res = await adminAttrsApi.create.invoke(params);

  return res.data;
});

export type UpdateAttrAPIParams = { id: string } & Omit<CreateAttrAPIParams, 'clientId' | 'name'>;

export const updateAttr = createAsyncThunk(`${SLICE_NAME}/create`, async (params: UpdateAttrAPIParams) => {
  const res = await adminAttrsApi.update.invoke(params.id, params);

  return res.data;
});

export const attrsAdapter = createEntityAdapter();

export const initialState = attrsAdapter.getInitialState({
  ids: [],
  entities: {},
  pagination: new PaginationState(),
});

const attrsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    clearAttrs: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchAttrs.fulfilled, (state: any, { payload: { links, meta, data } }) => {
      state.pagination = { links, meta };
      attrsAdapter.setAll(state, data);
    });
  },
});

export const { clearAttrs } = attrsSlice.actions;

const getAttrsState = (state: { [x: string]: any }) => state[SLICE_NAME];

export const { selectAll: getAttrs } = attrsAdapter.getSelectors(getAttrsState);

export const getAttrsLoaded = createApiHasStatusSelector(fetchAttrs, [API_STATES.complete, API_STATES.failed]);

export const getAttrsPageCount = (state: { [x: string]: any }) => getAttrsState(state).pagination.meta.totalPages;
export const getAttrsTotalRecords = (state: { [x: string]: any }) => getAttrsState(state).pagination.meta.totalRecords;

export default attrsSlice;
