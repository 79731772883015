import React, { useMemo } from 'react';
import { FormikHelpers, withFormik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { unwrapResult } from '@reduxjs/toolkit';

import CircleSpinner from '~/components/shared/CircleSpinner';
import { createUser, fetchUser, updateUser } from '~/ducks/admin/users';
import { addToast } from '~/ducks/toasts';
import { useThunk } from '~/lib/hooks';
import { User } from '~/models';

import { FormPage } from '../shared/pageLayout';

import UserForm from './UserForm';
import { userFormValidation } from './userFormValidation';

const mapDispatchToProps = {
  addToast,
  createUser,
  updateUser,
};

const connector = connect(null, mapDispatchToProps);

type EditUserProps = ConnectedProps<typeof connector>;

function EditUser(props: EditUserProps) {
  const { id: userId } = useParams();
  const { data: user, loaded: userLoaded } = useThunk(fetchUser, [userId], {
    condition: Boolean(userId),
    params: {
      id: userId,
      include: 'acting_client,client,credential,enabled_provider_types,role,selected_groups,selected_provider_types',
    },
  });

  const navigate = useNavigate();
  const goToUsers = () => navigate('/users');

  const handleSubmit = ({ shouldNavigate = true, ...userValues }: any, { setSubmitting }: FormikHelpers<any>) => {
    const { name } = userValues;

    const updatedUser = new User(userValues);

    const userRequest = updatedUser.id ? props.updateUser : props.createUser;
    const tryNotifySuccess = () => !shouldNavigate && props.addToast({ text: `${name} successfully added!` });

    return userRequest(updatedUser.serialize())
      .then(unwrapResult)
      .then(() => shouldNavigate && goToUsers())
      .then(tryNotifySuccess)
      .finally(() => setSubmitting(false));
  };

  const formikOptions = useMemo(
    () => ({
      enableReinitialize: true,
      handleSubmit,
      mapPropsToStatus: () => ({ isEdit: Boolean(userId) }),
      mapPropsToValues: () => user,
      validationSchema: userFormValidation,
    }),
    [user]
  );

  const FormikUserForm = useMemo(() => withFormik(formikOptions)(UserForm), [formikOptions]) as any;

  if (Boolean(userId) && !userLoaded) {
    return <CircleSpinner centered />;
  }

  return (
    <FormPage>
      <FormikUserForm onCancel={goToUsers} />
    </FormPage>
  );
}

export default connector(EditUser);
