import React from 'react';
import styled from 'styled-components';

import { BodySmall } from '~/components/shared/typography';
import { verticalCenter } from '~/styles/mixins';

export type FlyoutMenuItemProps<T> = {
  visible?: boolean;
  children: React.ReactNode[];
  onClick?: ((value: T) => void) | (() => void);
  width?: string;
  color?: string;
};

function FlyoutMenuItem<T>({ visible = true, ...props }: FlyoutMenuItemProps<T>) {
  return visible && <StyledFlyoutMenuItem {...props} />;
}

const StyledFlyoutMenuItem = styled(BodySmall).attrs(() => ({
  forwardedAs: 'div',
}))`
  color: ${({ color }) => color};
  cursor: pointer;
  min-width: 150px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.black10};
  }

  ${verticalCenter()};
`;

export default FlyoutMenuItem;
