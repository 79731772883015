import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { DISCHARGED_TO_GROUP_TYPE, GROUP_TYPE, SEARCH } from '~/constants/filterKeysConstants';
import { PROVIDER } from '~/constants/groupTypes';
import { DischargeReasonsFilterState, setFilters } from '~/ducks/admin/dischargeReasonsFilters';
import { fetchGroupTypes } from '~/ducks/admin/groupTypes';
import { fetchGroupTypes2 } from '~/ducks/admin/groupTypes2';
import { getDisplayName, getId } from '~/helpers';
import { useAsyncOptions, useDebounce } from '~/lib/hooks';
import { GroupType } from '~/models';

import { CheckboxMultiSelect, InlineInputGroups, InputGroup } from '../shared/form';
import Search from '../shared/Search';

type Props = {
  filters: DischargeReasonsFilterState;
  setFilters: typeof setFilters;
};

export default function DischargeReasonsFilters({ filters, setFilters }: Props) {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search);

  const updateFilter = (value: GroupType | string, filterType: keyof DischargeReasonsFilterState) => {
    setFilters({ [filterType]: value });
  };

  useEffect(() => {
    updateFilter(debouncedSearch, SEARCH);
  }, [debouncedSearch]);

  const asyncGroupTypeOptions = useAsyncOptions(fetchGroupTypes, {
    params: { sortBy: 'displayName' },
    optionsToPrepend: [{ displayName: 'None', id: 'null' } as unknown as GroupType],
  });

  const asyncProviderTypeOptions = useAsyncOptions(fetchGroupTypes2, {
    params: { sortBy: 'displayName', type: PROVIDER },
    optionsToPrepend: [{ displayName: 'None', id: 'null' } as unknown as GroupType],
  });

  return (
    <FiltersContainer>
      <StyledSearch
        value={search}
        placeholder='Search Dispositions'
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
        onClear={() => setSearch('')}
      />

      <InputGroup
        {...asyncGroupTypeOptions}
        component={CheckboxMultiSelect<GroupType>}
        label='Linked Group Type'
        name='dischargedToGroupType'
        onChange={(value) => updateFilter(value, DISCHARGED_TO_GROUP_TYPE)}
        value={filters[DISCHARGED_TO_GROUP_TYPE]}
        getOptionLabel={getDisplayName}
        getOptionValue={getId}
      />

      <InputGroup
        {...asyncProviderTypeOptions}
        component={CheckboxMultiSelect<GroupType>}
        label='Displayed To'
        name='displayedTo'
        onChange={(value) => updateFilter(value, GROUP_TYPE)}
        value={filters[GROUP_TYPE]}
        getOptionLabel={getDisplayName}
        getOptionValue={getId}
      />
    </FiltersContainer>
  );
}

const FiltersContainer = styled(InlineInputGroups)`
  & > * {
    max-width: 230px;
    margin-bottom: 24px;
    margin-right: 8px;
  }
`;

const StyledSearch = styled(Search)`
  margin-right: 24px;
`;
