import React, { ReactNode, useEffect, useRef, useState } from 'react';
import Icon from 'react-fontawesome';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import StyledNavLink from './StyledNavLink';

interface SideNavLinkProps {
  relativeIsActiveHandler: (title: string, pathname: string) => boolean;
  title: string;
  to?: string;
  visible: boolean;
  children?: ReactNode;
}

function SideNavLink(props: SideNavLinkProps) {
  const { relativeIsActiveHandler = () => undefined, title = '', to = '', visible = true } = props;

  const [isOpen, setIsOpen] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const location = useLocation();

  const handleParentClick = () => {
    setIsOpen(!isOpen);
  };

  const relativeIsActive = relativeIsActiveHandler(title, location.pathname);

  useEffect(() => {
    if (!containerRef.current) return;

    const active =
      [...containerRef.current.children].some(
        (child) => child instanceof HTMLAnchorElement && child.className.includes('active')
      ) ||
      relativeIsActive ||
      false;

    setIsActive(active);
  }, [location.pathname]);

  return (
    visible && (
      <Container className={isActive ? 'active' : ''} ref={containerRef}>
        {to ? (
          <StyledNavLink className={relativeIsActive ? 'active' : ''} to={to}>
            {title}
          </StyledNavLink>
        ) : (
          <StyledNavLink onClick={handleParentClick} className={isActive ? 'active' : ''} as='div'>
            {title}
            <StyledIcon name={`${isOpen ? 'caret-up' : 'caret-down'}`} />
          </StyledNavLink>
        )}

        {isOpen &&
          React.Children.map(props.children, (child) => {
            const element = child as React.ReactElement<any>;
            const { title, visible, ...rest } = element.props;

            return (
              visible && (
                <StyledNavLink {...rest}>
                  <Spacer />
                  {title}
                </StyledNavLink>
              )
            );
          })}
      </Container>
    )
  );
}

export default SideNavLink;

const Container = styled.div`
  border: 2px solid transparent;

  &.active {
    border-left-color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.white15};
  }
`;

const Spacer = styled.div`
  width: 18px;
  height: 18px;
  display: inline-block;
`;

const StyledIcon = styled(Icon)`
  margin-right: 10px;
  margin-left: auto;
  align-self: center;
`;
