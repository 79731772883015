import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import styled from 'styled-components';

import { NotificationPreferencesFormValues } from '~/components/preferences/notifications/NotificationPreferencesFormWrapper';
import { Button, ButtonGroup } from '~/components/shared/buttons';
import { MainPage } from '~/components/shared/pageLayout';
import { SaveChangesWrapper } from '~/components/shared/SaveChangesModal';
import TabFilters from '~/components/shared/TabFilters';
import { TabType } from '~/components/shared/TabFilters/TabFilter';
import { User } from '~/models';

export const SelectedPreferenceTab = createContext('');

export type Props = {
  children: React.ReactNode;
  onTabClick: (tab: string) => void;
  onTabChanged: (tab: string) => void;
  subtitle: string;
  title: string;
  user: User;
};

export default function NotificationPreferencesPage(props: Props) {
  const { children, onTabClick, onTabChanged, subtitle, title, user } = props;

  const {
    dirty,
    isSubmitting,
    isValid,
    resetForm,
    status: { disabled },
    submitForm,
  } = useFormikContext<NotificationPreferencesFormValues>();

  const selectedTab = useContext(SelectedPreferenceTab);
  const [showModalFunc, setShowModalFunc] = useState<() => void>(() => () => undefined);
  const discardDisabled = useMemo(() => disabled || !dirty || isSubmitting, [dirty, isSubmitting]);
  const saveDisabled = useMemo(() => disabled || !dirty || isSubmitting || !isValid, [dirty, isSubmitting, isValid]);
  const handleDiscardClick = useCallback(() => showModalFunc(), [showModalFunc]);
  const handleBindShowModal = useCallback(
    (func: () => void) => {
      setShowModalFunc(() => func);
    },
    [setShowModalFunc]
  );

  const buttonGroup = (
    <ButtonGroup>
      <Button color='transparent' disabled={saveDisabled} onClick={handleDiscardClick} text='Discard Changes' />
      <Button
        data-cy='saveNotificationPreferencesButton'
        disabled={discardDisabled}
        onClick={submitForm}
        text='Save Changes'
      />
    </ButtonGroup>
  );

  const providerTabs: TabType[] = useMemo(() => {
    return user.enabledProviderTypes.map((type) => ({
      label: type.displayName,
      value: type.id,
    }));
  }, [user.enabledProviderTypes]);

  return (
    <SaveChangesWrapper
      component={MainPage}
      title={title}
      bindShowModal={handleBindShowModal}
      headerWhite
      historyBlockCondition={dirty || isSubmitting}
      onSaveChangesConfirm={resetForm}
      rightContent={buttonGroup}
      subtitle={subtitle}
      headerContent={
        <TabFiltersContainer>
          <TabFilters
            tabs={providerTabs}
            data-cy='providerTabs'
            onTabChanged={onTabChanged}
            onTabClick={onTabClick}
            selectedTab={providerTabs.find((tab: TabType) => tab.value === selectedTab) || providerTabs[0]}
          />
        </TabFiltersContainer>
      }>
      {React.Children.map(
        children,
        (child) =>
          child &&
          React.cloneElement(child as any, {
            selectedTab,
          })
      )}
    </SaveChangesWrapper>
  );
}

const TabFiltersContainer = styled.div`
  margin-top: 24px;
`;
