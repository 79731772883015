import React from 'react';

import { Permission } from '~/models';

import Section from './Section';

function AdminSection({ permissions = [] }: { permissions?: Permission[] }) {
  return <Section label='Admin Permissions' permissions={permissions} />;
}

export default AdminSection;
