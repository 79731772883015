import React from 'react';

import colors from '~/styles/theme/colors';

const CheckboxOnIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  fill = colors.black,
  width = 24,
  height = 24,
  ...rest
}) => {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' {...rest}>
      <g id='checkbox-on-icon-black100' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g fill={fill} id='Combined-Shape'>
          <path d='M21.6110458,0 C22.9304288,0 24,1.07785486 24,2.38895416 L24,21.6110458 C24,22.9304288 22.9221451,24 21.6110458,24 L2.38895416,24 C1.06957121,24 0,22.9221451 0,21.6110458 L0,2.38895416 C0,1.06957121 1.07785486,0 2.38895416,0 L21.6110458,0 Z M18.2315186,4.8 L8.67423036,15.3142749 L5.45213077,12.0921753 L3.6,13.9453543 L8.76332551,19.1097279 L20.1696,6.56303563 L18.2315186,4.8 Z' />
        </g>
      </g>
    </svg>
  );
};

export default CheckboxOnIcon;
