import React, { useMemo } from 'react';
import { FormikHelpers, withFormik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { unwrapResult } from '@reduxjs/toolkit';

import CircleSpinner from '~/components/shared/CircleSpinner';
import { FormPage } from '~/components/shared/pageLayout';
import { ATTR_OLIO_MAPPED_FIELDS } from '~/constants/attrMappedFields';
import { createAttr, CreateAttrAPIParams, fetchAttr, updateAttr, UpdateAttrAPIParams } from '~/ducks/admin/attrs';
import { addToast } from '~/ducks/toasts';
import formatValidationErrors from '~/helpers/formatValidationErrors';
import { useThunk } from '~/lib/hooks';
import { baseAttrMatchRules } from '~/models/Attr';

import AttrForm, { AttrFormValues } from './AttrForm';
import { attrFormValidation } from './attrFormValidation';

interface IAttrFormProps {
  onCancel: () => void;
  isEditing: boolean;
}

const mapDispatchToProps = {
  addToast,
  createAttr,
  updateAttr,
};

const connector = connect(null, mapDispatchToProps);

type EditAttrProps = ConnectedProps<typeof connector>;

function EditAttr(props: EditAttrProps) {
  const navigate = useNavigate();
  const { id: attrId } = useParams<{ id?: string }>();

  const { data: attr, loaded: attrLoaded } = useThunk(fetchAttr, [attrId], {
    condition: Boolean(attrId),
    params: {
      id: attrId,
      include: 'client',
    },
  });

  const navigateToAttributes = () => {
    navigate('/attributes');
  };

  const handleSubmit = (values: AttrFormValues, { setSubmitting, setFieldError }: FormikHelpers<AttrFormValues>) => {
    const request = attrId ? props.updateAttr : props.createAttr;

    const hasOlioAttr = !!values.olioAttr?.value;
    const cleanedJSON = values.matchRules?.replace(/\n/g, '');

    const valuesForRequest: CreateAttrAPIParams | UpdateAttrAPIParams = {
      id: attrId,
      visible: values.visible,
      name: values.name,
      displayName: values.displayName || null,
      olioAttr: values.olioAttr?.value || null,
      clientId: values.client?.id || '',
      active: values.active,
      matchRules: hasOlioAttr && !!cleanedJSON ? JSON.parse(cleanedJSON) : {},
    };

    return request(valuesForRequest)
      .then(unwrapResult)
      .catch((e) => {
        const errors = e?.response?.data?.errors;

        if (errors) {
          const formattedErrors = formatValidationErrors(errors, { name: 'Raw attribute' });

          Object.keys(formattedErrors).forEach((err) => {
            setFieldError(err, formattedErrors[err]);
          });
        } else {
          props.addToast({
            text: `There was an error ${attrId ? 'updating' : 'creating'} the attribute. Please try again.`,
          });
        }
        throw e;
      })
      .then(navigateToAttributes)
      .then(() => props.addToast({ text: `Attribute successfully ${attrId ? 'updated' : 'added'}!` }))
      .finally(() => setSubmitting(false));
  };

  const formikOptions = useMemo(() => {
    const olioAttr = ATTR_OLIO_MAPPED_FIELDS.find((olioAttr) => olioAttr.value === attr?.olioAttr);
    const matchRules = Object.keys(attr?.matchRules || {}).length
      ? JSON.stringify(attr.matchRules, undefined, 2)
      : JSON.stringify(baseAttrMatchRules, undefined, 2);

    return {
      enableReinitialize: true,
      handleSubmit,
      validationSchema: attrFormValidation,
      mapPropsToStatus: () => ({ isEditing: Boolean(attrId) }),
      mapPropsToValues: () => ({ ...attr, olioAttr, matchRules }),
    };
  }, [attr]);

  const FormikAttrForm = useMemo(
    () => withFormik<IAttrFormProps, AttrFormValues>(formikOptions)(AttrForm),
    [formikOptions]
  );

  if (Boolean(attrId) && !attrLoaded) {
    return <CircleSpinner centered />;
  }

  return (
    <FormPage>
      <FormikAttrForm onCancel={navigateToAttributes} isEditing={!!attrId} />
    </FormPage>
  );
}

export default connector(EditAttr);
