import { createSelector, createSlice } from '@reduxjs/toolkit';

import { ALL_OPTION, CLIENT, CREATED_AT, STATUS } from '~/constants/filterKeysConstants';
import { rootPathnameChanged } from '~/ducks/navigation';

const now = new Date();
const ninetyDaysAgo = new Date(new Date().setDate(now.getDate() - 90));

export const initialState = {
  [CLIENT]: ALL_OPTION,
  [STATUS]: ALL_OPTION,
  [CREATED_AT]: [ninetyDaysAgo, now],
};

const sliceName = 'admin/importFilters';

const importFiltersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setFilter: (state, action) => {
      const { filterType, value } = action.payload;

      state[filterType] = value;
    },
    clearFilters: (state) => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(rootPathnameChanged, () => initialState);
  },
});

export const getImportFilters = (state) => state[sliceName];

export const getImportFiltersForRequest = createSelector(getImportFilters, (importFilters) => {
  return Object.entries(importFilters)
    .filter(([filterKey, filter]) => {
      switch (filterKey) {
        default:
          if (Array.isArray(filter)) {
            return !!filter.length;
          }
          return filter.id;
      }
    })
    .reduce((acc, [filterKey, filter]) => {
      switch (filterKey) {
        case 'createdAt':
          return { ...acc, [filterKey]: `${filter.map((f) => f.toISOString().split('T')[0]).join(',')}` };
        default:
          return { ...acc, [filterKey]: `${filter.id}` };
      }
    }, {});
});

export const { clearFilters, setFilter } = importFiltersSlice.actions;

export default importFiltersSlice;
