import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { INPATIENT, SEARCH, TYPE } from '~/constants/filterKeysConstants';
import { GROUP_TYPE_CATEGORY_OPTIONS } from '~/constants/groupTypes';
import { GroupTypeFiltersState, setFilters } from '~/ducks/admin/groupTypesFilters';
import { useDebounce } from '~/lib/hooks';

import { Checkbox, InlineInputGroups, InputGroup, Select } from '../shared/form';
import Search from '../shared/Search';

type Props = {
  filters: GroupTypeFiltersState;
  setFilters: typeof setFilters;
};

export default function GroupTypeFilters({ filters, setFilters }: Props) {
  const [search, setSearch] = useState('');
  const debouncedSearch = useDebounce(search);

  const updateFilter = (
    value:
      | string
      | boolean
      | {
          label: string;
          value: string;
        },
    filterType: string
  ) => {
    setFilters({ [filterType]: value });
  };

  useEffect(() => {
    updateFilter(debouncedSearch, SEARCH);
  }, [debouncedSearch]);

  return (
    <FiltersContainer>
      <StyledSearch
        value={search}
        placeholder='Search'
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
        onClear={() => setSearch('')}
      />

      <InputGroup
        label='Category'
        name={TYPE}
        value={filters[TYPE]}
        options={GROUP_TYPE_CATEGORY_OPTIONS}
        onChange={updateFilter}
        component={
          Select<{
            label: string;
            value: string;
          }>
        }
      />

      <CheckboxContainer>
        <Checkbox
          label='Inpatient'
          name={INPATIENT}
          value={filters[INPATIENT]}
          size={18}
          labelSize='16px'
          onChange={updateFilter}
        />
      </CheckboxContainer>
    </FiltersContainer>
  );
}

const FiltersContainer = styled(InlineInputGroups)`
  & > * {
    max-width: 230px;
    margin-bottom: 24px;
    margin-right: 24px;
  }
`;

const StyledSearch = styled(Search)`
  margin-right: 24px;
`;

const CheckboxContainer = styled.div`
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: auto;
`;
