import React, { useMemo, useState } from 'react';
import { FormikHelpers, withFormik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';

import { unwrapResult } from '@reduxjs/toolkit';

import { updateClientGroupType } from '~/ducks/admin/clientGroupTypes';
import { fetchClient } from '~/ducks/admin/clients';
import { addToast } from '~/ducks/toasts';
import { isDeepEqual } from '~/lib';
import { simpleDashDate } from '~/lib/formatDate';
import { useThunk } from '~/lib/hooks';
import { ClientGroupType } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';

import ConfigureCareOptionsForm from './ConfigureCareOptionsForm';
import ConfigureCareOptionsMainPage, { ConfigureCareOptionsMainPageProps } from './ConfigureCareOptionsMainPage';
import { ConfigureCareOptionsContext } from './context';
import { ReducedConfigs } from './types';

const mapDispatchToProps = {
  addToast,
  updateClientGroupType,
};

const connector = connect(null, mapDispatchToProps);

type ConfigureCareOptionsProps = ConnectedProps<typeof connector>;

function ConfigureCareOptions(props: ConfigureCareOptionsProps) {
  const [selectedTabValue, setSelectedTabValue] = useState('');

  const { id } = useParams();
  const profileSvc = useProfileContext();
  const formDisabled = !profileSvc.has(PERMISSIONS.adminClientGroupTypeEdit);
  const { data: client } = useThunk(fetchClient, [], {
    condition: !!id,
    params: {
      id,
      include: 'clientGroupTypes.groupType,group_types',
    },
  });

  const initialState = useMemo(
    () =>
      client.clientGroupTypes.reduce(
        (acc: ReducedConfigs, curr: ClientGroupType) => ({
          ...acc,
          [curr.id]: curr.config,
        }),
        {} as ReducedConfigs
      ),
    [client.clientGroupTypes]
  );

  const handleSubmit = async (values: ReducedConfigs, actions: FormikHelpers<ReducedConfigs>) => {
    const updateRequests: any[] = [];

    Object.entries(values).map(([clientGroupTypeId, clientGroupTypeValues]) => {
      // ignore tabs that have not been changed
      if (isDeepEqual(clientGroupTypeValues, initialState[clientGroupTypeId])) {
        return;
      }

      const params = {
        id: clientGroupTypeId,
        projectedDischarge: clientGroupTypeValues.projectedDischarge,
        utilizationManagement: clientGroupTypeValues.utilizationManagement,
        caseManager: clientGroupTypeValues.caseManager,
        utilizationManager: clientGroupTypeValues.utilizationManager,
        launchDate: simpleDashDate(clientGroupTypeValues.launchDate),
        include: 'groupType',
      };

      updateRequests.push(props.updateClientGroupType(params));
    });

    await Promise.allSettled(updateRequests)
      .then((results) =>
        results.map((result) => {
          if (result.status === 'fulfilled') {
            return unwrapResult(result.value as any);
          } else {
            throw new Error(result.reason);
          }
        })
      )
      .then((payloads) => {
        const updatedConfigs = initialState;

        payloads.forEach((payload) => {
          updatedConfigs[payload.id] = new ClientGroupType(payload).config;
        });

        actions.resetForm(updatedConfigs);
        props.addToast({ text: 'Care option(s) successfully updated' });
      })
      .catch((e) => {
        const errorMessage = e?.response?.data?.message || 'An error occurred';

        props.addToast({ text: errorMessage });
      });
  };

  const formikOptions = useMemo(
    () => ({
      enableReinitialize: true,
      handleSubmit,
      mapPropsToStatus: () => ({ disabled: formDisabled }),
      mapPropsToValues: () => initialState,
    }),
    [formDisabled, initialState]
  );

  const FormikConfigureCareOptionsMainPage = useMemo(() => {
    return withFormik<ConfigureCareOptionsMainPageProps, ReducedConfigs>(formikOptions as any)(
      ConfigureCareOptionsMainPage
    );
  }, [formikOptions]);

  return (
    <ConfigureCareOptionsContext.Provider value={selectedTabValue}>
      <FormikConfigureCareOptionsMainPage
        client={client}
        onTabChanged={setSelectedTabValue}
        onTabClick={setSelectedTabValue}>
        <ConfigureCareOptionsForm client={client} />
      </FormikConfigureCareOptionsMainPage>
    </ConfigureCareOptionsContext.Provider>
  );
}

export default connector(ConfigureCareOptions);
