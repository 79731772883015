import React, { useMemo, useState } from 'react';
import { useFormikContext } from 'formik';

import { FormSection, InlineInputGroups, Input, InputGroup, SectionHeader, Select } from '~/components/shared/form';
import states from '~/components/shared/states';
import { AFFILIATE, PHYSICIAN_TEAM } from '~/constants/locationTypes';
import { TIMEZONES } from '~/constants/timezones';
import { fetchClients } from '~/ducks/admin/clients';
import { fetchGroupTypes } from '~/ducks/admin/groupTypes';
import { getDisplayName, getId, getName } from '~/helpers';
import { useAsyncOptions, useDeepEffect } from '~/lib/hooks';
import { Group } from '~/models';

function ContactInfoSection() {
  const {
    setFieldTouched,
    setFieldValue,
    status: { isEdit },
    values,
  } = useFormikContext();

  const [clientParams, setClientParams] = useState(null);
  const group = useMemo(() => new Group(values), [values]);

  useDeepEffect(() => {
    if (isEdit && group) {
      setClientParams({ clientType: group.client.clientType });
    }
  }, [isEdit, group]);

  const asyncClientOptions = useAsyncOptions(fetchClients, {
    condition: !isEdit || (isEdit && clientParams),
    params: clientParams,
  });

  const disableClient = isEdit && !group.isProvider;
  const groupTypeApiName = values.groupType?.apiName;
  const showAddress = ![PHYSICIAN_TEAM, AFFILIATE].includes(groupTypeApiName);
  const disableGroupType = isEdit;

  const asyncGroupTypeOptions = useAsyncOptions(fetchGroupTypes, {
    condition: !disableGroupType,
    params: {
      sortBy: 'name',
    },
  });

  return (
    <FormSection>
      <SectionHeader>Contact Information</SectionHeader>
      <InputGroup
        {...asyncClientOptions}
        name='client'
        label='Client'
        data-cy='client'
        placeholder='Client'
        disabled={disableClient}
        getOptionLabel={getName}
        getOptionValue={getId}
        component={Select}
        onChange={(_client) => {
          if (!isEdit) {
            setFieldTouched('groupType', false);
            setFieldValue('groupType', '');
          }
        }}
      />
      <InputGroup name='name' label='Group Name' placeholder='Group Name' component={Input} />

      <InputGroup
        {...asyncGroupTypeOptions}
        name='groupType'
        label='Group Type'
        data-cy='groupType'
        placeholder='Group Type'
        disabled={disableGroupType}
        getOptionLabel={getDisplayName}
        getOptionValue={getId}
        component={Select}
      />
      {showAddress && (
        <React.Fragment>
          <InlineInputGroups>
            <InputGroup name='address.streetAddress' label='Street 1' placeholder='Street 1' component={Input} />
            <InputGroup
              name='address.streetAddressLine2'
              label='Street 2 (optional)'
              placeholder='Street 2'
              component={Input}
            />
          </InlineInputGroups>
          <InlineInputGroups>
            <InputGroup name='address.city' label='City' placeholder='City' component={Input} />
            <InlineInputGroups>
              <InputGroup
                name='address.state'
                label='State'
                data-cy='state'
                placeholder='State'
                options={states}
                component={Select}
              />
              <InputGroup name='address.zip' label='Zip' placeholder='Zip' component={Input} />
            </InlineInputGroups>
          </InlineInputGroups>
        </React.Fragment>
      )}

      <InputGroup
        name='timezone'
        label='Timezone'
        data-cy='timezone'
        placeholder='Timezone'
        options={TIMEZONES}
        component={Select}
      />
    </FormSection>
  );
}

export default ContactInfoSection;
