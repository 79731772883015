import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '~/components/shared/buttons';
import { MainPage } from '~/components/shared/pageLayout';
import { PlusIcon } from '~/components/shared/svg';
import TabFilters from '~/components/shared/TabFilters';
import useTabs, { TabDef } from '~/lib/hooks/useTabs';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import colors from '~/styles/theme/colors';

import ImportConfigurationsFilterBar from './ImportConfigurationsFilterBar';
import ImportConfigurationsTable from './ImportConfigurationsTable';

interface ImportConfigurationsListProps {
  tabDefs: TabDef[];
}

function ImportConfigurationsList({ tabDefs }: ImportConfigurationsListProps) {
  const profileSvc = useProfileContext();
  const canAddImports = profileSvc.has(PERMISSIONS.adminImportConfigurationCreate);

  const navigate = useNavigate();

  const handleAdd = () => navigate('/clients/import-configurations/new');
  const handleEdit = (config: { id: string }) => navigate(`/clients/import-configurations/${config.id}/edit`);

  const addImportConfiguration = (
    <Button onClick={handleAdd} icon={<PlusIcon size={14} fill={colors.white} />} text='Add Import Configuration' />
  );

  const tabinfo = useTabs(tabDefs, 'Import Configurations');

  return (
    <MainPage
      title='Clients'
      headerWhite
      rightContent={canAddImports && addImportConfiguration}
      headerContent={
        <Fragment>
          <ImportConfigurationsFilterBar />
          <TabFilters {...tabinfo} />
        </Fragment>
      }>
      <ImportConfigurationsTable handleEdit={handleEdit} />
    </MainPage>
  );
}

export default ImportConfigurationsList;
