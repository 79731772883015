import React, { ReactNode } from 'react';
import styled from 'styled-components';

import colors from '~/styles/theme/colors';

import { Label } from '../typography';

interface FieldLabelProps {
  name?: string;
  children: ReactNode;
}

function FieldLabel({ name = '', children }: FieldLabelProps) {
  return (
    <LabelContainer>
      <Label htmlFor={name} color={colors.black75}>
        {children}
      </Label>
    </LabelContainer>
  );
}

export default FieldLabel;

const LabelContainer = styled.div`
  margin-bottom: 4px;
`;
