import React from 'react';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { StandardModal } from '~/components/shared/modal';
import { Body, H3 } from '~/components/shared/typography';
import { capitalize } from '~/helpers/capitalize';

interface ConfirmationModalProps {
  actionText: string;
  objType: string;
  onCancel: () => void;
  onConfirm: () => void;
  show?: boolean;
  subText?: string;
}

function ConfirmationModal({
  onCancel,
  onConfirm,
  show = false,
  subText = '',
  actionText,
  objType,
}: ConfirmationModalProps) {
  const text = `${capitalize(actionText)} ${capitalize(objType)}`;

  return (
    <StandardModal colorTheme='danger' title={text} show={show} onCancel={onCancel}>
      <Content>
        <H3>
          Are you sure you want to {actionText} this {objType}?
        </H3>
        {subText && <Paragraph>{subText}</Paragraph>}
      </Content>
      <Actions>
        <Button color='transparent' text='Cancel' onClick={onCancel} />
        <Button color='danger' text={text} onClick={onConfirm} data-cy='confirmButton' />
      </Actions>
    </StandardModal>
  );
}

export default ConfirmationModal;

const Content = styled.div`
  margin: 26px 0 50px;
  text-align: center;
`;

const Paragraph = styled(Body)`
  display: block;
`;

const Actions = styled(ButtonGroup)`
  justify-content: flex-end;
`;
