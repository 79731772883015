import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';
import ReactModal from 'react-modal';
import { Provider } from 'react-redux';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router';

import { datadogRum } from '@datadog/browser-rum';

import { LoggedOut } from '~/components/auth';
import { store } from '~/store';

import App from './components/app';
import AttributesList from './components/attributes/AttributesList';
import AttributeValuesList from './components/attributes/AttributeValuesList';
import EditAttr from './components/attributes/edit/EditAttr';
import EditAttrValue from './components/attributes/EditAttrValue';
import Classifications from './components/classifications';
import { EditClient } from './components/clients';
import ClientsList from './components/clients/ClientsList';
import { CollaborationsList } from './components/clients/collaborations';
import { ConfigureCareOptions } from './components/clients/configureCareOptions';
import ImportConfigurationsList, { EditImportConfiguration } from './components/clients/imports';
import DischargeReasons from './components/dischargeReasons/DischargeReasons';
import EditDischargeReason from './components/dischargeReasons/EditDischargeReason';
import { Error, ErrorBoundary } from './components/error';
import FeatureFlags, { EditFeatureFlag } from './components/featureFlags';
import { EditGroup, Groups } from './components/groups';
import EditGroupType from './components/groupTypes/EditGroupType';
import GroupTypes from './components/groupTypes/GroupTypes';
import { StatusDetails } from './components/imports';
import ImportHistory from './components/imports/history/ImportHistory';
import NotificationPreferencesFormWrapper from './components/preferences/notifications/NotificationPreferencesFormWrapper';
import { TemplateFormContainer } from './components/questionTemplates';
import { DischargeTemplates } from './components/questionTemplates/dischargeTemplates';
import { ProgressTemplates } from './components/questionTemplates/progressTemplates';
import { ProtectedRouteElement } from './components/router';
import { EditUser, Users } from './components/users';
import { UserPermissionsContainer } from './components/users/permissions';
import { PERMISSIONS } from './services/profile';

import './assets/stylesheets/index.scss';

TagManager.initialize({
  gtmId: import.meta.env.GOOGLE_TAG_MANAGER_ID,
  auth: import.meta.env.GOOGLE_TAG_MANAGER_AUTH,
  preview: import.meta.env.GOOGLE_TAG_MANAGER_PREVIEW,
});

datadogRum.init({
  applicationId: import.meta.env.DATADOG_APPLICATION_ID,
  clientToken: import.meta.env.DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: import.meta.env.DATADOG_SERVICE_NAME,
  env: import.meta.env.DATADOG_ENVIRONMENT,
  version: '1.0.0',
  sessionSampleRate: 100,
  sessionReplaySampleRate: Number(import.meta.env.DATADOG_PREMIUM_SAMPLE_RATE) || 0,
  trackUserInteractions: true,
  defaultPrivacyLevel: 'mask',
  traceSampleRate: Number(import.meta.env.DATADOG_TRACE_SAMPLE_RATE) || 0,
  ...(import.meta.env.DATADOG_TRACE_URL
    ? {
        allowedTracingUrls: [(url) => url.startsWith(`https://${import.meta.env.DATADOG_TRACE_URL}.olio.health/api`)],
      }
    : {}),
});

datadogRum.startSessionReplayRecording();

// setup or modal parent element to attach to
ReactModal.setAppElement('#app');

const attributeTabDefs = [
  { label: 'Attributes', permission: PERMISSIONS.adminAttrView, path: '/attributes' },
  { label: 'Values', permission: PERMISSIONS.adminAttrValueView, path: '/attributes/values' },
];
const clientTabDefs = [
  { label: 'Clients', path: '/clients' },
  { label: 'Collaborations', permission: PERMISSIONS.adminCollaborationView, path: '/clients/collaborations' },
  {
    label: 'Import Configurations',
    permission: PERMISSIONS.adminImportConfigurationView,
    path: '/clients/import-configurations',
  },
];
const questionTemplateTabDefs = [
  { label: 'Progress Templates', path: '/question-templates/progress' },
  { label: 'Discharge Templates', path: '/question-templates/discharge' },
];

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path='/error' element={<Error />} />
      <Route path='/logged-out' element={<LoggedOut />} />
      <Route path='' element={<App />}>
        <Route
          path='/groups/new'
          element={<ProtectedRouteElement component={EditGroup} permissions={PERMISSIONS.adminGroupCreate} />}
        />
        <Route
          path='/groups/:id/edit'
          element={<ProtectedRouteElement component={EditGroup} permissions={PERMISSIONS.adminGroupEdit} />}
        />
        <Route
          path='/groups'
          element={<ProtectedRouteElement component={Groups} permissions={PERMISSIONS.adminGroupView} />}
        />
        <Route
          path='/discharge-reasons/new'
          element={
            <ProtectedRouteElement
              component={EditDischargeReason}
              permissions={PERMISSIONS.adminDischargeReasonCreate}
            />
          }
        />
        <Route
          path='/discharge-reasons/:id/edit'
          element={
            <ProtectedRouteElement component={EditDischargeReason} permissions={PERMISSIONS.adminDischargeReasonEdit} />
          }
        />
        <Route
          path='/discharge-reasons'
          element={
            <ProtectedRouteElement component={DischargeReasons} permissions={PERMISSIONS.adminDischargeReasonView} />
          }
        />
        <Route
          path='/group-types/new'
          element={<ProtectedRouteElement component={EditGroupType} permissions={PERMISSIONS.adminGroupTypeCreate} />}
        />
        <Route
          path='/group-types/:id/edit'
          element={<ProtectedRouteElement component={EditGroupType} permissions={PERMISSIONS.adminGroupTypeEdit} />}
        />
        <Route
          path='/group-types'
          element={<ProtectedRouteElement component={GroupTypes} permissions={PERMISSIONS.adminGroupTypeView} />}
        />
        <Route
          path='/feature-flags/:name/edit'
          element={<ProtectedRouteElement component={EditFeatureFlag} permissions={PERMISSIONS.adminFeatureFlagEdit} />}
        />
        <Route
          path='/feature-flags'
          element={<ProtectedRouteElement component={FeatureFlags} permissions={PERMISSIONS.adminFeatureFlagView} />}
        />
        <Route
          path='/clients/import-configurations/new'
          element={
            <ProtectedRouteElement
              component={EditImportConfiguration}
              permissions={PERMISSIONS.adminImportConfigurationCreate}
            />
          }
        />
        <Route
          path='/clients/import-configurations/:id/edit'
          element={
            <ProtectedRouteElement
              component={EditImportConfiguration}
              permissions={PERMISSIONS.adminImportConfigurationEdit}
            />
          }
        />
        <Route
          path='/clients/new'
          element={<ProtectedRouteElement component={EditClient} permissions={PERMISSIONS.adminClientCreate} />}
        />
        <Route
          path='/clients/:id/edit'
          element={<ProtectedRouteElement component={EditClient} permissions={PERMISSIONS.adminClientEdit} />}
        />
        <Route
          path='/clients/:id/group-types/edit'
          element={
            <ProtectedRouteElement
              component={ConfigureCareOptions}
              permissions={PERMISSIONS.adminClientGroupTypeView}
            />
          }
        />
        <Route
          path='/clients/collaborations'
          element={
            <ProtectedRouteElement
              component={CollaborationsList}
              permissions={PERMISSIONS.adminCollaborationView}
              tabDefs={clientTabDefs}
            />
          }
        />
        <Route
          path='/clients/import-configurations'
          element={
            <ProtectedRouteElement
              component={ImportConfigurationsList}
              permissions={PERMISSIONS.adminImportConfigurationView}
              tabDefs={clientTabDefs}
            />
          }
        />
        <Route
          path='/clients'
          element={
            <ProtectedRouteElement
              component={ClientsList}
              permissions={PERMISSIONS.adminClientView}
              tabDefs={clientTabDefs}
            />
          }
        />
        <Route
          path='/classifications/:type'
          element={
            <ProtectedRouteElement component={Classifications} permissions={PERMISSIONS.adminClassificationView} />
          }
        />
        <Route
          path='/users/new'
          element={<ProtectedRouteElement component={EditUser} permissions={PERMISSIONS.adminUserCreate} />}
        />
        <Route
          path='/users/:id/edit'
          element={<ProtectedRouteElement component={EditUser} permissions={PERMISSIONS.adminUserEdit} />}
        />
        <Route
          path='/users/:id/preferences'
          element={
            <ProtectedRouteElement
              component={NotificationPreferencesFormWrapper}
              permissions={PERMISSIONS.adminUserView}
            />
          }
        />
        <Route
          path='/users/:id/permissions'
          element={
            <ProtectedRouteElement component={UserPermissionsContainer} permissions={PERMISSIONS.adminUserEdit} />
          }
        />
        <Route
          path='/users'
          element={<ProtectedRouteElement component={Users} permissions={PERMISSIONS.adminUserView} />}
        />
        <Route
          path='/question-templates/:type/new'
          element={
            <ProtectedRouteElement
              component={TemplateFormContainer}
              permissions={PERMISSIONS.adminQuestionTemplateCreate}
            />
          }
        />
        <Route
          path='/question-templates/:type/:id/edit'
          element={
            <ProtectedRouteElement
              component={TemplateFormContainer}
              permissions={PERMISSIONS.adminQuestionTemplateEdit}
            />
          }
        />
        <Route
          path='/question-templates/progress'
          element={
            <ProtectedRouteElement
              component={ProgressTemplates}
              permissions={PERMISSIONS.adminQuestionTemplateView}
              tabDefs={questionTemplateTabDefs}
            />
          }
        />
        <Route
          path='/question-templates/discharge'
          element={
            <ProtectedRouteElement
              component={DischargeTemplates}
              permissions={PERMISSIONS.adminQuestionTemplateView}
              tabDefs={questionTemplateTabDefs}
            />
          }
        />
        <Route
          path='/import-history/:id/status-details'
          element={
            <ProtectedRouteElement component={StatusDetails} permissions={PERMISSIONS.adminImportedPatientView} />
          }
        />
        <Route
          path='/import-history'
          element={
            <ProtectedRouteElement component={ImportHistory} permissions={PERMISSIONS.adminImportedPatientView} />
          }
        />
        <Route
          path='/attributes/values/new'
          element={<ProtectedRouteElement component={EditAttrValue} permissions={PERMISSIONS.adminAttrValueCreate} />}
        />
        <Route
          path='/attributes/values/:id/edit'
          element={<ProtectedRouteElement component={EditAttrValue} permissions={PERMISSIONS.adminAttrValueEdit} />}
        />
        <Route
          path='/attributes/new'
          element={<ProtectedRouteElement component={EditAttr} permissions={PERMISSIONS.adminAttrCreate} />}
        />
        <Route
          path='/attributes/:id/edit'
          element={<ProtectedRouteElement component={EditAttr} permissions={PERMISSIONS.adminAttrEdit} />}
        />
        <Route
          path='/attributes/values'
          element={
            <ProtectedRouteElement
              component={AttributeValuesList}
              permissions={PERMISSIONS.adminAttrValueView}
              tabDefs={attributeTabDefs}
            />
          }
        />
        <Route
          path='/attributes'
          element={
            <ProtectedRouteElement
              component={AttributesList}
              permissions={PERMISSIONS.adminAttrView}
              tabDefs={attributeTabDefs}
            />
          }
        />
        <Route index element={<Navigate to='/clients' replace />} />
      </Route>
    </>
  )
);

const root = (
  <Provider store={store}>
    <ErrorBoundary>
      <RouterProvider router={router} />
    </ErrorBoundary>
  </Provider>
);

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
createRoot(document.getElementById('app')!).render(root);

export { store };
