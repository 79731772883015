import React, { useEffect } from 'react';
import styled from 'styled-components';

import { RadioButtonChecked, RadioButtonUnchecked } from '~/components/shared/svg';
import { Label } from '~/components/shared/typography';
import useField from '~/lib/hooks/useField';
import { verticalCenter } from '~/styles/mixins';

interface RadioButtonGroupProps {
  disabled?: boolean;
  getOptionLabel?: (option: any) => string;
  getOptionValue?: (option: any) => any;
  menuPlacement?: string;
  onChange?: (value: any, name: string, setValue: (value: any) => void) => void;
  options: Array<any>;
}

function RadioButtonGroup(props: RadioButtonGroupProps) {
  const {
    options,
    getOptionValue = ({ value }) => value,
    getOptionLabel = ({ label }) => label,
    menuPlacement = 'auto',
    disabled = false,
  } = props;

  const [field, , helpers] = useField({ ...props, menuPlacement, disabled });
  const { name, value } = field;

  useEffect(() => {
    if (!value && options.length) {
      handleChange(getOptionValue(options[0]));
    }
  }, [options]);

  const handleChange = (val: any) => {
    helpers.setValue(val);
    props.onChange?.(val, name, helpers.setValue);
  };

  return (
    <GroupContainer>
      {options.map((option) => {
        const val = getOptionValue(option);
        const label = getOptionLabel(option);

        return (
          <RadioButton key={val} onClick={() => handleChange(val)}>
            {value !== null && value === val ? <RadioButtonChecked size={18} /> : <RadioButtonUnchecked size={18} />}
            {label && <RadioLabel>{label}</RadioLabel>}
          </RadioButton>
        );
      })}
    </GroupContainer>
  );
}

export default RadioButtonGroup;

const GroupContainer = styled.div`
  display: flex;
  width: 100%;
  border-radius: 4px;
`;

const RadioButton = styled.div`
  ${verticalCenter()};
  margin-right: 16px;
`;

const RadioLabel = styled(Label)`
  margin-left: 9px;
`;
