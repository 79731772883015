import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { unwrapResult } from '@reduxjs/toolkit';

import { ConfirmationModal } from '~/components/shared/modal';
import { MainPage } from '~/components/shared/pageLayout';
import { ControlledTable } from '~/components/shared/table';
import { ImportAction } from '~/constants/imports';
import { fetchActiveStorageAttachment } from '~/ducks/activeStorageAttachments';
import { clearFilters, getImportFilters, getImportFiltersForRequest, setFilter } from '~/ducks/admin/importFilters';
import { fetchImports, updateImport } from '~/ducks/admin/imports';
import { addToast } from '~/ducks/toasts';
import { useThunk } from '~/lib/hooks';
import { Import } from '~/models';

import ImportFilterBar from './ImportFilterBar';
import importHistoryTableColumns from './importHistoryTableColumns';

const mapStateToProps = (state: any) => ({
  filters: getImportFilters(state),
  filtersForRequest: getImportFiltersForRequest(state),
});

const mapDispatchToProps = {
  addToast,
  fetchActiveStorageAttachment,
  clearFilters,
  fetchImports,
  setFilter,
  updateImport,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ImportHistoryProps = ConnectedProps<typeof connector>;

function ImportHistory(props: ImportHistoryProps) {
  const { filters, filtersForRequest } = props;
  const [pageCount, setPageCount] = useState(0);
  const [pagingFilters, setPagingFilters] = useState({});
  const [updatedImport, setUpdatedImport] = useState<any>(null);
  const [selectedImport, setSelectedImport] = useState<any>(null);

  const navigate = useNavigate();

  useEffect(() => {
    props.clearFilters();
  }, []);

  const { data: imports, loaded: importsLoaded } = useThunk(
    fetchImports,
    [pagingFilters, filtersForRequest, updatedImport],
    {
      onSuccess: (payload: any) => {
        setPageCount(payload?.meta?.totalPages);
      },
      params: { ...pagingFilters, ...filtersForRequest, include: 'child,client,file,stepCounts' },
      debounce: 50,
    }
  );

  const handleViewStatusDetails = useCallback((importObj: Import) => {
    navigate(`/import-history/${importObj.id}/status-details`);
  }, []);

  const handleImportAction = async (importObj: any, action: ImportAction) => {
    const payload = {
      id: importObj.id,
      invokeAction: action,
      include: 'child,client,steps',
    };

    await props.updateImport(payload).then(unwrapResult).then(setUpdatedImport);
  };

  const handleCancelImport = useCallback((importObj: Import) => {
    setSelectedImport(importObj);
  }, []);

  const handleModalConfirm = useCallback(() => {
    handleImportAction(selectedImport, ImportAction.CANCEL);

    setSelectedImport(null);
  }, [selectedImport]);

  const handleModalCancel = useCallback(() => {
    setSelectedImport(null);
  }, []);

  const handlePagingFiltersChange = useCallback((newPagingFilters: object) => {
    setPagingFilters(newPagingFilters);
  }, []);

  const handleDownloadAttachment = async (attachmentId: string) => {
    props
      .fetchActiveStorageAttachment({ id: attachmentId, include: 'url' })
      .then((res: any) => window.open(res.payload.url, '_blank'));
  };

  const columns = useMemo(
    () =>
      importHistoryTableColumns({
        onCancelImport: (importObj) => handleCancelImport(importObj),
        onRestartImport: (importObj) => handleImportAction(importObj, ImportAction.RESTART),
        onResumeImport: (importObj) => handleImportAction(importObj, ImportAction.RUN),
        onViewStatusDetails: handleViewStatusDetails,
        handleDownloadAttachment,
      }),
    []
  );

  return (
    <MainPage title='Import History'>
      <ImportFilterBar clearFilters={props.clearFilters} filters={filters} setFilter={props.setFilter} />
      <ControlledTable
        data={imports}
        defaultSortBy={'createdAt desc'}
        loading={!importsLoaded}
        columns={columns}
        filters={filters}
        onPagingFiltersChange={handlePagingFiltersChange}
        pageCount={pageCount}
      />
      <ConfirmationModal
        show={Boolean(selectedImport)}
        actionText='cancel'
        objType='import'
        onConfirm={handleModalConfirm}
        onCancel={handleModalCancel}
      />
    </MainPage>
  );
}

export default connector(ImportHistory);
