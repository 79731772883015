import React, { useCallback, useRef } from 'react';
import { components } from 'react-select';
import styled from 'styled-components';

import InfiniteScroll from '~/components/shared/InfiniteScroll';

const InnerMenuList = components.MenuList as any;

interface InfiniteScrollMenuListProps {
  innerRef?: (element: HTMLElement | null) => void;
  selectProps: {
    bindMenuListRef?: (element: HTMLElement | null) => void;
    hasMore?: boolean;
    isLoading?: boolean;
    onEndReached?: () => void;
  };
  children: React.ReactNode;
}

function InfiniteScrollMenuList({ innerRef: _, ...props }: InfiniteScrollMenuListProps) {
  const {
    bindMenuListRef = () => undefined,
    hasMore = false,
    isLoading = false,
    onEndReached = () => undefined,
  } = props.selectProps;

  const menuListRef = useRef<HTMLElement | null>(null);

  // We invoke bindMenuListRef here because the parent component does
  // not have access to it, even if you get the ref to the React Select.
  // We can't handle and pass through the onChange for the Select in
  // this component, so bind the MenuList ref and let the parent implement
  // the needed onChange logic that retrieves more pages of data if the
  // menu list isn't scrollable.
  const handleInnerRef = useCallback((element: HTMLElement | null) => {
    bindMenuListRef(element);
    menuListRef.current = element;
  }, []);

  return (
    <InnerMenuList {...props} innerRef={handleInnerRef}>
      {props.children}
      <StyledInfiniteScroll
        element={menuListRef.current}
        hasMore={hasMore}
        loading={isLoading}
        onEndReached={onEndReached}
      />
    </InnerMenuList>
  );
}

export default InfiniteScrollMenuList;

const StyledInfiniteScroll = styled(InfiniteScroll)`
  height: 10px !important;
`;
