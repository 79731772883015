import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import StandardModal from '~/components/shared/modal/StandardModal';
import { authService, SESSION_LOGOUT_AFTER_IDLE } from '~/services/auth';

interface SessionTimeoutModalProps {
  onClose: () => void;
  showModal: boolean;
}

function SessionTimeoutModal({ onClose, showModal }: SessionTimeoutModalProps) {
  const [timeRemaining, setTimeRemaining] = useState<string | null>(null);
  const [idleTime, setIdleTime] = useState<number | null>(null);
  const [closing, setClosing] = useState(false);
  const [countdown, setCountdown] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!closing && !countdown && showModal) {
      setIdleTime(new Date().getTime());
    }
  }, [showModal]);

  useEffect(() => {
    if (idleTime) {
      setCountdown(setInterval(() => updateTimeRemaining(), 1000));
    }
  }, [idleTime]);

  useEffect(() => reset, []);

  const triggerClose = (action: () => void) => {
    setClosing(true);
    action();
    setClosing(false);
  };

  const reset = () => {
    if (countdown !== null) clearInterval(countdown);
    setCountdown(null);
    setIdleTime(null);
    setTimeRemaining(null);
  };

  const handleLogout = () => {
    triggerClose(() => {
      reset();
      authService.logout();
    });
  };

  const handleRenewSession = async () => {
    try {
      await authService.renewSession();

      triggerClose(() => {
        reset();
        onClose();
      });
    } catch (e) {
      handleLogout();
    }
  };

  const updateTimeRemaining = () => {
    if (idleTime === null) return;

    const remaining = idleTime + SESSION_LOGOUT_AFTER_IDLE - new Date().getTime();

    setTimeRemaining(moment(remaining).format('mm:ss'));

    if (remaining <= 0) {
      handleLogout();
    }
  };

  return (
    <StandardModal show={showModal} title={'Session Expiring Soon'} showCloseButton={false}>
      <Content>
        <Message>Your session is about to expire. You will be automatically logged out in</Message>
        <Countdown>{timeRemaining}</Countdown>
        <Message>
          To continue your session, select <b>Stay Signed In</b>
        </Message>
      </Content>
      <Actions>
        <Button onClick={handleLogout} color='transparent' text='Logout' />
        <Button onClick={handleRenewSession} text='Stay Signed In' />
      </Actions>
    </StandardModal>
  );
}

export default SessionTimeoutModal;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 30px 0 50px;
  font-size: 16px;
  line-height: 1.5;

  & > * {
    margin-bottom: 8px;
  }
`;

const Message = styled.div`
  flex: 1;
`;

const Countdown = styled.div`
  color: rgb(50, 83, 239);
  font-size: 20px;
`;

const Actions = styled(ButtonGroup)`
  justify-content: flex-end;
`;
