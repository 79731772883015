import React from 'react';
import styled from 'styled-components';

import { RadioButtonChecked, RadioButtonUnchecked } from '~/components/shared/svg';
import { verticalCenter } from '~/styles/mixins';

interface RadioButtonProps {
  onChange: (value: string) => void;
  radioLabel?: string;
  radioValue: string;
  value?: string;
}

const RadioButton: React.FC<RadioButtonProps> = ({ onChange, radioLabel, radioValue, value }) => {
  const handleClick = () => {
    if (radioValue !== value) {
      onChange(radioValue);
    }
  };

  return (
    <Root onClick={handleClick}>
      {value && value === radioValue ? <RadioButtonChecked /> : <RadioButtonUnchecked />}
      {radioLabel && <Label>{radioLabel}</Label>}
    </Root>
  );
};

export default RadioButton;

const Root = styled.div`
  ${verticalCenter()};
`;

const Label = styled.div`
  margin-left: 8px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.black50};
`;
