import React, { useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import { InlineInputGroups, InputGroup, Select } from '~/components/shared/form';
import Search from '~/components/shared/Search';
import { OWNER_CLIENT_TYPES } from '~/constants/clientTypes';
import { ALL_OPTION, CLIENT_TYPE, SEARCH } from '~/constants/filterKeysConstants';
import { clearFilters, getImportConfigurationsFilters, setFilter } from '~/ducks/admin/importConfigurationsFilters';
import { useDebounce } from '~/lib/hooks';

const mapStateToProps = (state: any) => ({
  filters: getImportConfigurationsFilters(state),
});

const mapDispatchToProps = {
  clearFilters,
  setFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ImportConfigurationsFilterBarProps = ConnectedProps<typeof connector>;

function ImportConfigurationsFilterBar({ filters, ...props }: ImportConfigurationsFilterBarProps) {
  const [search, setSearch] = useState(filters[SEARCH]);
  const debouncedSearch = useDebounce(search);
  const clientTypeOptions = useMemo(() => {
    return [ALL_OPTION, ...OWNER_CLIENT_TYPES.map((value) => ({ id: value, name: value }))];
  }, []);

  const updateFilter = (key: string, val: any) => props.setFilter({ filterType: key, value: val });

  useEffect(() => {
    updateFilter(SEARCH, search);
  }, [debouncedSearch]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.currentTarget.value);
  const handleOptionChange = (value: { id: string; name: string }, filterType: string) => {
    updateFilter(filterType, value);
  };
  const handleSearchClear = () => setSearch('');
  const handleClearFilters = () => props.clearFilters();

  const showClearAll = Object.values(filters).some((value: any) => value.id);
  const byName = ({ name }: { name: string }) => name;
  const byId = ({ id }: { id: string }) => id;

  return (
    <FiltersContainer>
      <StyledSearch
        value={search}
        placeholder='Search Clients'
        onChange={handleSearchChange}
        onClear={handleSearchClear}
      />

      <InputGroup
        label='Client Type'
        name={CLIENT_TYPE}
        value={filters[CLIENT_TYPE]}
        options={clientTypeOptions}
        getOptionLabel={byName}
        getOptionValue={byId}
        onChange={handleOptionChange}
        component={Select<{ id: string; name: string }>}
      />

      {showClearAll && (
        <ClearLinkContainer>
          <ClearLink onClick={handleClearFilters}>Clear Filter</ClearLink>
        </ClearLinkContainer>
      )}
    </FiltersContainer>
  );
}

export default connector(ImportConfigurationsFilterBar);

const FiltersContainer = styled(InlineInputGroups)`
  & > * {
    max-width: 230px;
    margin-bottom: 24px;
  }
`;

const StyledSearch = styled(Search)`
  margin-right: 24px;
`;

const ClearLinkContainer = styled.div`
  height: 40px;
  display: flex;
  flex: 0;
  align-items: center;
`;

const ClearLink = styled.div`
  cursor: pointer;
  width: 76px;
  color: ${({ theme }) => theme.colors.primaryBlue};
`;
