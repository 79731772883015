/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash-es';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SortingState } from '@tanstack/react-table';

import { ControlledTable } from '~/components/shared/table';
import { SEARCH } from '~/constants/filterKeysConstants';
import { clearFilters, setFilter } from '~/ducks/admin/attrsFilters';
import {
  fetchAttrValues,
  getAttrValues,
  getAttrValuesLoaded,
  getAttrValuesPageCount,
  getAttrValuesTotalRecords,
} from '~/ducks/admin/attrValues';
import { getAttrValuesFilters, getAttrValuesFiltersForRequest } from '~/ducks/admin/attrValuesFilters';
import { useModel } from '~/lib/hooks';
import { AttrValue } from '~/models';
import { useProfileContext } from '~/services/profile';

import attributeValuesTableColumns from './attributeValuesTableColumns';

const mapStateToProps = (state: any) => ({
  values: getAttrValues(state),
  loaded: getAttrValuesLoaded(state),
  pageCount: getAttrValuesPageCount(state),
  filters: getAttrValuesFilters(state),
  filtersForRequest: getAttrValuesFiltersForRequest(state),
  totalRecords: getAttrValuesTotalRecords(state),
});

const mapDispatchToProps = {
  fetchAttrValues,
  clearFilters,
  setFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type AttributeValuesTableProps = ConnectedProps<typeof connector>;

function AttributeValuesTable({ ...props }: AttributeValuesTableProps) {
  const profileSvc = useProfileContext();
  const [pagingFilters, setPagingFilters] = useState({});
  const [tableProps, setTableProps] = useState({ setSorting: (_sort: SortingState) => {} });
  const debouncedFetchAttrValues = useCallback(
    _.debounce((params: any) => props.fetchAttrValues(params), 50),
    []
  );
  const values = useModel(AttrValue, props.values);

  useEffect(() => {
    debouncedFetchAttrValues({
      ...pagingFilters,
      ...props.filtersForRequest,
      include: 'client,attr,name',
    });
  }, [pagingFilters, props.filtersForRequest]);

  const handlePagingFiltersChange = useCallback(
    (newPagingFilters: { page: number; pageSize: number; sortBy: string }) => {
      setPagingFilters(newPagingFilters);
    },
    []
  );

  const navigate = useNavigate();
  const handleEditAttrValue = (attrValue: AttrValue) => {
    navigate(`/attributes/values/${attrValue.id}/edit`);
  };

  const columns = useMemo(
    () =>
      attributeValuesTableColumns({
        profileSvc,
        onEdit: handleEditAttrValue,
      }),
    []
  );

  const defaultSortBy = [{ id: 'name', desc: false }];
  const searchValue: any = props.filters[SEARCH as keyof typeof props.filters];

  useEffect(() => {
    const sortBy = searchValue ? [{ id: SEARCH, desc: true }] : defaultSortBy;

    tableProps.setSorting(sortBy);
  }, [searchValue]);

  // can we get generic from columns?
  return (
    <ControlledTable<AttrValue>
      data={values}
      defaultSortBy={'name asc'}
      loading={!props.loaded}
      onInitialize={setTableProps}
      columns={columns}
      filters={props.filters}
      onPagingFiltersChange={handlePagingFiltersChange}
      pageCount={props.pageCount}
    />
  );
}

export default connector(AttributeValuesTable);
