import React from 'react';

import { FlyoutMenu, FlyoutMenuIcon, FlyoutMenuItem } from '~/components/shared/FlyoutMenu';
import { CancelIcon, DetailsIcon, ExportIcon, RestartIcon, ResumeIcon } from '~/components/shared/svg';
import { Import } from '~/models/imports';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import colors from '~/styles/theme/colors';

interface ImportsFlyoutMenuProps {
  handleDownloadAttachment: (id: string) => void;
  onCancelImport: (i: Import) => void;
  onRestartImport: (i: Import) => void;
  onResumeImport: (i: Import) => void;
  onViewStatusDetails: (i: Import) => void;
  row: {
    original: Import;
  };
}

function ImportsFlyoutMenu(props: ImportsFlyoutMenuProps) {
  const { handleDownloadAttachment, onRestartImport, onResumeImport, onCancelImport, onViewStatusDetails, row } = props;

  const profileSvc = useProfileContext();
  const canEditImport = profileSvc.has(PERMISSIONS.adminImportedPatientEdit);
  const importObj = row.original;

  const showCancelButton = canEditImport && importObj.cancellable;
  const showResumeButton = canEditImport && importObj.runnable;
  const showRestartButton = canEditImport && importObj.restartable;

  return (
    <FlyoutMenu data={importObj}>
      <FlyoutMenuItem onClick={onViewStatusDetails} color={colors.black} width={'100%'}>
        <FlyoutMenuIcon>
          <DetailsIcon size={18} />
        </FlyoutMenuIcon>
        View Status Details
      </FlyoutMenuItem>
      {!importObj?.fileExpired && (
        <FlyoutMenuItem
          onClick={() => handleDownloadAttachment(importObj?.file?.id ?? '')}
          color={colors.black}
          width={'100%'}>
          <FlyoutMenuIcon>
            <ExportIcon width='18' height='18' fill={`${colors.black50}`} />
          </FlyoutMenuIcon>
          Download File
        </FlyoutMenuItem>
      )}
      {showResumeButton && (
        <FlyoutMenuItem onClick={onResumeImport} color={colors.black} width={'100%'}>
          <FlyoutMenuIcon>
            <ResumeIcon size={16} />
          </FlyoutMenuIcon>
          Resume Import
        </FlyoutMenuItem>
      )}
      {showRestartButton && (
        <FlyoutMenuItem onClick={onRestartImport} color={colors.black} width={'100%'}>
          <FlyoutMenuIcon>
            <RestartIcon size={16} />
          </FlyoutMenuIcon>
          Restart Import
        </FlyoutMenuItem>
      )}
      {showCancelButton && (
        <FlyoutMenuItem onClick={onCancelImport} color={colors.black} width={'100%'}>
          <FlyoutMenuIcon>
            <CancelIcon size={16} />
          </FlyoutMenuIcon>
          Cancel Import
        </FlyoutMenuItem>
      )}
    </FlyoutMenu>
  );
}

export default ImportsFlyoutMenu;
