import React, { Fragment, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ClientsFilterBar, ClientsTable } from '~/components/clients';
import { Button } from '~/components/shared/buttons';
import { MainPage } from '~/components/shared/pageLayout';
import { PlusIcon } from '~/components/shared/svg';
import TabFilters from '~/components/shared/TabFilters';
import useTabs, { TabDef } from '~/lib/hooks/useTabs';
import { Client } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import colors from '~/styles/theme/colors';

import CollaborationModal from './collaborations/CollaborationModal';

type ClientsListProps = {
  tabDefs: TabDef[];
};

function ClientsList({ tabDefs }: ClientsListProps) {
  const profileSvc = useProfileContext();
  const canAddClients = profileSvc.has(PERMISSIONS.adminClientCreate);
  const navigate = useNavigate();
  const navigateToNewClient = () => navigate('/clients/new');
  const addClientButton = (
    <Button onClick={navigateToNewClient} icon={<PlusIcon size={14} fill={colors.white} />} text='Add Client' />
  );
  const [showCollaborationModal, setShowCollaborationModal] = useState(false);
  const [currentClient, setCurrentClient] = useState(new Client());
  const handleCollaborationCancel = () => setShowCollaborationModal(false);
  const handleCollaborationEdit = useCallback((client: Client) => {
    setShowCollaborationModal(true);
    setCurrentClient(client);
  }, []);

  const tabinfo = useTabs(tabDefs, 'Clients');

  return (
    <Fragment>
      <MainPage
        title='Clients'
        headerWhite
        rightContent={canAddClients && addClientButton}
        headerContent={
          <Fragment>
            <ClientsFilterBar />
            <TabFilters {...tabinfo} />
          </Fragment>
        }>
        <ClientsTable onCollaborationEdit={handleCollaborationEdit} />
      </MainPage>
      <CollaborationModal show={showCollaborationModal} client={currentClient} onCancel={handleCollaborationCancel} />
    </Fragment>
  );
}

export default ClientsList;
