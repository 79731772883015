import { useState } from 'react';
import * as _ from 'lodash-es';

import { AsyncThunk } from '@reduxjs/toolkit';

import { unwrapResult } from '~/lib';
import { store } from '~/store';

import useDeepEffect from './useDeepEffect';
import useModel from './useModel';

function useThunk<T>(
  thunk: AsyncThunk<T, any, any>,
  dependencies: any[] = [],
  options: any = {}
): {
  data: T extends { data: any } ? T['data'] : T extends { items: any } ? T['items'] : T;
  loaded: boolean;
  setData: (t: T extends { data: any } ? T['data'] : T extends { items: any } ? T['items'] : T) => void;
  refetch: () => void;
} {
  const {
    params = {},
    initialData = (thunk as any).defaultValue,
    model = (thunk as any).modelClass,
    onError = () => undefined,
    onCompleted = () => undefined,
    onSuccess = () => undefined,
    parseData = (payload: any) => payload?.data || payload?.items || payload,
    condition = true,
    debounce = 0,
  } = options;

  if (!model) {
    throw new Error(
      'useThunk expects a "model" options parameter or the "thunk" parameter to have a "modelClass" property'
    );
  }

  const [data, setData] = useState(initialData);
  const [loaded, setLoaded] = useState(false);

  const handleCompleted = () => {
    onCompleted();
    setLoaded(true);
  };

  const invoke = () => {
    if (condition) {
      setLoaded(false);
      store
        .dispatch(thunk(params))
        .then(unwrapResult)
        .then((payload: any) => {
          setData(parseData(payload));
          onSuccess(payload);
        })
        .catch(onError)
        .finally(handleCompleted);
    }
  };

  const debouncedInvoke = _.debounce(invoke, debounce);

  useDeepEffect(debounce > 0 ? debouncedInvoke : invoke, dependencies);

  return { data: useModel(model, data), loaded, setData, refetch: invoke };
}

export default useThunk;
