import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import ReactDatePicker from 'react-datepicker';

import { useField } from '~/lib/hooks';

import DatePickerInput from './DatePickerInput';

import 'react-datepicker/dist/react-datepicker.css';
import './datePickerCustomStyles.scss';

export interface DatePickerProps {
  clearable?: boolean;
  dateFormat?: string;
  disabled?: boolean;
  hasError?: boolean;
  onBlur?: (value: any, name: string) => void;
  onChange?: (value: any, name: string, setValue: (value: any, shouldValidate?: boolean) => void) => void;
  placeholder?: string;
  value?: moment.Moment | Date | null;
  minDate?: Date;
  maxDate?: Date;
}

const noop = () => undefined;
const DEFAULT_DATE_FORMAT = 'MM/dd/yyyy';

const DatePicker: React.FC<DatePickerProps> = (props) => {
  const {
    dateFormat = DEFAULT_DATE_FORMAT,
    disabled = false,
    hasError = false,
    onChange = noop,
    onBlur = noop,
    placeholder = DEFAULT_DATE_FORMAT.toUpperCase(),
    clearable = true,
  } = props;

  const [field, meta, helpers] = useField(props);
  const { name, value } = field;

  const inputValueIsMoment = () => value instanceof moment;
  const [isMoment, setIsMoment] = useState(inputValueIsMoment());

  const selectedDate = () => {
    if (!value) {
      return null;
    }

    return isMoment ? (value as moment.Moment).toDate() : new Date(value as Date);
  };

  const [dateValue, setDateValue] = useState<Date | null>(selectedDate());

  useEffect(() => {
    setIsMoment(inputValueIsMoment());
  }, [value]);

  useEffect(() => {
    setDateValue(selectedDate());
  }, [value]);

  const handleChange = (newDate: Date | null) => {
    let outputValue: Date | Moment | null = newDate;

    if (!clearable && !newDate) {
      outputValue = dateValue;
    }

    setDateValue(newDate);

    if (isMoment && outputValue) {
      outputValue = outputValue ? moment(outputValue) : moment();
    }

    helpers.setTouched(true);
    helpers.setValue(outputValue, true);
    onChange(outputValue, name, helpers.setValue);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
    onBlur(value, name);
  };

  const showError = hasError || Boolean(meta.error && typeof meta.error === 'string' && meta.touched);

  return (
    <ReactDatePicker
      {...field}
      {...props}
      autoComplete='off'
      wrapperClassName='standard-datepicker'
      value={dateValue}
      customInput={<DatePickerInput hasError={showError} />}
      dateFormat={dateFormat}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholderText={placeholder}
      selected={dateValue}
    />
  );
};

export default DatePicker;
