import React from 'react';

import colors from '~/styles/theme/colors';

interface RestartIconProps {
  fill?: string;
  size?: number;
}

const RestartIcon: React.FC<RestartIconProps> = ({ fill = colors.black50, size = 24 }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 15 14' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='TrashBin' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='ImportHistory' transform='translate(-1073.000000, -446.000000)' fill={fill} fillRule='nonzero'>
          <g id='refresh' transform='translate(1073.000000, 446.000000)'>
            <path
              d='M13.8302828,0.0420163383 C13.7217145,-0.00324670393 13.5965829,0.0211355574 13.5129513,0.103849315 L11.7384615,1.85967251 C10.4524277,0.660984029 8.75870057,-0.00382785089 7.00065555,0 C3.70885141,0 0.861806011,2.29360442 0.16111497,5.50996985 C-0.53957607,8.72633527 1.09542929,11.996346 4.08893626,13.3656124 C7.08244322,14.7348788 10.6255407,13.8333952 12.6006232,11.1999519 C12.9872203,10.6844891 12.882754,9.95322511 12.3672912,9.56662801 C11.8518284,9.18003092 11.1205644,9.28449721 10.7339673,9.79996 C9.41724568,11.5555889 7.05518066,12.156578 5.05950936,11.2437337 C3.06383805,10.3308894 1.97383447,8.15088224 2.44096183,6.00663862 C2.90808919,3.862395 4.80611946,2.33333644 7.00065555,2.33333644 C8.13429003,2.33113334 9.22904293,2.74644173 10.0759711,3.5 L8.09848255,5.46115172 C8.0193433,5.54023165 7.99217851,5.65749299 8.02848295,5.76331664 C8.06513089,5.86983291 8.16026778,5.94539618 8.27231488,5.95698219 L13.685617,6.55664539 L13.7182834,6.55664539 C13.7958286,6.55727332 13.8703801,6.5267461 13.9252146,6.4719116 C13.9800491,6.41707709 14.0105763,6.34252557 14.0099579,6.26498041 L14.0099579,0.311514782 C14.0097139,0.193722761 13.9389237,0.0875374146 13.8302828,0.0420163383 Z'
              id='Path'></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default RestartIcon;
