import React, { useState } from 'react';
import ReactSelect from 'react-select';

import useField from '~/lib/hooks/useField';

import InfiniteScrollWrapper from '../InfiniteScrollWrapper';
import { DropdownIndicator } from '../Select';
import { customTheme } from '../Select';

import Option from './CheckboxMultiSelectOption';
import Placeholder from './CheckboxMultiSelectPlaceholder';
import { customStyles } from './customStyles';

export type CheckboxMultiSelectProps<T> = {
  components?: { [key: string]: any };
  disabled?: boolean;
  getOptionLabel?: (option: T) => string;
  getOptionValue?: (option: T) => T;
  hasError?: boolean;
  menuPlacement?: string;
  meta?: Record<string, any>;
  onBlur?: (name: string) => void;
  onChange?: (value: T, name: string, setValue: (value: T[]) => void) => void;
  options: T[];
  value?: T[];
  styles?: Record<string, any>;
};

const noop = () => undefined;

function CheckboxMultiSelect<T>(props: CheckboxMultiSelectProps<T>) {
  const {
    components = {},
    disabled = false,
    hasError = false,
    menuPlacement = 'auto',
    onBlur = noop,
    onChange = noop,
    styles,
  } = props;
  const [field, meta, helpers] = useField(props);
  const { name } = field;
  const [inputValue, setInputValue] = useState('');

  const handleChange = (val: any) => {
    helpers.setValue(val);
    onChange(val, name, helpers.setValue);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
    onBlur(name);
  };

  const handleInputChange = (val: string, { action }: { action: string }) => {
    if (action !== 'set-value') {
      setInputValue(val);

      return val;
    }

    return inputValue;
  };

  const showError = hasError || Boolean(meta.error && typeof meta.error === 'string' && meta.touched);

  return (
    <InfiniteScrollWrapper
      {...field}
      {...props}
      closeMenuOnSelect={false}
      component={ReactSelect}
      controlShouldRenderValue={false}
      hideSelectedOptions={false}
      inputValue={inputValue}
      isMulti
      isClearable
      isDisabled={disabled}
      hasError={showError}
      onChange={handleChange}
      onBlur={handleBlur}
      onInputChange={handleInputChange}
      theme={customTheme}
      styles={customStyles(styles)}
      classNamePrefix={'react-select'}
      components={{ DropdownIndicator, Option, Placeholder, ...components }}
      menuPlacement={menuPlacement}
    />
  );
}

export default CheckboxMultiSelect;
