import React, { useEffect, useMemo, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import { InlineInputGroups, InputGroup, Select } from '~/components/shared/form';
import Search from '~/components/shared/Search';
import { ALL_OPTION, HEALTH_SYSTEM, PHYSICIAN_GROUP, SEARCH } from '~/constants/filterKeysConstants';
import { fetchClients } from '~/ducks/admin/clients';
import { clearFilters, getCollaborationsFilters, setFilter } from '~/ducks/admin/collaborationsFilters';
import { useThunk } from '~/lib/hooks';
import { useDebounce } from '~/lib/hooks';
import { Client } from '~/models';

const mapStateToProps = (state: any) => ({
  filters: getCollaborationsFilters(state),
});

const mapDispatchToProps = {
  clearFilters,
  setFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type CollaborationsFilterBarProps = ConnectedProps<typeof connector>;

function CollaborationsFilterBar({ filters, ...props }: CollaborationsFilterBarProps) {
  const [search, setSearch] = useState(filters[SEARCH]);
  const debouncedSearch = useDebounce(search);
  const { data: clients, loaded: clientsLoaded } = useThunk(fetchClients, []);
  const { healthSystems, physicianGroups } = useMemo(
    () => ({
      healthSystems: [ALL_OPTION, ...clients.filter((c) => c.isHealthSystem)],
      physicianGroups: [ALL_OPTION, ...clients.filter((c) => c.isPhysicianGroup)],
    }),
    [clients]
  );

  const updateFilter = (key: string, val: any) => clientsLoaded && props.setFilter({ filterType: key, value: val });

  useEffect(() => {
    updateFilter(SEARCH, search);
  }, [debouncedSearch]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.currentTarget.value);
  const handleOptionChange = (value: Client, filterType: string) => {
    updateFilter(filterType, value);
  };
  const handleSearchClear = () => setSearch('');
  const handleClearFilters = () => props.clearFilters();

  const showClearAll = Object.values(filters).some((value: any) => value.id);
  const byName = ({ name }: { name: string }) => name;
  const byId = ({ id }: { id: string }) => id;

  return (
    <FiltersContainer>
      <StyledSearch
        value={search}
        placeholder='Search Clients'
        onChange={handleSearchChange}
        onClear={handleSearchClear}
      />

      <InputGroup
        label='Physician Group'
        name={PHYSICIAN_GROUP}
        value={filters[PHYSICIAN_GROUP]}
        options={physicianGroups}
        getOptionLabel={byName}
        getOptionValue={byId}
        onChange={handleOptionChange}
        component={Select<Client>}
      />

      <InputGroup
        label='Health System'
        name={HEALTH_SYSTEM}
        value={filters[HEALTH_SYSTEM]}
        options={healthSystems}
        getOptionLabel={byName}
        getOptionValue={byId}
        onChange={handleOptionChange}
        component={Select<Client>}
      />

      {showClearAll && (
        <ClearLinkContainer>
          <ClearLink onClick={handleClearFilters}>Clear Filters</ClearLink>
        </ClearLinkContainer>
      )}
    </FiltersContainer>
  );
}

export default connector(CollaborationsFilterBar);

const FiltersContainer = styled(InlineInputGroups)`
  & > * {
    max-width: 230px;
    margin-bottom: 24px;
  }
`;

const StyledSearch = styled(Search)`
  margin-right: 24px;
`;

const ClearLinkContainer = styled.div`
  height: 40px;
  display: flex;
  flex: 0;
  align-items: center;
`;

const ClearLink = styled.div`
  cursor: pointer;
  width: 76px;
  color: ${({ theme }) => theme.colors.primaryBlue};
`;
