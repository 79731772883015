import React, { useCallback, useMemo } from 'react';
import { FormikHelpers, withFormik } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import CircleSpinner from '~/components/shared/CircleSpinner';
import { createClient, fetchClient, updateClient } from '~/ducks/admin/clients';
import { addToast } from '~/ducks/toasts';
import { capitalize, humanize } from '~/helpers';
import { unwrapResult } from '~/lib';
import { useThunk } from '~/lib/hooks';

import { FormPage } from '../shared/pageLayout';

import ClientForm from './clientForm/ClientForm';
import validationSchema from './clientFormValidations';

const mapDispatchToProps = {
  addToast,
  createClient,
  updateClient,
};

const connector = connect(null, mapDispatchToProps);

type EditClientProps = ConnectedProps<typeof connector>;

function EditClient(props: EditClientProps) {
  const { id } = useParams();
  const { data: client, loaded } = useThunk(fetchClient, [], {
    condition: !!id,
    params: {
      id,
      include: 'parent,children,episodeClassifications,planTypeClassifications,groupTypes,networkGroups',
    },
  });

  const navigate = useNavigate();
  const goToClients = () => navigate('/clients');

  const handleSubmit = useCallback((values: any, { setErrors, setSubmitting }: FormikHelpers<any>) => {
    const params = {
      id: values.id,
      name: values.name,
      clientType: values.clientType.name,
      parentId: values.parent?.id || null,
      episodeClassificationIds: (values.episodeClassifications || []).map((ec: any) => ec.id),
      planTypeClassificationIds: (values.planTypeClassifications || []).map((pc: any) => pc.id),
      groupTypeIds: (values.groupTypes || []).map((gt: any) => gt.id),
      mfaConfig: {
        enabled: values.mfaConfig.enabled,
        default: values.mfaConfig.default?.id || null,
        allowedFactors: values.mfaConfig.allowedIndependentFactors
          .concat(values.mfaConfig.allowedDependentFactors)
          .map((factor: any) => factor.id),
      },
      ssoEnabled: values.ssoEnabled,
      fileRetentionDays: values.fileRetentionDays,
      networkGroupIds: Object.values(values.networkGroups).reduce(
        (acc: any, networkGroup: any) => acc.concat(networkGroup.groups.map((group: any) => group.id)),
        []
      ),
    };

    const clientRequest = values.id ? props.updateClient : props.createClient;

    clientRequest(params)
      .then(unwrapResult)
      .then(goToClients)
      .catch((e) => {
        const errors = e.response?.data?.errors;

        if (errors) {
          const errorMessages = Object.keys(errors).reduce((obj: any, key) => {
            obj[key] = errors[key].map((err: any) => `${capitalize(humanize(key))} ${err}`).join(', ');
            return obj;
          }, {});

          setErrors(errorMessages);
        } else {
          props.addToast({ text: 'Something went wrong. Please try again.' });
        }
      })
      .finally(() => setSubmitting(false));
  }, []);

  const formikOptions = useMemo(
    () => ({
      enableReinitialize: true,
      handleSubmit,
      mapPropsToValues: () => ({ ...client.toFormValues() }),
      validationSchema,
    }),
    [client]
  );

  const FormikClientForm = useMemo(() => withFormik(formikOptions)(ClientForm), [formikOptions]) as any;

  if (id && !loaded) {
    return <CircleSpinner centered />;
  }

  return (
    <FormPage>
      <FormikClientForm onCancel={goToClients} />
    </FormPage>
  );
}

export default connector(EditClient);
