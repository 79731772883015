import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { createAsyncThunk } from '~/lib';
import { Client } from '~/models';
import { Paginated } from '~/models/Paginated';
import { adminClientsApi } from '~/services/api';

import { API_STATES, createApiHasStatusSelector } from '../api';

import { updateCollaboration } from './collaborations';

const SLICE_NAME = 'admin/clients';

const getClientsState = (state: any) => state[SLICE_NAME];

const cancellableFetchClients = adminClientsApi.fetch.cancellable();

export const fetchClients = createAsyncThunk<Paginated<Client>>(
  'admin/clients/fetch',
  async (params) => {
    const defaults = { pageSize: 1000, sortBy: 'name' };
    const res = await cancellableFetchClients({ ...defaults, ...params });

    return res.data;
  },
  {
    defaultValue: [],
    modelClass: Client,
  } as any
);

const cancellableFetchClientsForGroups = adminClientsApi.fetch.cancellable();

export const fetchClientsForGroups = createAsyncThunk(
  'admin/clientsForGroups/fetch',
  async (params) => {
    const defaults = { sortBy: 'name' };
    const res = await cancellableFetchClientsForGroups({ ...defaults, ...params });

    return res.data;
  },
  {
    defaultValue: [],
    modelClass: Client,
  } as any
);

export const fetchClient = createAsyncThunk<Client>(
  'admin/clients/fetchById',
  async ({ id, ...params }) => {
    const res = await adminClientsApi.fetchById.invoke(id, params);

    return res.data;
  },
  {
    modelClass: Client,
  } as any
);

export const createClient = createAsyncThunk('admin/clients/create', async (newClient) => {
  const res = await adminClientsApi.create.invoke(newClient);

  return res.data;
});

export const updateClient = createAsyncThunk('admin/clients/update', async ({ id, ...params }) => {
  const res = await adminClientsApi.update.invoke(id, params);

  return res.data;
});

const clientsAdapter = createEntityAdapter();

const initialPaginationState: Partial<Paginated<Client>> = {
  links: {
    next: null,
    prev: null,
  },
  meta: {
    totalRecords: 0,
    totalPages: 0,
  },
};

const initialState = clientsAdapter.getInitialState({
  pagination: initialPaginationState,
});

const clientsSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    clearClients: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchClients.fulfilled, (state, { payload: { links, meta, data } }) => {
        state.pagination = { links, meta };
        clientsAdapter.upsertMany(state, data);
      })
      .addCase(fetchClient.fulfilled, clientsAdapter.upsertOne)
      .addCase(createClient.fulfilled, clientsAdapter.addOne)
      .addCase(updateClient.fulfilled, (state, { payload }) => {
        const { id, ...rest } = payload;

        clientsAdapter.updateOne(state, { id, changes: { ...rest } });
      })
      .addCase(updateCollaboration.fulfilled, (state, { payload }) => {
        const { ownerId: id, collaboratorIds } = payload;

        clientsAdapter.updateOne(state, { id, changes: { collaboratorIds } });
      });
  },
});

export const { clearClients } = clientsSlice.actions;

export const getClientsLoaded = createApiHasStatusSelector(fetchClients, [API_STATES.complete, API_STATES.failed]);

export const { selectAll: getClients, selectById: getClient } = clientsAdapter.getSelectors(getClientsState);

export const getClientsPageCount = (state: any) => getClientsState(state).pagination.meta.totalPages;

export default clientsSlice;
