import React, { useEffect, useState } from 'react';

import InfiniteScrollMenuList from './InfiniteScrollMenuList';

interface InfiniteScrollWrapperProps {
  component: React.ElementType;
  components: Record<string, any>;
  getNextPage: () => void;
  loading: boolean;
  options: object[];
  hasMore: boolean;
  setSearch: (value: any) => void;
  onChange: (newValue: any, actionMeta: any) => void;
  onInputChange: (newValue: any, actionMeta: any) => any;
  [key: string]: any; // for rest props
}

const noop = () => undefined;
const identity = (x: any) => x;

const InfiniteScrollWrapper: React.FC<InfiniteScrollWrapperProps> = ({
  component: ReactSelectComponent,
  components,
  getNextPage = noop,
  loading = false,
  options,
  hasMore,
  setSearch = noop,
  onChange = noop,
  onInputChange = identity,
  ...rest
}) => {
  const [menuListElement, setMenuListElement] = useState<HTMLElement | null>(null);

  const handleInputChange = (newValue: any, actionMeta: any) => {
    setSearch(onInputChange(newValue, actionMeta));
  };

  const infiniteScrollProps = {
    defaultOptions: true,
    hasMore,
    isLoading: loading,
    loadingMessage: () => '',
    onEndReached: getNextPage,
    onInputChange: handleInputChange,
    options,
  };

  const shouldGetNextPage = () => {
    const element = menuListElement;

    if (element) {
      const boundingRect = element.getBoundingClientRect();

      // The height of the dropdown isn't big enough to scroll and there are
      // more options to retrieve.
      return hasMore && !loading && element.scrollHeight <= boundingRect.height + 50;
    }

    return false;
  };

  const tryGetNextPage = () => {
    if (shouldGetNextPage()) {
      getNextPage();
    }
  };

  const handleOnChange = (newValue: any, actionMeta: any) => {
    tryGetNextPage();

    onChange(newValue, actionMeta);
  };

  useEffect(() => {
    tryGetNextPage();
  }, [options, menuListElement]);

  return (
    <ReactSelectComponent
      {...rest}
      {...infiniteScrollProps}
      bindMenuListRef={setMenuListElement}
      onChange={handleOnChange}
      components={{
        ...components,
        LoadingIndicator: null,
        MenuList: InfiniteScrollMenuList,
      }}
    />
  );
};

export default InfiniteScrollWrapper;
