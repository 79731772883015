import React, { useEffect } from 'react';
import { FormikHelpers } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import CircleSpinner from '~/components/shared/CircleSpinner';
import { FormPage } from '~/components/shared/pageLayout';
import { ACUTE_CLIENT_TYPES } from '~/constants/clientTypes';
import { fetchClients } from '~/ducks/admin/clients';
import { createQuestionTemplate, fetchQuestionTemplate, updateQuestionTemplate } from '~/ducks/questionTemplates';
import { addToast } from '~/ducks/toasts';
import { unwrapResult } from '~/lib';
import { useThunk } from '~/lib/hooks';
import { QuestionTemplate } from '~/models';

import { TemplateForm } from './TemplateForm';

const mapDispatchToProps = {
  addToast,
  createQuestionTemplate,
  updateQuestionTemplate,
};

const connector = connect(null, mapDispatchToProps);

type TemplateFormContainerProps = ConnectedProps<typeof connector>;

function TemplateFormContainer(props: TemplateFormContainerProps) {
  const { id: questionTemplateId, type: questionTemplateType } = useParams<{ id?: string; type?: string }>();
  const navigate = useNavigate();

  const { data: questionTemplate, loaded: templateLoaded } = useThunk(fetchQuestionTemplate, [questionTemplateId], {
    condition: !!questionTemplateId,
    params: questionTemplateId,
  });

  const { data: clients } = useThunk(fetchClients, [], { params: { clientType: ACUTE_CLIENT_TYPES.join(',') } });

  const goToQuestionTemplates = ({ type }: { type: any }) => navigate(`/question-templates/${type.value ?? type}`);

  const submitForm = ({ type, ...values }: any, { setErrors, setSubmitting }: FormikHelpers<any>) => {
    const template = new QuestionTemplate({ ...values, type: type.value });
    const request = template.id ? props.updateQuestionTemplate : props.createQuestionTemplate;

    request(template.serialize())
      .then(unwrapResult)
      .then(() => goToQuestionTemplates(template))
      .catch((error: any) => handleSubmitError({ error, setErrors, setSubmitting }));
  };

  const handleSubmitError = ({ error, setErrors, setSubmitting }: any) => {
    if (Array.isArray(error.response?.data?.message)) {
      handleValidationErrors(error, setErrors);
    } else {
      handleUnknownError(error);
    }

    setSubmitting(false);
  };

  const handleUnknownError = (e: any) => {
    const message = e.response?.data?.message || 'There was an error saving the template.';

    props.addToast({ text: message });
  };

  const handleValidationErrors = (e: any, setErrors: any) => {
    const { message } = e.response.data;

    setErrors({ groupTypes: message.join('\n\n') });
  };

  useEffect(() => {
    if (questionTemplate && !questionTemplate.id) {
      questionTemplate.type = questionTemplateType;
    }
  }, [questionTemplate]);

  const formProps = {
    clients,
    questionTemplate,
    onCancel: goToQuestionTemplates,
    onSubmit: submitForm,
  };

  if (questionTemplateId && !templateLoaded) return <CircleSpinner centered />;

  return (
    <FormPage>
      <TemplateForm {...formProps} />
    </FormPage>
  );
}

export default connector(TemplateFormContainer);
