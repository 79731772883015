import React from 'react';

import { Permission } from '~/models';

import Section from './Section';

function NonAdminSection({ permissions = [] }: { permissions?: Permission[] }) {
  return <Section label='User Permissions' permissions={permissions} />;
}

export default NonAdminSection;
