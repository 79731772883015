import React, { Fragment } from 'react';
import styled, { keyframes } from 'styled-components';

interface CircleSpinnerProps {
  centered?: boolean;
  margin?: string;
}

function CircleSpinner({ centered }: CircleSpinnerProps) {
  return (
    <Fragment>
      {centered ? (
        <Centered>
          <Spinner />
        </Centered>
      ) : (
        <Spinner />
      )}
    </Fragment>
  );
}

export default CircleSpinner;

const spin = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const Centered = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Spinner = styled.div<CircleSpinnerProps>`
  margin: ${({ margin }) => margin || 'auto'};
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 5px solid #ffffff;
  border-right: 5px solid #ffffff;
  border-bottom: 5px solid #ffffff;
  border-left: 5px solid #5253ef;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: ${spin} 0.85s infinite linear;
  animation: ${spin} 0.85s infinite linear;

  border-radius: 50%;
  width: 50px;
  height: 50px;

  &:after {
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }
`;
