import { createSelector, createSlice } from '@reduxjs/toolkit';

import {
  ACTIVE,
  ALL_OPTION,
  CLIENT,
  CLIENT_TYPE,
  ENABLED_PROVIDER_TYPE,
  GROUP,
  ROLE,
  SEARCH,
} from '~/constants/filterKeysConstants';
import { rootPathnameChanged } from '~/ducks/navigation';

export const initialState = {
  [ACTIVE]: true,
  [ENABLED_PROVIDER_TYPE]: [],
  [CLIENT]: ALL_OPTION,
  [CLIENT_TYPE]: ALL_OPTION,
  [GROUP]: ALL_OPTION,
  [ROLE]: ALL_OPTION,
  [SEARCH]: '',
};

const sliceName = 'admin/users/filters';

const usersFiltersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setFilter: (state, action) => {
      const { filterType, value } = action.payload;

      state[filterType] = value;
    },
    clearFilters: (state) => ({ ...initialState, [SEARCH]: state[SEARCH] }),
  },
  extraReducers: (builder) => {
    builder.addCase(rootPathnameChanged, () => initialState);
  },
});

export const getUsersFilters = (state) => state[sliceName];

export const getUsersFiltersForRequest = createSelector(getUsersFilters, (usersFilters) => {
  return Object.entries(usersFilters)
    .filter(([filterKey, filter]) => {
      switch (filterKey) {
        case SEARCH:
        case ACTIVE:
          return filter;
        case CLIENT_TYPE:
          return filter.name !== ALL_OPTION.name;
        case ENABLED_PROVIDER_TYPE:
          return filter?.length > 0;
        default:
          return filter.id;
      }
    })
    .reduce((acc, [filterKey, filter]) => {
      switch (filterKey) {
        case SEARCH:
        case ACTIVE:
          return { ...acc, [filterKey]: `${filter}` };
        case CLIENT_TYPE:
          return { ...acc, [filterKey]: `${filter.name}` };
        case ENABLED_PROVIDER_TYPE:
          return { ...acc, [filterKey]: `${filter.map((f) => f['id']).join(',')}` };
        default:
          return { ...acc, [filterKey]: `${filter.id}` };
      }
    }, {});
});

export const { clearFilters, setFilter } = usersFiltersSlice.actions;

export default usersFiltersSlice;
