import { useState } from 'react';
import styled from 'styled-components';

import { QuestionTemplateConfig } from '~/models/QuestionTemplate';

import ChartFields from './ChartFields';
import QuestionInput, { Form, QuestionInputProps } from './QuestionInput';

type Props = QuestionInputProps & {
  form: Form;
};

const RangeOrNumericQuestion = ({ form, ...props }: Props) => {
  const { name, value } = props.field;

  const [showChart, setShowChart] = useState(!!value.config?.chart);

  const handleConfigChange = (inputVal: string) => {
    setShowChart(inputVal === 'yes');

    form.setFieldValue(name, {
      ...value,
      config: inputVal === 'yes' ? { chart: { chartName: '', yAxis: '' } } : undefined,
    });
  };

  return (
    <QuestionInput {...props} configLabel='Chart' configName='chart' handleConfigChange={handleConfigChange}>
      <ChartSection>
        {showChart && <ChartFields name={name} value={value as QuestionTemplateConfig['chart']} />}
      </ChartSection>
    </QuestionInput>
  );
};

export default RangeOrNumericQuestion;

export const ChartSection = styled.div`
  margin-left: 40px;
`;
