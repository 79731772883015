import React from 'react';
import { components } from 'react-select';

import { useDeepEffect } from '~/lib/hooks';

const InnerValueContainer = components.ValueContainer as any;

type ValueContainerProps = {
  getValue: () => any[];
  selectProps: {
    getOptionLabel?: (option: any) => string;
  };
  setValue: (value: any[]) => void;
};

function ValueContainer(props: ValueContainerProps) {
  const values = props.getValue();

  useDeepEffect(() => {
    const getOptionLabel = props.selectProps.getOptionLabel;

    let sortedValues = values;

    if (getOptionLabel) {
      sortedValues = values.sort((optionA, optionB) => {
        const optionALabel = getOptionLabel(optionA);
        const optionBLabel = getOptionLabel(optionB);

        if (optionALabel && optionBLabel) {
          return optionALabel.localeCompare(optionBLabel);
        }

        return 0;
      });

      props.setValue(sortedValues);
    }
  }, [values]);

  return <InnerValueContainer {...props} />;
}

export default ValueContainer;
