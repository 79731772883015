import React from 'react';

interface PendingStatusIconProps {
  size?: number;
}

const PendingStatusIcon: React.FC<PendingStatusIconProps> = ({ size = 18 }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 18 18' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g
        id='view-status-details-import-history'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeOpacity='0.5'>
        <g id='ImportDetailsStatus' transform='translate(-238.000000, -362.000000)' fill='#FFFFFF' stroke='#0F1226'>
          <g id='Group-9-Copy-4' transform='translate(223.000000, 346.000000)'>
            <circle id='Oval' cx='24' cy='25' r='8'></circle>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default PendingStatusIcon;
