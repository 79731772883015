/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SortingState } from '@tanstack/react-table';

import DischargeReasonsFilters from '~/components/dischargeReasons/DischargeReasonFilters';
import { ConfirmationModal } from '~/components/shared/modal';
import { SEARCH } from '~/constants/filterKeysConstants';
import { archiveDischargeReason, fetchDischargeReasons } from '~/ducks/admin/dischargeReasons';
import {
  clearFilters,
  DischargeReasonsFilterState,
  getDischargeReasonsFilterForRequest,
  getDischargeReasonsFilters,
  setFilters,
} from '~/ducks/admin/dischargeReasonsFilters';
import { useThunk } from '~/lib/hooks';
import { DischargeReason } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import colors from '~/styles/theme/colors';
import { BaseIndexParams } from '~/types';

import { Button } from '../shared/buttons';
import { MainPage } from '../shared/pageLayout';
import { PlusIcon } from '../shared/svg';
import { ControlledTable, CountMessage } from '../shared/table';

import dischargeReasonsTableColumns from './dischargeReasonsTableColumns';

const mapStateToProps = (state: any) => ({
  filters: getDischargeReasonsFilters(state),
  filtersForRequest: getDischargeReasonsFilterForRequest(state),
});

const mapDispatchToProps = {
  setFilters,
  clearFilters,
  archiveDischargeReason,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type DischargeReasonsProps = ConnectedProps<typeof connector>;

function DischargeReasons(props: DischargeReasonsProps) {
  const profileSvc = useProfileContext();
  const navigate = useNavigate();
  const canCreateRecord = profileSvc.has(PERMISSIONS.adminDischargeReasonCreate);
  const [idToArchive, setIdToArchive] = useState<string>();
  const [tableProps, setTableProps] = useState({ setSorting: (_sort: SortingState) => {} });

  const [queryMeta, setQueryMeta] = useState({ totalPages: 0, totalRecords: 0 });
  const [pagingFilters, setPagingFilters] = useState<BaseIndexParams>({
    page: 1,
    pageSize: 10,
    sortBy: 'displayName asc',
  });

  const {
    data: dischargeReasons,
    loaded,
    refetch,
  } = useThunk(fetchDischargeReasons, [props.filtersForRequest, pagingFilters], {
    onSuccess: (payload: any) => {
      setQueryMeta({
        totalPages: payload?.meta?.totalPages,
        totalRecords: payload?.meta?.totalRecords,
      });
    },
    params: { ...props.filtersForRequest, ...pagingFilters, include: 'dischargedToGroupType,groupTypeDisplayNames' },
    debounce: 50,
  });

  const handleEdit = (dischargeReason: DischargeReason) => {
    navigate(`/discharge-reasons/${dischargeReason.id}/edit`);
  };

  const handlePagingFiltersChange = useCallback((newPagingFilters: BaseIndexParams) => {
    setPagingFilters(newPagingFilters);
  }, []);

  const handleArchive = useCallback((dischargeReason: DischargeReason) => {
    if (!profileSvc.has(PERMISSIONS.adminDischargeReasonEdit)) return;

    setIdToArchive(dischargeReason.id);
  }, []);

  const handleArchiveConfirm = useCallback(async () => {
    await props.archiveDischargeReason(idToArchive ?? '');
    refetch();
    setIdToArchive(undefined);
  }, [idToArchive]);

  const handleArchiveCancel = useCallback(() => {
    setIdToArchive(undefined);
  }, []);

  const defaultSortBy = [{ id: 'displayName', desc: false }];
  const searchValue = props.filters[SEARCH];

  useEffect(() => {
    const sortBy = searchValue ? [{ id: SEARCH, desc: true }] : defaultSortBy;

    tableProps.setSorting(sortBy);
  }, [searchValue]);

  return (
    <MainPage
      title='Discharge Dispositions'
      rightContent={
        canCreateRecord && (
          <Button
            onClick={() => navigate('/discharge-reasons/new')}
            icon={<PlusIcon size={14} fill={colors.white} />}
            text='Add Disposition'
          />
        )
      }
      middleContent={<CountMessage count={queryMeta.totalRecords} countType='result' />}>
      <DischargeReasonsFilters filters={props.filters} setFilters={props.setFilters} />

      <ControlledTable
        data={dischargeReasons}
        loading={!loaded}
        columns={dischargeReasonsTableColumns({ profileSvc, onEdit: handleEdit, onArchive: handleArchive })}
        filters={props.filters}
        onPagingFiltersChange={handlePagingFiltersChange}
        pageCount={queryMeta.totalPages}
        onInitialize={setTableProps}
        defaultSortBy={searchValue ? 'search desc' : 'displayName asc'}
      />

      <ConfirmationModal
        show={Boolean(idToArchive)}
        actionText='archive'
        objType='disposition'
        onConfirm={handleArchiveConfirm}
        onCancel={handleArchiveCancel}
      />
    </MainPage>
  );
}

export default connector(DischargeReasons);
