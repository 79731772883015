import { createAsyncThunk } from '~/lib';
import { LocationType } from '~/models';
import { Paginated } from '~/models/Paginated';
import { adminLocationTypesApi } from '~/services/api';

import { API_STATES, createApiHasStatusSelector } from '../api';

export const fetchLocationTypes = createAsyncThunk<Paginated<LocationType>>(
  'admin/locationTypes/fetch',
  async (payload) => {
    const { data } = await adminLocationTypesApi.fetch.invoke(payload);

    return data;
  },
  {
    defaultValue: [],
    modelClass: LocationType,
  } as any
);

export const getLocationTypesFetchDone = createApiHasStatusSelector(fetchLocationTypes, [
  API_STATES.complete,
  API_STATES.failed,
]);
