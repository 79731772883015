/* eslint-disable react/jsx-indent */
import React from 'react';
import styled from 'styled-components';

import useField from '~/lib/hooks/useField';

export interface InputProps {
  disabled?: boolean;
  hasError?: boolean;
  inputStyles?: Record<string, any>;
  onBlur?: (value: string, name: string) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, name: string, setValue: (value: any) => void) => void;
  placeholder?: string;
  type?: string;
  value?: string | null;
}

const noop = () => undefined;

const Input: React.FC<InputProps> = (props) => {
  const {
    disabled = false,
    hasError = false,
    type = 'text',
    onChange = noop,
    onBlur = noop,
    placeholder = 'Type...',
  } = props;

  const [field, meta, helpers] = useField(props);
  const { name, value } = field;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(e.target.value);
    onChange(e, name, helpers.setValue);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
    onBlur(value, name);
  };

  const showError = hasError || Boolean(meta.error && typeof meta.error === 'string' && meta.touched);

  return (
    <StyledInput
      {...field}
      {...props}
      type={type}
      hasError={showError}
      disabled={disabled}
      onChange={handleChange}
      onBlur={handleBlur}
      placeholder={placeholder}
    />
  );
};

export default Input;

const getBorderColor = ({ theme, hasError, disabled }: { theme: any; hasError?: boolean; disabled?: boolean }) => {
  let borderColor = theme.colors.black25;

  if (hasError) {
    borderColor = theme.colors.accentRed;
  }

  if (disabled) {
    borderColor = 'transparent';
  }

  return borderColor;
};

export const StyledInput = styled.input<InputProps>`
  box-sizing: border-box;
  border: 1px solid ${getBorderColor};
  border-radius: 3px;
  padding: 8px;
  font-size: ${({ theme }) => theme.textStyles.bodySmall.fontSize};
  line-height: ${({ theme }) => theme.textStyles.bodySmall.lineHeight};
  background-color: ${({ theme, disabled }) => (disabled ? theme.colors.black10 : theme.colors.white)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'inherit')};
  outline: none;
  width: 100%;
  height: ${({ inputStyles }) => inputStyles?.height || 'initial'};
  min-height: ${({ inputStyles }) => inputStyles?.minHeight || '40px'};

  &::placeholder {
    color: ${({ theme }) => theme.colors.black25};
  }

  &:focus {
    border-color: ${({ theme, hasError }) => (hasError ? theme.colors.accentRed : theme.colors.primaryBlue)};
  }
`;
