import React from 'react';
import { useNavigate } from 'react-router-dom';

import { StandardModal } from '~/components/shared/modal';

import ClientSelectForm from './ClientSelectForm';

type ClientSelectModalProps = {
  isOpen: boolean;
  onCancel: () => void;
};

function ClientSelectModal(props: ClientSelectModalProps) {
  const { isOpen, onCancel } = props;
  const navigate = useNavigate();

  const handleOnComplete = () => {
    onCancel();
    navigate('/clients');
  };

  return (
    <StandardModal show={isOpen} title={'Select View'} onCancel={onCancel} disableBackdropClick>
      <ClientSelectForm onComplete={handleOnComplete} onCancel={onCancel} />
    </StandardModal>
  );
}

export default ClientSelectModal;
