import React from 'react';

import { Row } from '@tanstack/react-table';

import { FlyoutMenu, FlyoutMenuIcon, FlyoutMenuItem } from '~/components/shared/FlyoutMenu';
import { DeactivateIcon, PencilIcon } from '~/components/shared/svg';
import { BodySmall } from '~/components/shared/typography';
import { DischargeReason } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import { colors } from '~/styles/theme';

export const { adminClientEdit, adminClientGroupTypeView, adminCollaborationEdit } = PERMISSIONS;
export const permissions = [adminClientEdit, adminClientGroupTypeView, adminCollaborationEdit];

export type DischargeReasonsFlyoutMenuProps = {
  onEdit: (reason: DischargeReason) => void;
  onArchive: (reason: DischargeReason) => void;
  row: Row<DischargeReason>;
};

function DischargeReasonsFlyoutMenu(props: DischargeReasonsFlyoutMenuProps) {
  const { onEdit, onArchive, row } = props;

  const profileSvc = useProfileContext();

  return (
    <FlyoutMenu data={row.original}>
      <FlyoutMenuItem onClick={onEdit} visible={profileSvc.has(PERMISSIONS.adminDischargeReasonEdit)}>
        <FlyoutMenuIcon>
          <PencilIcon size={18} />
        </FlyoutMenuIcon>
        Edit
      </FlyoutMenuItem>
      <FlyoutMenuItem onClick={onArchive} visible={profileSvc.has(PERMISSIONS.adminDischargeReasonEdit)}>
        <FlyoutMenuIcon>
          <DeactivateIcon size={18} />
        </FlyoutMenuIcon>
        <BodySmall color={colors.accentRed}>Archive</BodySmall>
      </FlyoutMenuItem>
    </FlyoutMenu>
  );
}

export default DischargeReasonsFlyoutMenu;
