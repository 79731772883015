import React from 'react';
import FontAwesome from 'react-fontawesome';
import styled from 'styled-components';

import { CircleXIcon } from '~/components/shared/svg';
import { verticalCenter } from '~/styles/mixins';
import { colors, textStyles } from '~/styles/theme';

interface SearchProps {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClear?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  placeholder?: string;
  value?: string;
  [key: string]: any; // For additional props that might be passed
}

const noop = () => undefined;

const Search: React.FC<SearchProps> = ({
  onChange = noop,
  onClear = noop,
  placeholder = 'Search...',
  value = '',
  ...rest
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => onChange(e);
  const handleClear = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => onClear(e);

  return (
    <SearchContainer {...rest}>
      <FontAwesome name='search' style={{ color: colors.black25, marginLeft: 8, marginRight: 8 }} />
      <Input placeholder={placeholder} value={value} onChange={handleChange} />
      {value && (
        <ClearSearchContainer onClick={handleClear}>
          <CircleXIcon size={16} />
        </ClearSearchContainer>
      )}
    </SearchContainer>
  );
};

export default Search;

const SearchContainer = styled.div`
  ${verticalCenter()};
  border-radius: 25px;
  padding: 8px 10px;
  background-color: ${colors.gray};
  max-width: 250px;
  min-width: 100px;
  width: 100%;
`;

const Input = styled.input`
  background-color: transparent;
  width: 100%;
  outline: none !important;
  border: none;
  color: ${textStyles.base.color};
  font-size: ${textStyles.base.fontSize};

  ::placeholder {
    color: ${colors.black25};
  }
`;

const ClearSearchContainer = styled.div`
  ${verticalCenter()};
  cursor: pointer;
  margin-left: 4px;
`;
