import React from 'react';

interface SSOErrorIconProps {
  size?: number;
}

const SSOErrorIcon: React.FC<SSOErrorIconProps> = ({ size = 80 }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 81 80' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='global-auth0-updates' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='sso-error' transform='translate(-680.000000, -258.000000)' fillRule='nonzero'>
          <g id='a-tag-remove' transform='translate(680.000000, 258.000000)'>
            <path
              d='M3.72237183,78.1384749 C2.21678241,78.1381495 0.859585692,77.2310593 0.28346506,75.840058 C-0.292655573,74.4490567 0.0257179811,72.847984 1.09016976,71.7832108 L25.2900473,47.5833332 C26.7508967,46.1723977 29.07299,46.1925761 30.5090993,47.6286854 C31.9452086,49.0647947 31.965387,51.386888 30.5544515,52.8477374 L6.35457389,77.0476149 C5.65745921,77.7472647 4.71003403,78.1399034 3.72237183,78.1384749 L3.72237183,78.1384749 Z'
              id='Path'
              fill='#3253EF'
            />
            <path
              d='M50.9102715,10.9205187 L55.2085421,12.409742 L61.0574664,6.54406395 L53.3004748,3.86346213 C52.7550468,3.67730923 39.8360353,-0.47576207 30.8746345,8.49122333 L8.53628593,30.8295719 C-0.432560999,39.7909727 3.73167947,52.7099842 3.92527849,53.2889198 L6.58912655,61.0124038 L12.4454969,55.1541719 L10.9823351,50.9154702 C10.9544122,50.8242553 8.11558037,41.7790857 13.8006901,36.093976 L36.1390386,13.7556275 C41.8278713,8.06865624 50.8674564,10.9093496 50.9102715,10.9205187 Z'
              id='Path'
              fill='#3253EF'
            />
            <path
              d='M50.2605979,31.6002488 C48.7550085,31.5999234 47.3978118,30.6928332 46.8216911,29.3018319 C46.2455705,27.9108306 46.5639441,26.3097579 47.6283958,25.2449847 L71.8282734,1.04510716 C73.2891228,-0.36582839 75.611216,-0.345650028 77.0473254,1.09045931 C78.4834347,2.52656865 78.5036131,4.84866191 77.0926775,6.30951129 L52.8928,30.5093889 C52.1956853,31.2090386 51.2482601,31.6016773 50.2605979,31.6002488 L50.2605979,31.6002488 Z'
              id='Path'
              fill='#3253EF'
            />
            <path
              d='M63.596592,42.7843114 C66.4316062,42.8264688 69.2265344,43.460527 71.802212,44.6458404 C77.8354276,35.9766997 74.4362756,25.3324766 74.268738,24.8373099 L71.5937207,17.0803183 L65.7373504,22.9366887 L67.2265736,27.2349593 C67.2377428,27.2777744 70.0784361,36.3173595 64.3914649,42.0061922 L63.596592,42.7843114 Z'
              id='Path'
              fill='#3253EF'
            />
            <path
              d='M42.829374,63.5515294 L42.0438087,64.3370946 C36.358699,70.0222043 27.3135294,67.1833725 27.2223145,67.1554496 L22.9761667,65.6922878 L17.1253809,71.5486581 L24.8563111,74.2125062 C25.3812622,74.3874899 36.0254853,77.7922266 44.6983491,71.7571494 C43.5104689,69.1820286 42.8738691,66.3870764 42.829374,63.5515294 L42.829374,63.5515294 Z'
              id='Path'
              fill='#3253EF'
            />
            <path
              d='M46.5375398,63.2462386 C46.5375398,72.4990855 54.0384543,80 63.2913012,80 C72.5441481,80 80.0450626,72.4990855 80.0450626,63.2462386 C80.0450626,53.9933917 72.5441481,46.4924772 63.2913012,46.4924772 C54.0384543,46.4924772 46.5375398,53.9933917 46.5375398,63.2462386 Z M70.7374174,61.3847096 C71.7655115,61.3847096 72.5989464,62.2181445 72.5989464,63.2462386 C72.5989464,64.2743327 71.7655115,65.1077677 70.7374174,65.1077677 L55.845185,65.1077677 C54.8170909,65.1077677 53.983656,64.2743327 53.983656,63.2462386 C53.983656,62.2181445 54.8170909,61.3847096 55.845185,61.3847096 L70.7374174,61.3847096 Z'
              id='Shape'
              fill='#D10D44'
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SSOErrorIcon;
