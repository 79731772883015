import * as _ from 'lodash-es';

import { NOTES, NUMERIC, RANGE, SATISFACTION_SCALE, STATUS, YES_NO } from '~/constants/activities';
import { DISCHARGE } from '~/constants/questionTemplateTypes';

import NotesQuestion from './inputs/NotesQuestion';
import RangeOrNumericQuestion from './inputs/RangeOrNumericQuestion';
import SatisfactionScaleQuestion from './inputs/SatisfactionScaleQuestion';
import StatusQuestion from './inputs/StatusQuestion';
import YesNoQuestion from './inputs/YesNoQuestion';

interface QuestionConfiguration {
  inputLabel: string;
  component: React.ComponentType<any>;
}

const questionsConfigurations: Record<string, QuestionConfiguration> = {
  [NOTES]: {
    inputLabel: 'Notes',
    component: NotesQuestion,
  },
  [NUMERIC]: {
    inputLabel: 'Numeric',
    component: RangeOrNumericQuestion,
  },
  [RANGE]: {
    inputLabel: 'Scale 1-10',
    component: RangeOrNumericQuestion,
  },
  [SATISFACTION_SCALE]: {
    inputLabel: '1-5 Satisfaction Scale',
    component: SatisfactionScaleQuestion,
  },
  [STATUS]: {
    inputLabel: 'Status',
    component: StatusQuestion,
  },
  [YES_NO]: {
    inputLabel: 'Yes/No',
    component: YesNoQuestion,
  },
};

const progressQuestionTypes = [YES_NO, RANGE, NUMERIC, STATUS];

const dischargeQuestionTypes = [SATISFACTION_SCALE, NOTES, YES_NO];

const questionOptions = _.keys(questionsConfigurations).map(function (key) {
  return {
    label: questionsConfigurations[key].inputLabel,
    value: key,
  };
});

const questionTypeOptionsForTemplateType = (templateType: string) =>
  questionOptions.filter((opt) =>
    (templateType === DISCHARGE ? dischargeQuestionTypes : progressQuestionTypes).includes(opt.value)
  );

export { questionsConfigurations as default, questionTypeOptionsForTemplateType };
