import React from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { BodySmallBold } from '~/components/shared/typography';
import { Classification } from '~/models';
import { PERMISSIONS, ProfileService } from '~/services/profile';

import EditFlyoutMenu, { EditFlyoutMenuProps } from '../shared/FlyoutMenu/EditFlyoutMenu';

const columnHelper = createColumnHelper<Classification>();

type ColumnFnProps = {
  profileSvc: ProfileService;
  type: 'Plan Type' | 'Episode Type' | 'Classification';
} & Omit<EditFlyoutMenuProps<Classification>, 'row'>;

export default function classificationsTableColumns({ profileSvc, onEdit }: ColumnFnProps) {
  const columns: ColumnDef<Classification, any>[] = [
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: 'Name',
      cell: (info) => <BodySmallBold>{info.getValue()}</BodySmallBold>,
    }),
    columnHelper.accessor((row) => row.clientNames, {
      id: 'clientNames',
      header: 'Associated Clients',
      enableSorting: false,
    }),
  ];

  if (profileSvc.has(PERMISSIONS.adminClassificationEdit)) {
    columns.push(
      columnHelper.display({
        id: 'menu',
        cell: (info) => (
          <EditFlyoutMenu row={info.row} onEdit={onEdit} permission={PERMISSIONS.adminClassificationEdit} />
        ),
        size: 75,
        meta: { style: { justifyContent: 'flex-end' } },
      })
    );
  }

  return columns;
}
