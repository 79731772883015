import React, { useCallback } from 'react';
import styled from 'styled-components';

import { DatePicker, InlineInputGroups, InputGroup, Select } from '~/components/shared/form';
import { InputContainer } from '~/components/shared/form/DatePicker/DatePickerInput';
import { ALL_OPTION, CLIENT, CREATED_AT, END_DATE, START_DATE, STATUS } from '~/constants/filterKeysConstants';
import { ImportStatus } from '~/constants/imports';
import { fetchClients } from '~/ducks/admin/clients';
import { capitalize, getId, getName } from '~/helpers';
import { useAsyncOptions } from '~/lib/hooks';

interface ImportFilterBarProps {
  clearFilters: () => void;
  filters: Record<string, any>;
  setFilter: (filter: { filterType: string; value: any }) => void;
}

function ImportFilterBar({ filters, clearFilters, setFilter }: ImportFilterBarProps) {
  const clientsAsyncOptions = useAsyncOptions(fetchClients, {
    params: { hasImportConfiguration: true, sortBy: 'name asc' },
    optionsToPrepend: [ALL_OPTION],
  });

  const statuses = Object.values(ImportStatus);
  const statusOptions = [ALL_OPTION, ...statuses.map((status) => ({ id: status, name: capitalize(status) }))];

  const startDate = filters[CREATED_AT]?.[0];
  const endDate = filters[CREATED_AT]?.[1];
  const maxDate = new Date();

  const updateFilter = (val: any, key: string) => setFilter({ filterType: key, value: val });

  const handleDateChange = useCallback(
    (date: Date | null, filterType: string) => {
      let dateRange = [];

      // using (date || startDate/endDate) to reset value if a falsy value is passed in
      if (filterType === START_DATE) {
        dateRange = [date || startDate, endDate];
      } else {
        dateRange = [startDate, date || endDate];
      }

      updateFilter(dateRange, CREATED_AT);
    },
    [updateFilter, startDate, endDate, filters]
  );

  return (
    <FiltersContainer>
      <InputGroup
        {...clientsAsyncOptions}
        label='Client'
        name={CLIENT}
        value={filters[CLIENT]}
        getOptionLabel={getName}
        getOptionValue={getId}
        onChange={updateFilter}
        component={Select}
      />

      <InputGroup
        label='Status'
        name={STATUS}
        value={filters[STATUS]}
        options={statusOptions}
        getOptionLabel={getName}
        getOptionValue={getId}
        onChange={updateFilter}
        component={Select}
      />

      <InputGroup
        label='Date Range (Start)'
        name={START_DATE}
        value={startDate}
        maxDate={endDate}
        onChange={(date) => handleDateChange(date, START_DATE)}
        component={DatePicker}
        clearable={false}
      />

      <InputGroup
        label='Date Range (End)'
        name={END_DATE}
        value={endDate}
        onChange={(date) => handleDateChange(date, END_DATE)}
        minDate={startDate}
        maxDate={maxDate}
        component={DatePicker}
        clearable={false}
      />
    </FiltersContainer>
  );
}

export default ImportFilterBar;

const FiltersContainer = styled(InlineInputGroups)`
  & > * {
    max-width: 230px;
    margin-bottom: 24px;
  }

  ${InputContainer} {
    max-width: 230px !important;
  }
`;
