import React from 'react';
import styled from 'styled-components';

import { FieldLabel } from '~/components/shared/form';
import RadioButton from '~/components/shared/inputs/RadioButton';
import { verticalCenter } from '~/styles/mixins';

interface RadioButtonGroupProps {
  className?: string;
  label?: string;
  value?: string;
  onChange: (value: string) => void;
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({ className, label, value, onChange }) => {
  const handleChange = (newValue: string) => {
    onChange(newValue);
  };

  return (
    <div className={className}>
      <FieldLabel>{label}</FieldLabel>
      <RadioButtonContainer>
        <RadioButton radioValue='yes' value={value} radioLabel='Yes' onChange={handleChange} />
        <RadioButton radioValue='no' value={value} radioLabel='No' onChange={handleChange} />
      </RadioButtonContainer>
    </div>
  );
};

export default RadioButtonGroup;

export const RadioButtonContainer = styled.div`
  ${verticalCenter()};
  margin-top: 10px;

  & > * {
    margin-right: 16px;
  }
`;
