import { configureStore } from '@reduxjs/toolkit';

import slices from '~/ducks';

const store = configureStore({
  reducer: slices,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export { store };
