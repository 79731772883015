import React from 'react';
import styled from 'styled-components';

import { Footer } from '~/components/layouts';
import { horizontalCenter } from '~/styles/mixins';

import PageContainer from './PageContainer';

interface FormPageProps {
  children?: React.ReactElement;
}

function FormPage({ children }: FormPageProps) {
  return (
    <FormPageWrapper>
      <Container>
        {children}
        <Footer />
      </Container>
    </FormPageWrapper>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export default FormPage;

const FormPageWrapper = styled(PageContainer)`
  background-color: ${({ theme }) => theme.colors.white};
  ${horizontalCenter()};
  height: unset;
  min-height: 100%;
  padding: 24px 24px 0 24px;
`;
