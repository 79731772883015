import { useCallback, useState } from 'react';

import { AsyncThunk } from '@reduxjs/toolkit';

import { useDebounce, useDeepEffect, useDeepMemo, usePagedThunk } from '~/lib/hooks';
import { Paginated } from '~/models/Paginated';

export type UseAsyncOptionsConfig<T, Opts = T> = {
  condition?: boolean;
  initialOptions?: Opts[];
  initialPagination?: any;
  optionsToAppend?: T[];
  optionsToPrepend?: T[];
  optionsTransform?: (opts: T[]) => Opts[];
  select?: (opts: Opts[]) => Opts[];
  pageSize?: number;
  params?: { [key: string]: any };
  [id: string]: any; //for ...thunkOptions
};

function useAsyncOptions<T, Opts = T>(
  thunk: AsyncThunk<Paginated<T>, any, any>,
  config?: UseAsyncOptionsConfig<T, Opts>
) {
  const {
    condition = true,
    initialOptions = [], //T[]?
    initialPagination,
    optionsToAppend = [],
    optionsToPrepend = [],
    optionsTransform = (opts: T[]) => opts as unknown as Opts[],
    select = (opts: Opts[]) => opts,
    pageSize = 10,
    ...thunkOptions
  } = config ?? {
    params: {},
  };

  const [options, setOptions] = useState(initialOptions);
  const [search, setSearch] = useState('');
  const [lastPageFetched, setLastPageFetched] = useState(false);
  const debouncedSearch = useDebounce(search, 250);
  const handleLastPageFetched = useCallback(() => setLastPageFetched(true), []);
  const pagedThunkOptions = useDeepMemo(
    () => ({
      ...thunkOptions,
      condition,
      initialData: initialOptions,
      initialPagination,
      onLastPageFetched: handleLastPageFetched,
      params: {
        ...thunkOptions?.params,
        pageSize,
        search,
      },
    }),
    [condition, initialOptions, pageSize, debouncedSearch, thunkOptions]
  );

  const { data, loaded, hasMore, getNextPage } = usePagedThunk(thunk, [pagedThunkOptions], pagedThunkOptions);

  useDeepEffect(() => {
    if (!condition) return;

    setOptions(optionsTransform([...optionsToPrepend, ...data, ...(lastPageFetched ? optionsToAppend : [])]));
  }, [condition, optionsToAppend, optionsToPrepend, lastPageFetched, data]);

  return {
    getNextPage,
    loading: condition && !loaded,
    options: select(options),
    hasMore,
    setSearch,
  };
}

export default useAsyncOptions;
