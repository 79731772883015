import React from 'react';

import theme from '~/styles/theme';

interface PlusIconProps {
  fill?: string;
  size?: number;
}

const PlusIcon: React.FC<PlusIconProps> = ({ fill = theme.colors.black50, size = 24 }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='plus-icon-black50' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <polygon
          fill={fill}
          fillRule='nonzero'
          points='24 13.7142857 13.7142857 13.7142857 13.7142857 24 10.2857143 24 10.2857143 13.7142857 0 13.7142857 0 10.2857143 10.2857143 10.2857143 10.2857143 0 13.7142857 0 13.7142857 10.2857143 24 10.2857143'
        />
      </g>
    </svg>
  );
};

export default PlusIcon;
