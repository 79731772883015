/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DISCHARGED_TO_GROUP_TYPE, GROUP_TYPE, SEARCH } from '~/constants/filterKeysConstants';
import { rootPathnameChanged } from '~/ducks/navigation';

export type DischargeReasonsFilterState = {
  [DISCHARGED_TO_GROUP_TYPE]: any;
  [GROUP_TYPE]: any;
  [SEARCH]: string;
};

export const initialState: DischargeReasonsFilterState = {
  [DISCHARGED_TO_GROUP_TYPE]: [],
  [GROUP_TYPE]: [],
  [SEARCH]: '',
};

const sliceName = 'admin/dischargeReasons/filters';

const dischargeReasonsFilterSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setFilters: (state, action: PayloadAction<Partial<DischargeReasonsFilterState>>) => ({
      ...state,
      ...action.payload,
    }),
    clearFilters: (state) => ({ ...initialState, [SEARCH]: state[SEARCH] }),
  },
  extraReducers: (builder) => {
    builder.addCase(rootPathnameChanged, () => initialState);
  },
});

export const getDischargeReasonsFilters = (state: any) => state[sliceName];

export const getDischargeReasonsFilterForRequest = createSelector(getDischargeReasonsFilters, (filters) => {
  return Object.entries(filters)
    .filter(([filterKey, filter]: any) => {
      switch (filterKey) {
        case SEARCH:
          return filter;
        case DISCHARGED_TO_GROUP_TYPE:
        case GROUP_TYPE:
          return filter?.length > 0;
        default:
          return filter.id;
      }
    })
    .reduce((acc, [filterKey, filter]: any) => {
      switch (filterKey) {
        case SEARCH:
          return { ...acc, [filterKey]: `${filter}` };
        case DISCHARGED_TO_GROUP_TYPE:
        case GROUP_TYPE:
          return { ...acc, [filterKey]: `${filter.map((f: any) => f['id']).join(',')}` };
        default:
          return { ...acc, [filterKey]: `${filter.id}` };
      }
    }, {});
});

export const { clearFilters, setFilters } = dischargeReasonsFilterSlice.actions;

export default dischargeReasonsFilterSlice;
