import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { resetApiStatuses } from '~/ducks/api';

export const rootPathnameChanged = createAction('navigation/rootPathnameChanged');
export const patientsRootChanged = createAction('navigation/patientsRootChanged');

const patientsRoots = ['/all-patients', '/patients'];
const sliceName = 'navigation';

export const updateNavigation = createAsyncThunk(`${sliceName}/update`, async ({ current, previous }, { dispatch }) => {
  if (current !== previous) {
    dispatch(resetApiStatuses());

    const currentRoot = current.split('/')[1];
    const previousRoot = previous?.split('/')[1];

    if (currentRoot !== previousRoot) {
      dispatch(rootPathnameChanged({ current, previous }));

      if (patientsRoots.includes(current)) {
        dispatch(patientsRootChanged(currentRoot));
      }
    }
  }
});

export const initialState = {
  latestPatientsRoot: '',
};

const navigationSlice = createSlice({
  name: sliceName,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(patientsRootChanged, (state, { payload }) => {
      state.latestPatientsRoot = payload;
    });
  },
});

export default navigationSlice;

export const getLatestPatientsRoot = (state) => state[sliceName].latestPatientsRoot;
