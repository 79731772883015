import React from 'react';
import ReactSelect from 'react-select';

import useField from '~/lib/hooks/useField';

import InfiniteScrollWrapper from '../InfiniteScrollWrapper';
import { DropdownIndicator } from '../Select';
import { customTheme } from '../Select';

import { customStyles } from './customStyles';
import ValueContainer from './ValueContainer';

export type MultiSelectProps<T> = {
  components?: { [key: string]: any };
  disabled?: boolean;
  getOptionLabel?: (option: T) => string;
  getOptionValue?: (option: T) => string | number;
  hasError?: boolean;
  menuPlacement?: 'auto' | 'bottom' | 'top';
  meta?: { error?: string; touched?: boolean };
  onBlur?: (name: string) => void;
  onChange?: (value: T[], name: string, setValue: (value: T[]) => void) => void;
  options: T[];
  styles?: { [key: string]: any };
  value?: T[];
  autoSelectSingleOption?: boolean;
  isClearable?: boolean;
  getSelectedCountLabel?: (matched: number) => string;
  closeMenuOnSelect?: boolean;
  selectedCountDefault?: string;
  menuPortalTarget?: any;
};

const noop = () => undefined;

function MultiSelect<T>(props: MultiSelectProps<T>) {
  const {
    components = {},
    disabled = false,
    hasError = false,
    menuPlacement = 'auto',
    onBlur = noop,
    onChange = noop,
    styles,
  } = props;

  const [field, meta, helpers] = useField(props);
  const { name } = field;

  const handleChange = (val: any) => {
    helpers.setValue(val);
    onChange(val, name, helpers.setValue);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
    onBlur(name);
  };

  const showError = hasError || Boolean(meta?.error && typeof meta.error === 'string' && meta.touched);

  return (
    <InfiniteScrollWrapper
      {...field}
      {...props}
      component={ReactSelect}
      isMulti
      isClearable
      isDisabled={disabled}
      hasError={showError}
      onChange={handleChange}
      onBlur={handleBlur}
      theme={customTheme}
      styles={customStyles(styles)}
      classNamePrefix={'react-select'}
      components={{ DropdownIndicator, ValueContainer, ...components }}
      menuPlacement={menuPlacement}
    />
  );
}

export default MultiSelect;
