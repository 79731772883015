import React from 'react';
import FontAwesome from 'react-fontawesome';
import { components } from 'react-select';

import theme from '~/styles/theme';

const InnerDropdownIndicator = components.DropdownIndicator as any;

type DropdownIndicatorProps = {
  isDisabled?: boolean;
  [key: string]: any; // to allow any additional props passed to components.DropdownIndicator
};

function DropdownIndicator(props: DropdownIndicatorProps) {
  return (
    <InnerDropdownIndicator {...props}>
      <FontAwesome
        name='caret-down'
        size='lg'
        style={{
          color: props.isDisabled ? theme.colors.black25 : theme.colors.black,
          lineHeight: '1em',
          marginTop: '1px',
        }}
      />
    </InnerDropdownIndicator>
  );
}

export default DropdownIndicator;
