import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { Button } from '~/components/shared/buttons';
import { LoggedOutIcon } from '~/components/shared/svg';

import { LabelBold } from '../shared/typography';

export default function LoggedOut() {
  const navigate = useNavigate();
  const handleOnClick = () => navigate('/');

  return (
    <>
      <Content>
        <Widget>
          <WidgetContent>
            <OlioLogo
              src='https://static-assets.olio.health/images/olio-block-logo-dark.png'
              alt='Olio Logo'></OlioLogo>
            <LoggedOutIcon />
            <Blurb>
              <LabelBold fontSize='14px'>You&apos;ve been logged out of Olio.</LabelBold>
              Click the button below to log back in.
            </Blurb>
            <StyledButton onClick={handleOnClick} text='Log Back in to Olio' />
          </WidgetContent>
        </Widget>
      </Content>
      <Footer>
        <p>
          Have a question or need help? Contact our customer support team at{' '}
          <a href='mailto:support@olio.health'>support@olio.health</a>
        </p>
      </Footer>
    </>
  );
}

const Content = styled.main`
  background-color: rgba(50, 83, 239, 0.05);
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: 'Lato';
  height: 100vh;
  justify-content: center;
`;

const Widget = styled.div`
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  min-width: 320px;
  width: 40%;
`;

const WidgetContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
`;

const OlioLogo = styled.img`
  height: 36px;
  margin-bottom: 36px;
`;

const Blurb = styled.p`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 24px;
  margin: 36px 0px;
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const Footer = styled.footer`
  background-color: #0f1226;
  bottom: 0;
  color: white;
  font-family: 'Lato';
  font-size: 16px;
  left: 0;
  line-height: 28px;
  padding: 16px 0;
  position: fixed;
  width: 100%;
  z-index: 10;

  & p {
    margin: 0px 8px;
    text-align: center;
  }

  & a {
    color: white;
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
  }
`;
