import React from 'react';

import colors from '~/styles/theme/colors';

type EyeIconProps = {
  fill?: string;
  size?: number;
};

const EyeIcon: React.FC<EyeIconProps> = ({ fill = colors.black50, size = 24 }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='eye-icon-black-50' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='eye-icon-black50' transform='translate(0.000000, 4.000000)' fill={fill} fillRule='nonzero'>
          <path
            d='M23.8467476,7.468 C23.6547517,7.163 19.0708494,0 12,0 C4.92915064,0 0.345248291,7.163 0.153252382,7.468 C-0.0510841272,7.79323397 -0.0510841272,8.20676603 0.153252382,8.532 C0.345248291,8.837 4.92915064,16 12,16 C19.0708494,16 23.6547517,8.837 23.8467476,8.532 C24.0510841,8.20676603 24.0510841,7.79323397 23.8467476,7.468 Z M12,12 C9.79090806,12 8.00008521,10.209139 8.00008521,8 C8.00008521,5.790861 9.79090806,4 12,4 C14.2090919,4 15.9999148,5.790861 15.9999148,8 C15.9999148,9.06086596 15.5784964,10.0782816 14.8283669,10.8284271 C14.0782373,11.5785726 13.0608434,12 12,12 Z'
            id='icon-view'
          />
        </g>
      </g>
    </svg>
  );
};

export default EyeIcon;
