import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useParams } from 'react-router-dom';

import { fetchPermissions } from '~/ducks/admin/permissions';
import { fetchUser, updateUser } from '~/ducks/admin/users';
import { useThunk } from '~/lib/hooks';
import { User } from '~/models';

import Permissions from '../../permissions/Permissions';

const mapDispatchToProps = {
  fetchUser,
  updateUser,
};

const connector = connect(null, mapDispatchToProps);

type UserPermissionsContainerProps = ConnectedProps<typeof connector>;

const UserPermissionsContainer = (props: UserPermissionsContainerProps) => {
  const { id: userId } = useParams<{ id?: string }>();
  const { data: user, setData: setUser } = useThunk(fetchUser, [userId], {
    condition: Boolean(userId),
    params: { id: userId, include: 'client,role' },
  });

  const { data: permissions } = useThunk(fetchPermissions, []);

  const handleFormSubmit = async (values: any) => {
    const updatedUser = {
      id: userId,
      permissions: values,
      include: 'role',
    };

    return props.updateUser(updatedUser);
  };

  const handleFormSubmitSuccess = (updatedUser: User) => setUser(updatedUser);
  const subTitle = `${user.client?.name} — ${user.role?.name}`;

  return (
    <Permissions
      pageTitle={user.name}
      onFormSubmit={handleFormSubmit}
      onFormSubmitSuccess={handleFormSubmitSuccess}
      pageSubtitle={subTitle}
      permissions={permissions}
      user={user}
    />
  );
};

export default connector(UserPermissionsContainer);
