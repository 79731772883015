import React, { useEffect } from 'react';
import FontAwesome from 'react-fontawesome';
import styled from 'styled-components';

import { colors } from '~/styles/theme';

type InfiniteScrollProps = {
  element: Element | null;
  hasMore?: boolean;
  loading?: boolean;
  onEndReached?: () => void;
  className?: string;
};

const InfiniteScroll: React.FC<InfiniteScrollProps> = ({
  className,
  element,
  hasMore = false,
  loading = false,
  onEndReached = () => undefined,
}) => {
  useEffect(() => {
    if (element) {
      (element as any).onscroll = ({ target }: any) => {
        const distanceFromBottom = target.scrollHeight - (target.clientHeight + target.scrollTop);

        if (distanceFromBottom < 100 && hasMore && !loading) {
          onEndReached();
        }
      };
    }
  }, [element, loading, hasMore, onEndReached]);

  return (
    <>
      {' '}
      {loading && (
        <LoadingContainer className={className}>
          <FontAwesome spin name='spinner' size='2x' style={{ color: colors.black25 }} />
        </LoadingContainer>
      )}
    </>
  );
};

export default InfiniteScroll;

const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 100%;
  height: 50px;
  margin-bottom: 10px;
`;
