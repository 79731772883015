import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import { createColumnHelper } from '@tanstack/react-table';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import DataTable from '~/components/shared/DataTable';
import { InputGroup, Select } from '~/components/shared/form';
import Search from '~/components/shared/Search';
import Slideout from '~/components/shared/Slideout';
import TrashIcon from '~/components/shared/svg/TrashIcon';
import { LabelBold } from '~/components/shared/typography';
import { fetchGroups } from '~/ducks/admin/groups';
import { getId } from '~/helpers';
import { useAddressOptions, useAsyncOptions } from '~/lib/hooks';
import { Group } from '~/models';
import { colors } from '~/styles/theme';

type Props = {
  clientId: string;
  groupTypeId: string;
  groupTypeDisplayName: string;
  groups: Group[];
  handleClose: () => void;
  handleApply: (groupTypeId: string, groups: Group[]) => void;
};

function AssociatedGroupsSlideout({
  clientId,
  groupTypeId,
  groupTypeDisplayName,
  groups,
  handleClose,
  handleApply,
}: Props) {
  const [selectedGroups, setSelectedGroups] = useState<Group[]>(groups || []);
  const [search, setSearch] = useState('');
  const addressProps = useAddressOptions();

  const asyncGroupsOptions = useAsyncOptions(fetchGroups, {
    params: {
      groupType: groupTypeId,
      include: 'groupType',
      ...(!!clientId && { 'clientId.not': clientId }),
    },
    select: (groupsOptions: Group[]) => {
      return groupsOptions.filter(
        (group: Group) => !selectedGroups.some((selectedGroup) => selectedGroup.id === group.id)
      );
    },
  });

  const tableData = useMemo(
    () => selectedGroups.filter((group) => group.name.toLowerCase().includes(search.toLowerCase())),
    [search, selectedGroups]
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => setSearch(e.currentTarget.value);
  const handleSearchClear = () => setSearch('');

  const handleAddGroup = (group: Group) => {
    setSelectedGroups((prevGroups) => {
      return [...prevGroups, group];
    });
  };

  const handleRemoveGroup = (id: string) => {
    setSelectedGroups((prevGroups) => prevGroups.filter((group) => group.id !== id));
  };

  const handleApplyAndClose = () => {
    handleApply(groupTypeId, selectedGroups);
    handleClose();
  };

  const columnHelper = createColumnHelper<Group>();

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: groupTypeDisplayName,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
      }),
      columnHelper.accessor('client.name', {
        header: 'Client',
        cell: (data) => (
          <ClientCell>
            <LabelBold>{data.getValue()}</LabelBold>
            <IconContainer>
              <StyledTrashIcon color={colors.accentRed} onClick={() => handleRemoveGroup(data.row.original.id)} />
            </IconContainer>
          </ClientCell>
        ),
      }),
    ],
    [groupTypeDisplayName, handleRemoveGroup]
  );

  return (
    <Slideout title={groupTypeDisplayName} handleClose={handleClose}>
      {{
        content: (
          <SlideoutContent>
            <InputGroup
              {...addressProps}
              {...asyncGroupsOptions}
              component={Select<Group>}
              getOptionValue={getId}
              onChange={handleAddGroup}
              closeMenuOnSelect={false}
              autoSelectSingleOption={false}
              value={null}
              name='associatedGroups'
              label={`Select ${groupTypeDisplayName}s`}
              placeholder='Select'
            />
            <TableBar>
              <ItemsCount>
                {selectedGroups.length} Associated {groupTypeDisplayName}s
              </ItemsCount>
              <Search value={search} onChange={handleSearchChange} onClear={handleSearchClear} />
            </TableBar>
            <DataTable
              columns={columns}
              data={tableData}
              defaultSortBy={[
                {
                  id: 'name',
                  desc: false,
                },
              ]}></DataTable>
          </SlideoutContent>
        ),
        footer: (
          <SlideoutFooter>
            <ButtonGroup>
              <Button color='transparent' text='Cancel' onClick={handleClose} />
              <Button color='primary' text='Apply' onClick={handleApplyAndClose} />
            </ButtonGroup>
          </SlideoutFooter>
        ),
      }}
    </Slideout>
  );
}

export default AssociatedGroupsSlideout;

const SlideoutContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const SlideoutFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  *:only-child {
    margin-left: auto;
  }

  padding: 16px 24px;
`;

const TableBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 16px;
`;

const ItemsCount = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: ${colors.black};
`;

const ClientCell = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTrashIcon = styled(TrashIcon)`
  cursor: pointer;
`;

const IconContainer = styled.div`
  width: 16px;
`;
