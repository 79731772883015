import { createSelector, createSlice } from '@reduxjs/toolkit';

import { ALL_OPTION, CLIENT_TYPE, GROUP_TYPE, MFA_ENABLED, SEARCH, SSO_ENABLED } from '~/constants/filterKeysConstants';
import { rootPathnameChanged } from '~/ducks/navigation';

export const initialState = {
  [CLIENT_TYPE]: ALL_OPTION,
  [GROUP_TYPE]: [],
  [SEARCH]: '',
  [SSO_ENABLED]: false,
  [MFA_ENABLED]: false,
};

const sliceName = 'clients/filters';

const clientsFiltersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setFilter: (state, action) => {
      const { filterType, value } = action.payload;

      state[filterType] = value;
    },
    clearFilters: (state) => ({ ...initialState, [SEARCH]: state[SEARCH] }),
  },
  extraReducers: (builder) => {
    builder.addCase(rootPathnameChanged, () => initialState);
  },
});

export const getClientsFilters = (state) => state[sliceName];

export const getClientsFiltersForRequest = createSelector(getClientsFilters, (clientsFilters) => {
  return Object.entries(clientsFilters)
    .filter(([filterKey, value]) => {
      switch (filterKey) {
        case SEARCH:
        case SSO_ENABLED:
        case MFA_ENABLED:
          return value;
        default:
          if (Array.isArray(value)) {
            return value?.length > 0;
          }

          return value.id;
      }
    })
    .reduce((acc, [filterKey, value]) => {
      switch (filterKey) {
        case SEARCH:
        case SSO_ENABLED:
        case MFA_ENABLED:
          return { ...acc, [filterKey]: `${value}` };
        default:
          if (Array.isArray(value)) {
            return { ...acc, [filterKey]: `${value.map((f) => f['id']).join(',')}` };
          }

          return { ...acc, [filterKey]: `${value.id}` };
      }
    }, {});
});

export const { clearFilters, setFilter } = clientsFiltersSlice.actions;

export default clientsFiltersSlice;
