import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { addToast } from '~/ducks/toasts';
import { createAsyncThunk } from '~/lib';
import { ImportConfiguration } from '~/models';
import { importConfigurationsApi } from '~/services/api';

import { API_STATES, createApiHasStatusSelector } from '../api';
import PaginationState from '../PaginationState';

const sliceName = 'admin/importConfigurations';

const cancellableFetchImportConfigurations = importConfigurationsApi.fetch.cancellable();

export const fetchImportConfigurations = createAsyncThunk(
  `${sliceName}/fetch`,
  async (params) => {
    const res = await cancellableFetchImportConfigurations(params);

    return res.data;
  },
  {
    defaultValue: [],
    modelClass: ImportConfiguration,
  }
);

export const fetchImportConfiguration = createAsyncThunk(
  `${sliceName}/fetchById`,
  async ({ id, ...params }) => {
    const { data } = await importConfigurationsApi.fetchById.invoke(id, params);

    return data;
  },
  {
    modelClass: ImportConfiguration,
  }
);

export const createImportConfiguration = createAsyncThunk(`${sliceName}/create`, async (payload, { dispatch }) => {
  const { data } = await importConfigurationsApi.create.invoke(payload).catch((e) => {
    dispatch(addToast({ text: 'There was an error creating the import configuration. Please try again.' }));
    throw e;
  });

  return data;
});

export const updateImportConfiguration = createAsyncThunk(`${sliceName}/update`, async (payload, { dispatch }) => {
  const { data } = await importConfigurationsApi.update.invoke(payload.id, payload).catch((e) => {
    dispatch(addToast({ text: 'There was an error updating the import configuration. Please try again.' }));
    throw e;
  });

  return data;
});

const importConfigurationsAdapter = createEntityAdapter();

export const initialState = importConfigurationsAdapter.getInitialState({
  pagination: new PaginationState(),
});

const importConfigurationsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    clearImportConfigurations: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchImportConfigurations.fulfilled, (state, { payload: { links, meta, data } }) => {
      state.pagination = { links, meta };
      importConfigurationsAdapter.setAll(state, data);
    });
  },
});

export const { clearImportConfigurations } = importConfigurationsSlice.actions;

const getImportConfigurationsState = (state) => state[sliceName];

export const { selectAll: getImportConfigurations } =
  importConfigurationsAdapter.getSelectors(getImportConfigurationsState);

export const getImportConfigurationsLoaded = createApiHasStatusSelector(fetchImportConfigurations, [
  API_STATES.complete,
  API_STATES.failed,
]);

export const getImportConfigurationsPageCount = (state) =>
  getImportConfigurationsState(state).pagination.meta.totalPages;

export const getImportConfigurationsTotalRecords = (state) =>
  getImportConfigurationsState(state).pagination.meta.totalRecords;

export default importConfigurationsSlice;
