import React from 'react';

interface ConfigureCareIconProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
}

function ConfigureCareIcon({ size = 16, ...rest }: ConfigureCareIconProps) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} {...rest}>
      <g fill='#0F1226' fillOpacity='.5' fillRule='nonzero'>
        <path d='M5.25 10.51h3.5v3.5h-3.5zM5.25 0h3.5v3.5h-3.5zM10.5 10.51H14v3.5h-3.5zM0 10.51h3.5v3.5H0zM2.625 7.88h3.5v1.75h1.75V7.88h3.5v1.75h1.75V7.005c0-.525-.35-.875-.875-.875H7.875V4.38h-1.75v1.75H1.75c-.525 0-.875.35-.875.875V9.63h1.75V7.88Z' />
      </g>
    </svg>
  );
}

export default ConfigureCareIcon;
