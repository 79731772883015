import { immerable } from 'immer';

interface Links {
  next?: string | null;
  prev?: string | null;
}

interface Meta {
  totalRecords: number;
  totalPages: number;
}

interface PaginationStateOptions {
  links: Links;
  meta: Meta;
}

const defaults: PaginationStateOptions = {
  links: { next: null, prev: null },
  meta: { totalRecords: 0, totalPages: 0 },
};

export default class PaginationState {
  [immerable] = true;

  links: Links;
  meta: Meta;

  constructor(options: Partial<PaginationStateOptions> = {}) {
    const opts = { ...defaults, ...options };

    this.links = { ...defaults.links, ...opts.links };
    this.meta = { ...defaults.meta, ...opts.meta };
  }
}
