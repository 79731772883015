import React, { ReactNode } from 'react';
import styled from 'styled-components';

import { Footer } from '~/components/layouts';

import MainPageHeader from './MainPageHeader';
import PageContainer from './PageContainer';

interface MainPageProps {
  banner?: ReactNode;
  children?: ReactNode;
  headerContent?: ReactNode;
  headerSticky?: boolean;
  headerWhite?: boolean;
  middleContent?: ReactNode;
  onScroll?: () => void;
  rightContent?: ReactNode | boolean;
  subtitle?: string;
  title?: string;
}

function MainPage({
  banner = null,
  title = '',
  subtitle = '',
  rightContent = null,
  headerContent = null,
  children,
  headerSticky = false,
  onScroll,
  ...props
}: MainPageProps) {
  const showPageInfoContainer = Boolean(title || subtitle || rightContent);
  const showHeaderContainer = Boolean(showPageInfoContainer || headerContent);

  return (
    <StyledPageContainer headerSticky={headerSticky}>
      {banner}
      {showHeaderContainer && (
        <MainPageHeader
          showPageInfoContainer={showPageInfoContainer}
          title={title}
          subtitle={subtitle}
          rightContent={rightContent}
          headerContent={headerContent}
          {...props}
        />
      )}
      <ContentContainer onScroll={onScroll} headerSticky={headerSticky}>
        <div>{children}</div>
        <Footer />
      </ContentContainer>
    </StyledPageContainer>
  );
}

export default MainPage;

const StyledPageContainer = styled(PageContainer)<{ headerSticky?: boolean }>`
  overflow-y: ${({ headerSticky }) => (headerSticky ? 'hidden' : 'scroll')};
`;

const ContentContainer = styled.div<{ headerSticky?: boolean }>`
  flex: 1;
  padding: 24px 24px 0px 24px;
  overflow-y: ${({ headerSticky }) => (headerSticky ? 'scroll' : 'visible')};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
