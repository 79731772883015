import React from 'react';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';

import * as Sentry from '@sentry/react';

import Error from './Error';

Sentry.init({
  dsn: import.meta.env.SENTRY_DSN,
  environment: import.meta.env.SENTRY_ENVIRONMENT,
  integrations: [
    // currently using react-router v6
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  ignoreErrors: [/UnhandledRejection.*Non-Error promise rejection/],
  release: import.meta.env.COMMIT_SHA,
  tracesSampleRate: 0.1,
});

function ErrorBoundary(props: any) {
  return <Sentry.ErrorBoundary fallback={Error}>{props.children}</Sentry.ErrorBoundary>;
}

export default ErrorBoundary;
