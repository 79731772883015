import { createSelector, createSlice } from '@reduxjs/toolkit';

import { addToast } from '~/ducks/toasts';
import { createAsyncThunk } from '~/lib';
import { Profile, User, UserPreference } from '~/models';
import { meApi } from '~/services/api';

import { updateUser } from './admin/users';
import { updateUserPreference } from './userPreferences';

export const fetchProfile = createAsyncThunk('profile/fetch', async (params) => {
  const res = await meApi.profile.invoke(params);

  return res.data;
});

export const updateProfile = createAsyncThunk('profile/update', async (params, { dispatch }) => {
  try {
    const res = await meApi.update.invoke(params);

    return res.data;
  } catch (e) {
    const errorMessage = e?.response?.data?.error || 'There was an error updating. Please try again.';

    dispatch(addToast({ text: errorMessage }));
    throw e;
  }
});

const SLICE_NAME = 'profile';
const initialState = new Profile();
const anyProfileThunkFulfilled = (action) =>
  [fetchProfile.fulfilled, updateProfile.fulfilled].toString().includes(action.type);

const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  extraReducers: (builder) =>
    builder
      .addCase(updateUser.fulfilled, (state, { payload }) => {
        const updatedUser = new User(payload);

        if (state.id === updatedUser.id) {
          state.permissions = updatedUser.permissions;
        }
      })
      .addCase(updateUserPreference.fulfilled, (state, { payload }) => {
        const updatedUserPreference = new UserPreference(payload);
        const index = state.preferences.findIndex((pref) => pref.id === updatedUserPreference.id);

        if (index !== -1) {
          state.preferences[index] = updatedUserPreference;
        } else {
          state.preferences.push(updatedUserPreference);
        }
      })
      .addMatcher(anyProfileThunkFulfilled, (state, { payload }) => ({ ...state, ...payload })),
});

export const getSlice = (state) => state[SLICE_NAME];
export const getProfile = createSelector(getSlice, (profile) => new Profile(profile));

export const getProfileLoaded = (state) => !!getProfile(state).id;

export default slice.reducer;
