import React from 'react';
import { Field } from 'formik';

import questionsConfigurations from './questionsConfigurations';

interface QuestionProps {
  question: {
    kind: keyof typeof questionsConfigurations;
  };
  index: number;
  onRemove: (index: number) => void;
}

function Question({ question, index, onRemove }: QuestionProps) {
  const fieldName = `questions.${index}`;
  const questionConfig = questionsConfigurations[question.kind];

  if (!questionConfig) {
    return null;
  }

  const handleRemove = () => onRemove(index);

  return (
    <Field
      name={fieldName}
      component={questionConfig.component}
      inputLabel={questionConfig.inputLabel}
      removeItem={handleRemove}
    />
  );
}

export default Question;
