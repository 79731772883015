import React, { useState } from 'react';
import ReactSelect from 'react-select';

import { isDeepEqual } from '~/lib';
import { useDeepEffect } from '~/lib/hooks';
import useField from '~/lib/hooks/useField';

import InfiniteScrollWrapper from '../InfiniteScrollWrapper';

import { customStyles, customTheme } from './customStyles';
import DropdownIndicator from './DropdownIndicator';

export type SelectProps<T> = {
  components?: { [key: string]: any };
  disabled?: boolean;
  getOptionLabel?: (option: T) => React.ReactNode;
  getOptionValue?: (option: T) => string | number;
  hasError?: boolean;
  loading?: boolean;
  menuPlacement?: 'auto' | 'top' | 'bottom';
  onBlur?: (name: string) => void;
  onChange?: (value: T, name: string, setValue: (value: T) => void) => void;
  options: T[];
  styles?: { [key: string]: any };
  value?: T | null;
  autoSelectSingleOption?: boolean;
  isClearable?: boolean;
  closeMenuOnSelect?: boolean;
  menuPortalTarget?: any;
};

const noop = () => undefined;

function Select<T>(props: SelectProps<T>) {
  const {
    autoSelectSingleOption = true,
    components = {},
    options,
    disabled = false,
    getOptionLabel = ({ label }: any) => label,
    getOptionValue = ({ value }: any) => value,
    hasError = false,
    loading = false,
    menuPlacement = 'auto',
    onBlur = noop,
    onChange = noop,
    styles = {},
  } = props;

  const [prevOptions, setPrevOptions] = useState(options);
  const [field, meta, helpers] = useField(props);
  const { name } = field;

  const handleChange = (val: T) => {
    helpers.setValue(val);
    onChange(val, name, helpers.setValue);
  };

  const handleBlur = () => {
    helpers.setTouched(true);
    onBlur(name);
  };

  const showError = hasError || Boolean(meta.error && typeof meta.error === 'string' && meta.touched);
  const areEqual = (value: T | null, other: T | null) =>
    value && other && getOptionValue(value) === getOptionValue(other);
  const handleOptionsChange = () => {
    const optionValueChanged = !(
      areEqual(meta.value, options[0]) ||
      (options.length && areEqual(meta.value, getOptionValue(options[0])))
    );
    const optionsChanged = !isDeepEqual(prevOptions, options);

    if (!loading && autoSelectSingleOption && options.length === 1 && optionValueChanged && optionsChanged) {
      handleChange(options[0]);
    }
  };

  useDeepEffect(() => {
    setPrevOptions(options);
  }, [options]);
  useDeepEffect(handleOptionsChange, [loading, autoSelectSingleOption, options]);

  const allStyles = customStyles(styles);

  return (
    <InfiniteScrollWrapper
      {...field}
      {...props}
      component={ReactSelect}
      isDisabled={disabled}
      hasError={showError}
      onChange={handleChange}
      onBlur={handleBlur}
      theme={customTheme}
      styles={allStyles}
      classNamePrefix={'react-select'}
      components={{ DropdownIndicator, ...components }}
      menuPlacement={menuPlacement}
    />
  );
}

export default Select;
