import { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router';

import { PERMISSIONS, useProfileContext } from '~/services/profile';

export type TabDef = {
  label: string;
  permission?: keyof typeof PERMISSIONS;
  path: string;
};

function useTabs(tabDefs: TabDef[], title?: string) {
  const profileSvc = useProfileContext();
  const navigate = useNavigate();
  const tabs = useMemo(
    () =>
      tabDefs.map((x) =>
        !x.permission || profileSvc?.has(x.permission)
          ? {
              label: x.label,
              value: x.path,
            }
          : ({} as { label: string; value: string })
      ),
    []
  );
  const onTabClick = useCallback(
    (val: string) => {
      navigate(val);
    },
    [navigate]
  );
  const selectedTab = tabs.find((tab) => tab.label === title);

  return { tabs, onTabClick, selectedTab };
}

export default useTabs;
