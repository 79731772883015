import React from 'react';

import theme from '~/styles/theme';

type CancelIconProps = React.SVGProps<SVGSVGElement> & {
  size: number;
};

const CancelIcon: React.FC<CancelIconProps> = ({ fill = theme.colors.accentRed, size = 24 }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <g id='cancel-icon-red' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <path
          d='M23.55,0.45 C22.95,-0.15 22.05,-0.15 21.45,0.45 L12,9.9 L2.55,0.45 C1.95,-0.15 1.05,-0.15 0.45,0.45 C-0.15,1.05 -0.15,1.95 0.45,2.55 L9.9,12 L0.45,21.45 C-0.15,22.05 -0.15,22.95 0.45,23.55 C0.75,23.85 1.05,24 1.5,24 C1.95,24 2.25,23.85 2.55,23.55 L12,14.1 L21.45,23.55 C21.75,23.85 22.2,24 22.5,24 C22.8,24 23.25,23.85 23.55,23.55 C24.15,22.95 24.15,22.05 23.55,21.45 L14.1,12 L23.55,2.55 C24.15,1.95 24.15,1.05 23.55,0.45 Z'
          fill={fill}
          fillRule='nonzero'
        />
      </g>
    </svg>
  );
};

export default CancelIcon;
