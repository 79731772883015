import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

import { createAsyncThunk } from '~/lib';
import { QuestionTemplate } from '~/models';
import { questionTemplatesApi } from '~/services/api';

import { API_STATES, createApiHasStatusSelector } from './api';
import PaginationState from './PaginationState';

const SLICE_NAME = 'questionTemplates';

const cancellableFetchQuestionTemplates = questionTemplatesApi.fetch.cancellable();

export const fetchQuestionTemplates = createAsyncThunk(
  `${SLICE_NAME}/fetch`,
  async (params) => {
    const defaults = { sortBy: 'clientName' };

    const res = await cancellableFetchQuestionTemplates({ ...defaults, ...params });

    return res.data;
  },
  {
    defaultValue: [],
    modelClass: QuestionTemplate,
  }
);

export const fetchQuestionTemplate = createAsyncThunk(
  `${SLICE_NAME}/fetchById`,
  async (id) => {
    const res = await questionTemplatesApi.fetchById.invoke(id);

    return res.data;
  },
  {
    modelClass: QuestionTemplate,
  }
);

export const createQuestionTemplate = createAsyncThunk(`${SLICE_NAME}/create`, async (params) => {
  const res = await questionTemplatesApi.create.invoke(params);

  return res.data;
});

export const updateQuestionTemplate = createAsyncThunk(`${SLICE_NAME}/update`, async (params) => {
  const res = await questionTemplatesApi.update.invoke(params.id, params);

  return res.data;
});

const questionTemplatesAdapter = createEntityAdapter();
const initialState = questionTemplatesAdapter.getInitialState({
  pagination: new PaginationState(),
});

const questionTemplatesSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    clearQuestionTemplates: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchQuestionTemplates.fulfilled, (state, { payload: { links, meta, data } }) => {
      state.pagination = { links, meta };
      questionTemplatesAdapter.upsertMany(state, data);
    });
  },
});

export const { clearQuestionTemplates } = questionTemplatesSlice.actions;

const getQuestionTemplatesState = (state) => state[SLICE_NAME];

export const { selectAll: getQuestionTemplates } = questionTemplatesAdapter.getSelectors(getQuestionTemplatesState);

export const getQuestionTemplatesLoaded = createApiHasStatusSelector(fetchQuestionTemplates, [
  API_STATES.complete,
  API_STATES.failed,
]);

export const getQuestionTemplatesPageCount = (state) => getQuestionTemplatesState(state).pagination.meta.totalPages;

export default questionTemplatesSlice.reducer;
