import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';

import { Permission, User } from '~/models';
import { useProfileContext } from '~/services/profile';

import { AdminSection, NonAdminSection } from './sections';

interface PermissionsFormProps {
  bindResetForm?: (resetFunc: () => void) => void;
  bindSetSubmitting?: (setSubmittingFunc: (isSubmitting: boolean) => void) => void;
  bindSubmitForm?: (submitFunc: () => void) => void;
  dirty: boolean;
  isSubmitting: boolean;
  isValid: boolean;
  onDirtyChanged?: (dirty: boolean) => void;
  onIsSubmittingChanged?: (isSubmitting: boolean) => void;
  onIsValidChanged?: (isValid: boolean) => void;
  permissions: Permission[];
  resetForm: () => void;
  setStatus: (status: { disabled: boolean }) => void;
  setSubmitting: (isSubmitting: boolean) => void;
  submitForm: () => void;
  values: {
    user: User;
  };
}

function PermissionsForm(props: PermissionsFormProps) {
  const {
    bindResetForm = () => undefined,
    bindSetSubmitting = () => undefined,
    bindSubmitForm = () => undefined,
    dirty,
    isSubmitting,
    isValid,
    onDirtyChanged = () => undefined,
    onIsSubmittingChanged = () => undefined,
    onIsValidChanged = () => undefined,
    permissions,
    resetForm,
    setStatus,
    setSubmitting,
    submitForm,
    values,
  } = props;

  const profileSvc = useProfileContext();
  const isDisabled = profileSvc.profile.id === values.user.id;

  useEffect(() => setStatus({ disabled: isDisabled }), [isDisabled]);
  useEffect(() => bindResetForm(resetForm), []);
  useEffect(() => bindSetSubmitting(setSubmitting), []);
  useEffect(() => bindSubmitForm(submitForm), []);
  useEffect(() => onDirtyChanged(dirty), [dirty]);
  useEffect(() => onIsSubmittingChanged(isSubmitting), [isSubmitting]);
  useEffect(() => onIsValidChanged(isValid), [isValid]);

  const nonAdminPermissions = useMemo(
    () => permissions.filter((permission) => !permission.name.startsWith('admin')),
    [permissions]
  );
  const adminPermissions = useMemo(
    () => permissions.filter((permission) => permission.name.startsWith('admin')),
    [permissions]
  );

  return (
    <StyledForm>
      <NonAdminSection permissions={nonAdminPermissions} />
      {values.user.client?.isAdmin && (
        <SectionContainer>
          <AdminSection permissions={adminPermissions} />
        </SectionContainer>
      )}
    </StyledForm>
  );
}

export default PermissionsForm;

const StyledForm = styled.form`
  display: flex;
  flex-wrap: wrap;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
