import React, { useState } from 'react';
import styled from 'styled-components';

import AssociatedGroupsSlideout from '~/components/clients/clientForm/AssociatedGroupsSlideout';
import { getClientTypeName } from '~/components/clients/helpers';
import { Button, ButtonGroup } from '~/components/shared/buttons';
import Foldout from '~/components/shared/Foldout';
import {
  Checkbox,
  Form,
  FormHeader,
  FormSection,
  Input,
  InputGroup,
  MultiSelect,
  MultiSelectLabel,
  SectionHeader,
  Select,
} from '~/components/shared/form';
import Launcher from '~/components/shared/Launcher';
import { EPISODE, PLAN_TYPE } from '~/constants/classifications';
import { CLIENT_TYPES, HEALTH_SYSTEM, PAYOR } from '~/constants/clientTypes';
import { PROVIDER } from '~/constants/groupTypes';
import { fetchClassifications } from '~/ducks/admin/classifications';
import { fetchClients } from '~/ducks/admin/clients';
import { fetchGroupTypes } from '~/ducks/admin/groupTypes';
import { getDisplayName, getId, getKind, getName } from '~/helpers';
import { sortBy } from '~/helpers/sortBy';
import { useAsyncOptions } from '~/lib/hooks';
import Client, { MFA_FACTORS, MfaConfig } from '~/models/Client';
import GroupType from '~/models/GroupType';

const filterMfaOptionsByIndependence = (val: boolean) =>
  Object.entries(MFA_FACTORS)
    .filter(([_value, { independent }]) => independent === val)
    .map(([value, { label }]) => ({ name: label, id: value }));

interface ClientFormProps {
  dirty: boolean;
  isSubmitting: boolean;
  isValid: boolean;
  onCancel: () => void;
  setFieldValue: (field: string, value: any) => void;
  setValues: (values: any) => void;
  values: {
    children: Client[];
    clientType: { name: string; kind: string } | null;
    groupTypes: GroupType[];
    id: string;
    leaf: boolean;
    mfaConfig: {
      enabled: boolean;
      allowedDependentFactors: { id: string; name: string }[];
      allowedIndependentFactors: { id: string; name: string }[];
      default: { id: string; name: string } | null;
    };
    networkGroups: Record<string, { groupTypeId: string; groupTypeDisplayName: string; groups: any[] }>;
    ssoEnabled: boolean;
  };
}

function ClientForm(props: ClientFormProps) {
  const { dirty, isValid, isSubmitting, onCancel, setValues, values } = props;
  const { id, children } = values;
  const clientTypes = CLIENT_TYPES.map((type) => ({ name: type, kind: type }));

  const [showSlideout, setShowSlideout] = useState(false);
  const [slideoutGroupType, setSlideoutGroupType] = useState<any>(null);

  const episodesAsyncOptions = useAsyncOptions(fetchClassifications, { params: { type: EPISODE } });
  const planTypesAsyncOptions = useAsyncOptions(fetchClassifications, { params: { type: PLAN_TYPE } });
  const providerAsyncOptions = useAsyncOptions(fetchGroupTypes, { params: { sortBy: 'name asc', type: PROVIDER } });
  const mfaIndependentFactorOptions = filterMfaOptionsByIndependence(true);
  const mfaDependentFactorOptions = filterMfaOptionsByIndependence(false);
  const clientsAsyncOptions = useAsyncOptions(fetchClients, {
    optionsTransform: (clients: Client[]) => {
      return clients.filter((clientOption) => clientOption.id !== id);
    },
  });

  const groupTypeAsyncOptions = useAsyncOptions(fetchGroupTypes, {
    params: { sortBy: 'name asc' },
    select: (groupTypes: GroupType[]) => {
      return groupTypes.filter((gt) => {
        if (values.clientType?.name === HEALTH_SYSTEM && gt.name === 'Hospital') {
          return false;
        }

        return !values.networkGroups[gt.id];
      });
    },
  });

  const commonSelectProps = {
    autoSelectSingleOption: false,
    getOptionLabel: getName,
    getOptionValue: getId,
    isClearable: true,
    component: Select,
  };
  const commonMultiSelectProps = {
    ...commonSelectProps,
    component: MultiSelect,
    labelComponent: MultiSelectLabel,
  };

  const handleSsoChange = (val: boolean) => {
    if (val) {
      setValues({
        ...values,
        ssoEnabled: true,
        mfaConfig: new MfaConfig().toFormValues(),
      });
    }
  };

  const handleMfaEnabledChange = (val: boolean) => {
    if (!val) {
      setValues({
        ...values,
        mfaConfig: new MfaConfig().toFormValues(),
      });
    }
  };

  const handleAllowedIndependentFactorsChange = (factors: Array<{ id: string; name: string }>) => {
    if (factors.length === 0 || !factors.some((factor) => factor.id === values.mfaConfig.default?.id)) {
      setValues({
        ...values,
        mfaConfig: {
          ...values.mfaConfig,
          allowedIndependentFactors: factors,
          default: null,
        },
      });
    }
  };

  const formatParentClientLabel = (client: Client) => {
    const clientType = client.clientType === PAYOR ? 'Payer' : client.clientType;

    return `${client.name} (${clientType})`;
  };

  const handleAddGroupType = (groupType: GroupType) => {
    setValues({
      ...values,
      networkGroups: {
        ...values.networkGroups,
        [groupType.id]: { groupTypeId: groupType.id, groupTypeDisplayName: groupType.displayName, groups: [] },
      },
    });
  };

  const handleOpenSlideout = (groupType: any) => {
    setSlideoutGroupType(groupType);
    setShowSlideout(true);
  };

  const handleCloseSlideout = () => {
    setShowSlideout(false);
  };

  const handleApplySlideoutChanges = (groupTypeId: string, groups: any[]) => {
    setValues({
      ...values,
      networkGroups: {
        ...values.networkGroups,
        [groupTypeId]: { ...values.networkGroups[groupTypeId], groups },
      },
    });
    setShowSlideout(false);
  };

  return (
    <Form>
      <FormHeader title={id ? 'Edit Client' : 'Add Client'} />
      <StyledFormSection>
        <SectionHeader>Organization Setup</SectionHeader>
        <InputGroup name='name' label='Client Name' placeholder='Client Name' component={Input} />
        <InputGroup
          name='clientType'
          data-cy='clientType'
          label='Client Type'
          placeholder='Client Type'
          options={sortBy(clientTypes, 'name')}
          getOptionLabel={getClientTypeName}
          getOptionValue={getKind}
          onChange={(val) => {
            setValues({
              ...values,
              clientType: val,
              networkGroups: {},
            });
          }}
          component={Select}
          disabled={Boolean(children.length)}
        />
        <InputGroup
          {...commonSelectProps}
          {...clientsAsyncOptions}
          component={Select<Client>}
          name='parent'
          data-cy='parent'
          getOptionLabel={formatParentClientLabel}
          label='Parent Client (optional)'
          placeholder='Parent Client'
        />
        <InputGroup
          {...commonMultiSelectProps}
          {...providerAsyncOptions}
          name='groupTypes'
          data-cy='groupTypes'
          label='Enabled Care Options (optional)'
          placeholder='Enabled Care Options'
          getOptionLabel={getDisplayName}
          closeMenuOnSelect={false}
        />
        <InputGroup
          {...commonMultiSelectProps}
          {...episodesAsyncOptions}
          name='children'
          data-cy='children'
          label='Associated Clients (optional)'
          placeholder='Associated Clients'
          disabled
          visible={Boolean(children.length)}
        />
        <ClientCheckbox name='ssoEnabled' label='SSO Enabled' onChange={handleSsoChange} />
        <InputGroup
          name='fileRetentionDays'
          label='File Retention Days (optional)'
          placeholder='File Retention Days'
          component={Input}
        />
        <SectionHeader>Multi-factor Authentication (MFA) Configuration</SectionHeader>
        <ClientCheckbox
          name='mfaConfig.enabled'
          label='MFA Enabled'
          disabled={values.ssoEnabled}
          onChange={handleMfaEnabledChange}
        />
        {values.mfaConfig?.enabled && (
          <>
            <InputGroup
              {...commonMultiSelectProps}
              component={
                MultiSelect<{
                  id: string;
                  name: string;
                }>
              }
              options={mfaIndependentFactorOptions}
              name='mfaConfig.allowedIndependentFactors'
              data-cy='allowedIndependentFactors'
              label='Enabled Independent Factors'
              placeholder='Enabled Independent Factors'
              onChange={handleAllowedIndependentFactorsChange}
              disabled={!values.mfaConfig.enabled || values.ssoEnabled}
            />
            <InputGroup
              {...commonSelectProps}
              options={values.mfaConfig.allowedIndependentFactors}
              name='mfaConfig.default'
              data-cy='defaultFactor'
              label='Default Factor'
              placeholder='Default Factor'
              disabled={!values.mfaConfig.enabled || values.ssoEnabled}
            />
            <InputGroup
              {...commonMultiSelectProps}
              options={mfaDependentFactorOptions}
              name='mfaConfig.allowedDependentFactors'
              data-cy='allowedDependentFactors'
              label='Enabled Dependent Factors (optional)'
              placeholder='Enabled Dependent Factors'
              disabled={!values.mfaConfig.enabled || values.ssoEnabled}
            />
          </>
        )}
        <SectionHeader>Episodes and Plan Types</SectionHeader>
        <InputGroup
          {...commonMultiSelectProps}
          {...episodesAsyncOptions}
          name='episodeClassifications'
          data-cy='episodeClassifications'
          label='Episode Types'
          placeholder='Episodes'
        />
        <InputGroup
          {...commonMultiSelectProps}
          {...planTypesAsyncOptions}
          name='planTypeClassifications'
          data-cy='planTypeClassifications'
          label='Plan Types'
          placeholder='Plan Types'
        />
        <SectionHeader>Associated Groups</SectionHeader>
        <InputGroup
          {...commonSelectProps}
          {...groupTypeAsyncOptions}
          component={Select<GroupType>}
          getOptionLabel={getDisplayName}
          onChange={handleAddGroupType}
          value={null}
          name='associatedGroupTypes'
          label='Add a group type'
          placeholder='Add a group type'
        />
        {Object.values(values.networkGroups)
          .sort((a, b) => a.groupTypeDisplayName.localeCompare(b.groupTypeDisplayName))
          .map((groupType) => (
            <Launcher
              key={groupType.groupTypeId}
              label={groupType.groupTypeDisplayName}
              subLabel={`${groupType.groups.length} associated`}
              handleLaunch={() => handleOpenSlideout(groupType)}
              enabled={values.groupTypes.some((gt) => gt.id === groupType.groupTypeId)}
            />
          ))}

        <Foldout open={showSlideout}>
          {slideoutGroupType && (
            <AssociatedGroupsSlideout
              {...slideoutGroupType}
              clientId={id}
              handleClose={handleCloseSlideout}
              handleApply={handleApplySlideoutChanges}
            />
          )}
        </Foldout>
      </StyledFormSection>
      <ButtonGroup>
        <Button color='transparent' text='Cancel' onClick={onCancel} />
        <Button
          type='submit'
          disabled={!dirty || !isValid || isSubmitting}
          text={id ? 'Update Client' : 'Create Client'}
        />
      </ButtonGroup>
    </Form>
  );
}

export default ClientForm;

const ClientCheckbox = styled(Checkbox)`
  margin: 8px 0px 24px 0px;
`;

const StyledFormSection = styled(FormSection)`
  margin-bottom: 40px;
`;
