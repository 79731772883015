import React, { useMemo } from 'react';
import { FormikProps, useFormikContext } from 'formik';
import styled from 'styled-components';

import { AsyncThunk } from '@reduxjs/toolkit';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import {
  Checkbox,
  Form,
  FormHeader,
  FormSection,
  Input,
  InputGroup,
  MultiSelect,
  MultiSelectLabel,
  SectionHeader,
  Select,
} from '~/components/shared/form';
import { ATTR_OLIO_MAPPED_FIELDS } from '~/constants/attrMappedFields';
import { OWNER_CLIENT_TYPES } from '~/constants/clientTypes';
import { fetchAttrMatches, fetchAttrs } from '~/ducks/admin/attrs';
import { fetchClassifications } from '~/ducks/admin/classifications';
import { fetchClients } from '~/ducks/admin/clients';
import { fetchGroups } from '~/ducks/admin/groups';
import { getId, getName } from '~/helpers';
import { useAsyncOptions, useDebounce, useThunk } from '~/lib/hooks';
import { Attr, AttrMatchOption, Classification, Client, Group } from '~/models';
import { IAttrOptions } from '~/models/Attr';
import { Paginated } from '~/models/Paginated';
import colors from '~/styles/theme/colors';

export type AttrValueFormValues = {
  client: Client;
  name: string;
  displayName: string;
  visible: boolean;
  attr: Attr;
  associatedClassifications: Classification[];
  associatedGroups: Group[];
  active: boolean;
};

type AttrValueFormProps = {
  isEditing: boolean;
  onCancel: () => void;
};

type MatchesResponse = {
  meta: object;
  data: AttrMatchOption[];
};

type Props = AttrValueFormProps & FormikProps<AttrValueFormValues>;

export default function AttrValueForm({ onCancel, isEditing, ...props }: Props) {
  const asyncClientOptions = useAsyncOptions(fetchClients, {
    params: { clientType: OWNER_CLIENT_TYPES.join(','), sortBy: 'name asc' },
  });

  const clientId = props.values.client?.id;
  const attr = props?.values?.attr;
  const olioAttrApiName = ATTR_OLIO_MAPPED_FIELDS.find(({ value }) => value === attr?.olioAttr)?.['apiName'];
  const olioAttrObjectType = attr?.olioAttrObjectType;

  const asyncAttributeOptions = useAsyncOptions(fetchAttrs, {
    params: { client: clientId },
    condition: !!clientId,
  });

  const associatedOptionsFunc = useMemo(() => {
    return attr?.isClassificationType ? fetchClassifications : fetchGroups;
  }, [attr]) as AsyncThunk<Paginated<Group | Classification>, any, any>;

  const asyncAssociatedObjectOptions = useAsyncOptions(associatedOptionsFunc, {
    params: { clientId, type: olioAttrApiName },
    condition: !!(clientId && attr?.olioAttr),
  });

  const { handleSubmit, isValid, dirty, isSubmitting, setFieldValue } = useFormikContext();

  const onSubmit = () => handleSubmit();

  const handleClientChange = (newValue: Client | null) => {
    setFieldValue('client', newValue);

    // clear dependent data
    setFieldValue('attr', null);
    setFieldValue('associatedGroups', []);
    setFieldValue('associatedClassifications', []);
    setFieldValue('matches', []);
  };

  const handleAttrChange = (newValue: IAttrOptions) => {
    if (newValue.olioAttr) {
      setFieldValue('visible', true);
    } else {
      setFieldValue('visible', false);
    }

    setFieldValue('associatedGroups', []);
    setFieldValue('associatedClassifications', []);
    setFieldValue('matches', []);
    setFieldValue('attr', newValue);
  };

  const handleNameBlur = (val: string) => {
    if (val && !props.values.displayName) {
      setFieldValue('displayName', val);
    }
  };

  const matchesSearch = useMemo(() => {
    return props.values.displayName || props.values.name;
  }, [props.values.displayName, props.values.name]);
  const debouncedMatchesSearch = useDebounce(matchesSearch, 250);

  useThunk(fetchAttrMatches, [debouncedMatchesSearch, props.values.attr], {
    params: {
      id: props.values.attr?.id,
      search: debouncedMatchesSearch,
    },
    condition: !!(props.values.attr?.olioAttr && debouncedMatchesSearch),
    onSuccess: ({ data }: MatchesResponse) => {
      setFieldValue('matches', data);
    },
  });

  return (
    <Form>
      <FormHeader title={isEditing ? 'Edit Value' : 'Add Value'} />

      <InputGroup
        {...asyncClientOptions}
        name='client'
        label='Client'
        autoSelectSingleOption={false}
        data-cy='client'
        onChange={handleClientChange}
        getOptionLabel={getName}
        getOptionValue={getId}
        disabled={isEditing}
        component={Select<Client>}
      />

      <FormSection>
        <SectionHeader>Attribute Details</SectionHeader>

        <InputGroup
          {...asyncAttributeOptions}
          name='attr'
          label='Raw Attribute'
          autoSelectSingleOption={false}
          disabled={isEditing || (!clientId && !asyncAttributeOptions.loading)}
          onChange={handleAttrChange}
          getOptionLabel={getName}
          getOptionValue={getId}
          component={Select<IAttrOptions>}
        />

        <InputGroup
          name='attr.olioAttr'
          label='Mapped Olio Field'
          disabled
          value={attr?.olioAttr ? attr?.displayName : ''}
          component={Input}
        />

        <InputGroup
          name='attr.displayName'
          label='Display Attribute'
          value={props.values.attr?.displayName ?? ''}
          disabled
          component={Input}
        />
      </FormSection>

      <FormSection>
        <SectionHeader>Value Details</SectionHeader>

        <InputGroup name='name' label='Raw Value' disabled={isEditing} onBlur={handleNameBlur} component={Input} />

        <StyledCheckbox
          name='visible'
          disabled={!!attr?.olioAttr}
          label='Display on UI'
          checkedColor={colors.primaryBlue}
          labelSize='14px'
          size={20}
        />

        <InputGroup name='displayName' label='Display Value' component={Input} />

        {olioAttrObjectType && (
          <>
            <InputGroup
              options={[]}
              disabled
              name='matches'
              data-cy='matches'
              label={`Matched ${olioAttrObjectType}s`}
              placeholder={`Matched ${olioAttrObjectType}s`}
              autoSelectSingleOption={false}
              getOptionLabel={getName}
              getOptionValue={getId}
              isClearable
              component={MultiSelect}
              labelComponent={MultiSelectLabel}
              getSelectedCountLabel={(selectedCount: number): string => `${selectedCount} matched`}
            />

            <InputGroup
              {...asyncAssociatedObjectOptions}
              disabled={!clientId && !asyncAssociatedObjectOptions.loading}
              name={`associated${olioAttrObjectType}s`}
              data-cy={`associated${olioAttrObjectType}s`}
              label={`Additional Associated ${olioAttrObjectType}s (optional)`}
              placeholder={`Additional Associated ${olioAttrObjectType}s`}
              autoSelectSingleOption={false}
              getOptionLabel={getName}
              getOptionValue={getId}
              isClearable
              component={MultiSelect}
              labelComponent={MultiSelectLabel}
            />
          </>
        )}
      </FormSection>

      <ButtonGroup>
        <Button color='transparent' onClick={onCancel} text='Cancel' />
        <Button onClick={onSubmit} disabled={!dirty || !isValid || isSubmitting} text='Submit' />
      </ButtonGroup>
    </Form>
  );
}

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 20px;
`;
