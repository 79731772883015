import { createSelector, createSlice } from '@reduxjs/toolkit';

import { ALL_OPTION, HEALTH_SYSTEM, PHYSICIAN_GROUP, SEARCH } from '~/constants/filterKeysConstants';
import { rootPathnameChanged } from '~/ducks/navigation';

export const initialState = {
  [HEALTH_SYSTEM]: ALL_OPTION,
  [PHYSICIAN_GROUP]: ALL_OPTION,
  [SEARCH]: '',
};

const sliceName = 'admin/collaborations/filters';

const collaborationsFiltersSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    setFilter: (state, action) => {
      const { filterType, value } = action.payload;

      state[filterType] = value;
    },
    clearFilters: (state) => ({ ...initialState, [SEARCH]: state[SEARCH] }),
  },
  extraReducers: (builder) => {
    builder.addCase(rootPathnameChanged, () => initialState);
  },
});

export const getCollaborationsFilters = (state) => state[sliceName];

export const getCollaborationsFiltersForRequest = createSelector(getCollaborationsFilters, (collaborationsFilters) => {
  return Object.entries(collaborationsFilters)
    .filter(([filterKey, filter]) => {
      switch (filterKey) {
        case SEARCH:
          return filter;
        default:
          return filter.id;
      }
    })
    .reduce((acc, [filterKey, filter]) => {
      switch (filterKey) {
        case SEARCH:
          return { ...acc, [filterKey]: `${filter}` };
        default:
          return { ...acc, [filterKey]: `${filter.id}` };
      }
    }, {});
});

export const { clearFilters, setFilter } = collaborationsFiltersSlice.actions;

export default collaborationsFiltersSlice;
