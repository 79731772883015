import styled from 'styled-components';

import { Input, InputGroup } from '~/components/shared/form';
import TrashIcon from '~/components/shared/svg/TrashIcon';
import { QuestionTemplateConfig, QuestionTemplateQuestion } from '~/models/QuestionTemplate';
import { colors } from '~/styles/theme';

import RadioButtonGroup from './RadioButtonGroup';

export type Field = {
  name: string;
  value: Partial<QuestionTemplateQuestion>;
};

export type Form = {
  setFieldValue: (name: Field['name'], value: Field['value']) => void;
};

export interface QuestionInputProps {
  children?: React.ReactNode;
  configLabel?: string;
  configName?: keyof QuestionTemplateConfig;
  field: Field;
  inputLabel: string;
  placeholder?: string;
  handleConfigChange: (inputVal: string) => void;
  removeItem: () => void;
}

const QuestionInput = ({
  children,
  configLabel,
  configName,
  field: { name, value },
  inputLabel,
  placeholder = 'Type question here...',
  handleConfigChange,
  removeItem,
}: QuestionInputProps) => {
  const configValue = value.config?.[configName as keyof QuestionTemplateConfig];

  const radioValue = configValue === 'no' || !configValue ? 'no' : 'yes';

  return (
    <>
      <InlineContainer>
        <InputGroup label={inputLabel} name={`${name}.text`} placeholder={placeholder} component={Input} />
        {configLabel && <RadioButtonGroup label={configLabel} value={radioValue} onChange={handleConfigChange} />}
        <IconContainer>
          <StyledTrashIcon color={colors.accentRed} height={20} width={20} onClick={removeItem} />
        </IconContainer>
      </InlineContainer>
      {children}
    </>
  );
};

export default QuestionInput;

const IconContainer = styled.div`
  width: 16px;
  align-self: center;
`;

const InlineContainer = styled.div`
  display: flex;
  & > * {
    margin-left: 16px;
    &:first-child {
      margin-left: 0;
    }
  }
`;

const StyledTrashIcon = styled(TrashIcon)`
  cursor: pointer;
`;
