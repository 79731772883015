import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash-es';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router';

import { ConfirmationModal } from '~/components/shared/modal';
import { ControlledTable, CountMessage } from '~/components/shared/table';
import { ALL_OPTION } from '~/constants/filterKeysConstants';
import { AFFILIATE } from '~/constants/locationTypes';
import {
  clearGroups,
  deleteGroup,
  fetchGroups,
  getGroups,
  getGroupsLoaded,
  getGroupsPageCount,
  getGroupsTotalRecords,
} from '~/ducks/admin/groups';
import { FILTER_KEYS, getFilters, getSearch } from '~/ducks/admin/groupsFilters';
import { fetchLocationTypes } from '~/ducks/admin/locationTypes';
import { useModel, useThunk } from '~/lib/hooks';
import { Group, GroupType } from '~/models';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import colors from '~/styles/theme/colors';
import { BaseIndexParams } from '~/types';

import { Button } from '../shared/buttons';
import { MainPage } from '../shared/pageLayout';
import { PlusIcon } from '../shared/svg';

import GroupsFilterBar from './GroupsFilterBar';
import groupsTableColumns from './groupsTableColumns';

const mapDispatchToProps = {
  fetchGroups,
  clearGroups,
  deleteGroup,
};

const mapStateToProps = (state: any) => ({
  filters: getFilters(state),
  search: getSearch(state),
  groups: getGroups(state),
  loaded: getGroupsLoaded(state),
  pageCount: getGroupsPageCount(state),
  totalRecords: getGroupsTotalRecords(state),
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type GroupsProps = ConnectedProps<typeof connector>;

function Groups(props: GroupsProps) {
  const { deleteGroup, filters } = props;

  const navigate = useNavigate();
  const profileSvc = useProfileContext();
  const [pagingFilters, setPagingFilters] = useState<Partial<BaseIndexParams>>({});
  const [selectedGroupId, setSelectedGroupId] = useState<string | null>(null);
  const canAddGroups = profileSvc.has(PERMISSIONS.adminGroupCreate);
  const canDeleteGroup = profileSvc.has(PERMISSIONS.adminGroupDelete);

  const { data: locationTypes } = useThunk(fetchLocationTypes);

  const nonAffiliateLocationTypes = useMemo(
    () => locationTypes.filter((type) => type.kind !== AFFILIATE),
    [locationTypes]
  );
  const allTypes = useMemo(() => nonAffiliateLocationTypes.map((type) => type.kind), [nonAffiliateLocationTypes]);
  const filtersForRequest = useMemo(() => {
    return Object.entries(filters).reduce((acc, [key, value]: [string, any]) => {
      switch (key) {
        case FILTER_KEYS.CLIENT:
          if (value === ALL_OPTION) {
            return acc;
          } else {
            return { ...acc, [key]: value.id };
          }
        case FILTER_KEYS.LOCATION_TYPE:
          if (value === ALL_OPTION) {
            return { ...acc, [key]: allTypes };
          } else {
            return { ...acc, [key]: value.kind };
          }
        default:
          return { ...acc, [key]: value };
      }
    }, {});
  }, [filters]);

  const groups = useModel(Group, props.groups);

  const debouncedfetchGroups = useCallback(
    _.debounce((params) => props.fetchGroups(params), 50),
    []
  );

  useEffect(() => {
    props.clearGroups();

    debouncedfetchGroups({
      ...pagingFilters,
      ...filtersForRequest,
      include: 'active_users_count,group_type',
    });
  }, [pagingFilters, filtersForRequest]);

  const handlePagingFiltersChange = useCallback((newPagingFilters: any) => {
    setPagingFilters(newPagingFilters);
  }, []);

  const handleAddGroupClick = () => {
    navigate('/groups/new');
  };

  const handleEdit = useCallback((group: GroupType) => navigate(`/groups/${group.id}/edit`), []);

  const handleDelete = useCallback((group: GroupType) => {
    if (!canDeleteGroup) return;

    setSelectedGroupId(group.id);
  }, []);

  const handleDeleteConfirm = useCallback(() => {
    deleteGroup(selectedGroupId!);

    setSelectedGroupId(null);
  }, [selectedGroupId]);

  const handleDeleteCancel = useCallback(() => {
    setSelectedGroupId(null);
  }, []);

  const columns = useMemo(
    () =>
      groupsTableColumns({
        profileSvc,
        onDelete: handleDelete,
        onEdit: handleEdit,
      }),
    []
  );

  const count = <CountMessage count={props.totalRecords} countType='result' />;

  const addGroupButton = (
    <Button onClick={handleAddGroupClick} icon={<PlusIcon size={14} fill={colors.white} />} text='Add Group' />
  );

  return (
    <MainPage title='Groups' rightContent={canAddGroups && addGroupButton} middleContent={count}>
      <GroupsFilterBar locationTypes={nonAffiliateLocationTypes} />
      <ControlledTable
        columns={columns}
        data={groups}
        defaultSortBy={'name asc'}
        filters={filters}
        loading={!props.loaded}
        onPagingFiltersChange={handlePagingFiltersChange}
        pageCount={props.pageCount}
      />
      <ConfirmationModal
        show={Boolean(selectedGroupId)}
        actionText='archive'
        objType='group'
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
      />
    </MainPage>
  );
}

export default connector(Groups);
