import React, { useMemo } from 'react';
import { components } from 'react-select';
import styled from 'styled-components';

// Define the types for the props
interface CheckboxMultiSelectPlaceholderProps {
  children: React.ReactNode;
  getValue: () => any[];
  // You might want to define more props if they exist, according to your usage needs
}

function CheckboxMultiSelectPlaceholder({ children, ...props }: CheckboxMultiSelectPlaceholderProps) {
  const selectedValues = props.getValue();
  const valueText = useMemo(() => {
    if (selectedValues.length) {
      return `${selectedValues.length} selected`;
    }

    return children;
  }, [children, selectedValues]);

  return <StyledPlaceholder {...props}>{valueText}</StyledPlaceholder>;
}

export default CheckboxMultiSelectPlaceholder;

const StyledPlaceholder = styled(components.Placeholder as any)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 85%;
`;
