import React from 'react';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { StandardModal } from '~/components/shared/modal';
import { Body } from '~/components/shared/typography';

type SaveChangesModalProps = {
  onCancel: () => void;
  onConfirm: () => void;
  show: boolean;
};

const noop = () => undefined;

function SaveChangesModal({ show = false, onCancel = noop, onConfirm = noop }: SaveChangesModalProps) {
  const handleOnCancel = () => {
    onCancel();
  };

  const handleOnConfirm = () => {
    onConfirm();
  };

  return (
    <StandardModal show={show} title={'Save Changes?'} onCancel={handleOnCancel}>
      <Content>
        <StyledBody>Are you sure you wish to continue without saving? Your changes will be lost.</StyledBody>
      </Content>
      <StyledButtonGroup>
        <Button color='transparent' onClick={handleOnCancel} text='Go Back' />
        <Button onClick={handleOnConfirm} text='Continue Without Saving' />
      </StyledButtonGroup>
    </StandardModal>
  );
}

export default SaveChangesModal;

const Content = styled.div`
  margin: 26px 0 50px;
  text-align: center;
`;

const StyledBody = styled(Body)`
  display: block;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  justify-content: flex-end;
`;
