import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash-es';
import { connect, ConnectedProps } from 'react-redux';

import { ControlledTable } from '~/components/shared/table';
import {
  clearCollaborations,
  fetchCollaborations,
  getCollaborations,
  getCollaborationsLoaded,
  getCollaborationsPageCount,
} from '~/ducks/admin/collaborations';
import {
  clearFilters,
  getCollaborationsFilters,
  getCollaborationsFiltersForRequest,
  setFilter,
} from '~/ducks/admin/collaborationsFilters';
import { useModel } from '~/lib/hooks';
import { Client, Collaboration } from '~/models';
import { useProfileContext } from '~/services/profile';

import collaborationsTableColumns from './collaborationsTableColumns';

const mapStateToProps = (state: any) => ({
  collaborations: getCollaborations(state),
  filters: getCollaborationsFilters(state),
  filtersForRequest: getCollaborationsFiltersForRequest(state),
  pageCount: getCollaborationsPageCount(state),
  loaded: getCollaborationsLoaded(state),
});

const mapDispatchToProps = {
  clearCollaborations,
  clearFilters,
  fetchCollaborations,
  setFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type CollaborationsTableProps = ConnectedProps<typeof connector> & {
  onCollaborationEdit: (collaboration: Collaboration) => void;
};

function CollaborationsTable({
  filters,
  filtersForRequest,
  pageCount,
  loaded,
  onCollaborationEdit,
  ...props
}: CollaborationsTableProps) {
  const profileSvc = useProfileContext();
  const [pagingFilters, setPagingFilters] = useState<object>({});
  const debouncedFetchCollaborations = useCallback(
    _.debounce((params) => props.fetchCollaborations(params), 50),
    []
  );
  const collaborations = useModel(Collaboration, props.collaborations);

  useEffect(() => {
    props.clearCollaborations();
    debouncedFetchCollaborations({ ...pagingFilters, ...filtersForRequest });
  }, [pagingFilters, filtersForRequest]);

  const handlePagingFiltersChange = useCallback((newPagingFilters: object) => {
    setPagingFilters(newPagingFilters);
  }, []);

  const columns = useMemo(() => collaborationsTableColumns({ profileSvc, onEdit: onCollaborationEdit }), []);

  return (
    <ControlledTable
      data={collaborations}
      defaultSortBy={'name asc'}
      loading={!loaded}
      columns={columns}
      filters={filters}
      onPagingFiltersChange={handlePagingFiltersChange}
      pageCount={pageCount}
    />
  );
}

export default connector(CollaborationsTable);
