import React from 'react';

import { StandardModal } from '~/components/shared/modal';

import ClassificationForm from './ClassificationForm';

interface ClassificationModalProps {
  classificationId?: string | null;
  isOpen: boolean;
  onCancel: () => void;
  title: string;
  type: string;
}

function ClassificationModal({ isOpen, onCancel, classificationId, type, title }: ClassificationModalProps) {
  return (
    <StandardModal
      show={isOpen}
      title={`${classificationId ? 'Edit' : 'New'} ${title}`}
      onCancel={onCancel}
      disableBackdropClick>
      <ClassificationForm onComplete={onCancel} title={title} type={type} classificationId={classificationId} />
    </StandardModal>
  );
}

export default ClassificationModal;
