import React from 'react';
import styled from 'styled-components';

import { Button } from '~/components/shared/buttons';
import { ConfirmationModal } from '~/components/shared/modal';
import { CircleCancelIcon, RestartIcon, ResumeIcon } from '~/components/shared/svg';
import { ImportAction } from '~/constants/imports';
import { useSwitch } from '~/lib/hooks';
import { Import } from '~/models/imports';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import colors from '~/styles/theme/colors';

interface ImportActionsProps {
  importObj: Import;
  onImportAction(action: ImportAction): void;
}

function ImportActions({ importObj, onImportAction }: ImportActionsProps) {
  const profileSvc = useProfileContext();
  const canEditImport = profileSvc.has(PERMISSIONS.adminImportedPatientEdit);
  const confirmationModalSwitch = useSwitch(false);

  if (!canEditImport) return null;

  return (
    <>
      <ButtonContainer>
        {importObj.cancellable && (
          <Button
            onClick={confirmationModalSwitch.turnOn}
            data-cy='cancelImportButton'
            text='Cancel Import'
            icon={<CircleCancelIcon size={20} fill={colors.accentRed} />}
            color='accentWhite'
          />
        )}

        {importObj.runnable && (
          <Button
            onClick={() => onImportAction(ImportAction.RUN)}
            data-cy='resumeImportButton'
            text={'Resume Import'}
            icon={<ResumeIcon size={14} fill={colors.white} />}
          />
        )}

        {importObj.restartable && (
          <Button
            onClick={() => onImportAction(ImportAction.RESTART)}
            data-cy='restartImportButton'
            text={'Restart Import'}
            icon={<RestartIcon size={14} fill={colors.white} />}
          />
        )}
      </ButtonContainer>
      <ConfirmationModal
        show={confirmationModalSwitch.state}
        actionText='cancel'
        objType='import'
        onConfirm={() => onImportAction(ImportAction.CANCEL)}
        onCancel={confirmationModalSwitch.turnOff}
      />
    </>
  );
}

export default ImportActions;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  & > * {
    margin-left: 10px;
  }
`;
