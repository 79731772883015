import React, { useEffect } from 'react';
import { FormikProps, useFormikContext } from 'formik';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { Checkbox, Form, FormHeader, FormSection, Input, InputGroup, Select } from '~/components/shared/form';
import { GROUP_TYPE_TYPES, MANAGER } from '~/constants/groupTypes';
import colors from '~/styles/theme/colors';

export type GroupTypeFormValues = {
  displayName: string;
  name: string;
  isInpatient: boolean;
  type: { label: string; value: string };
};

export type GroupTypeFormProps = {
  isEditing: boolean;
  onCancel: () => void;
};

type Props = GroupTypeFormProps & FormikProps<GroupTypeFormValues>;

export default function GroupTypeForm({ onCancel, isEditing, values, setFieldValue }: Props) {
  const { handleSubmit, isValid, dirty, isSubmitting } = useFormikContext();

  const onSubmit = () => handleSubmit();

  useEffect(() => {
    if (values.type?.value == MANAGER) {
      setFieldValue('isInpatient', false);
    }
  }, [values.type?.value]);

  return (
    <Form>
      <FormHeader title={isEditing ? 'Edit Group Type' : 'Add Group Type'} />
      <FormSection>
        <InputGroup
          name='type'
          label='Category'
          placeholder='Category'
          options={GROUP_TYPE_TYPES}
          disabled={isEditing}
          component={Select}
        />

        <InputGroup name='name' label='Standard Name' component={Input} />

        <InputGroup name='displayName' label='Display Name' component={Input} />

        {values.type?.value != MANAGER && (
          <StyledCheckbox
            name='isInpatient'
            label='Inpatient'
            checkedColor={colors.primaryBlue}
            labelSize='14px'
            size={20}
            disabled={isEditing}
          />
        )}
      </FormSection>

      <ButtonGroup>
        <Button color='transparent' onClick={onCancel} text='Cancel' />
        <Button
          onClick={onSubmit}
          disabled={!dirty || !isValid || isSubmitting}
          text={isEditing ? 'Update Group Type' : 'Create Group Type'}
        />
      </ButtonGroup>
    </Form>
  );
}

const StyledCheckbox = styled(Checkbox)`
  margin-bottom: 20px;
`;
