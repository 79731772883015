import React, { useMemo, useState } from 'react';
import { FormikHelpers, withFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import NotificationPreferencesForm from '~/components/preferences/notifications/NotificationPreferencesForm';
import NotificationPreferencesPage, {
  Props as NotificationPreferencesPageProps,
  SelectedPreferenceTab,
} from '~/components/preferences/notifications/NotificationPreferencesPage';
import { fetchNotificationPreferencesById, fetchUser } from '~/ducks/admin/users';
import {
  createNotificationPreference,
  fetchNotificationPreferences,
  updateNotificationPreference,
} from '~/ducks/notificationPreferences';
import { addToast } from '~/ducks/toasts';
import { isDeepEqual, unwrapResult } from '~/lib';
import { useThunk } from '~/lib/hooks';
import { Notification } from '~/models/userPreferences';
import { useProfileContext } from '~/services/profile';
import { store } from '~/store';

export type NotificationPreferencesFormValues = {
  // key will always be a group type api name
  [key: string]: Notification;
};

export default function NotificationPreferencesFormWrapper() {
  const profileSvc = useProfileContext();
  const { id: paramsId } = useParams();
  const id = paramsId || profileSvc.profile.id;
  const [selectedTab, setSelectedTab] = useState<string>('');

  const dispatch = useDispatch<typeof store.dispatch>();
  const isAdminView = !!paramsId;

  const { data: fetchedUser } = useThunk(fetchUser, [id], {
    condition: isAdminView,
    params: {
      id,
      include: 'actingClientId,enabledProviderTypes,flags,role,acting_client.client_group_types',
    },
  });

  const { data: notificationPreferences, loaded: notificationPreferencesLoaded } = useThunk(
    isAdminView ? fetchNotificationPreferencesById : fetchNotificationPreferences,
    [id],
    {
      condition: !!id,
      params: {
        id,
        include: 'groupType,scopes',
      },
    }
  );

  const updatePreference = (value: Notification) => {
    return updateNotificationPreference({ ...value.serialize(), include: 'groupType,scopes' });
  };

  const createPreference = (value: Notification) => {
    return createNotificationPreference({
      ...new Notification({ ...value, clientId: profileSvc.actingClient.id }).serialize(),
      include: 'groupType,scopes',
    });
  };

  const handleSubmit = async (
    values: NotificationPreferencesFormValues,
    helpers: FormikHelpers<NotificationPreferencesFormValues>
  ) => {
    try {
      const requests = Object.entries(values).map(async ([key, value]) => {
        if (isDeepEqual(value, initialFormValues[key])) {
          return;
        }

        const requestFn = value.id ? updatePreference : createPreference;
        const res = await dispatch(requestFn(value)).then(unwrapResult);

        return { [key]: new Notification(res) };
      });

      const allResults = (await Promise.all(requests)).filter(Boolean);
      const newFormValues = allResults.reduce((acc, result) => ({ ...acc, ...result }), {}) || {};
      const newStatusValues = Object.keys(newFormValues).reduce((acc, key) => {
        return {
          ...acc,
          [key]: !!newFormValues[key].active,
        };
      }, {});

      helpers.resetForm({
        values: { ...initialFormValues, ...newFormValues },
        status: { disabled: isAdminView, activeStatus: { ...initialActiveStatus, ...newStatusValues } },
      });

      dispatch(addToast({ text: 'Your changes have been saved!' }));
    } catch (error) {
      dispatch(addToast({ text: 'There was an error saving your notification preferences. Please try again.' }));
    }
  };

  /**
   * Builds up an object where the keys are the groupTypeApiName's
   * and the values are the UserPreference::Notification objects.
   *
   * Example:
   * {
   *  'skilled_nursing_facility': { active: true, notification_selections: { ... } },
   *  'home_health_agency': { active: true, notification_selections: { ... } },
   * }
   */
  const initialFormValues = useMemo(() => {
    return (notificationPreferences as Notification[]).reduce(
      (acc: NotificationPreferencesFormValues, pref: Notification) => {
        return {
          ...acc,
          [pref.groupTypeApiName]: pref,
        };
      },
      {}
    );
  }, [notificationPreferences]);

  /**
   * Builds up an object where the keys are the groupTypeApiName's
   * and the values are the "active" status of the notification preferences
   * for that group type. These statuses are used to help disable inputs.
   *
   * Example:
   * {
   *  'skilled_nursing_facility': true,
   *  'home_health_agency': false,
   * }
   */
  const initialActiveStatus = useMemo(() => {
    return Object.keys(initialFormValues).reduce((acc, key) => {
      return {
        ...acc,
        [key]: !!initialFormValues[key].active,
      };
    }, {});
  }, [initialFormValues]);

  const formikOptions = useMemo(
    () => ({
      handleSubmit,
      mapPropsToStatus: () => ({ disabled: isAdminView, activeStatus: initialActiveStatus }),
      mapPropsToValues: () => initialFormValues,
    }),
    [initialFormValues, isAdminView]
  );

  const FormikNotificationPreferences = useMemo(
    () =>
      withFormik<NotificationPreferencesPageProps, NotificationPreferencesFormValues>(formikOptions)(
        NotificationPreferencesPage
      ),
    [formikOptions]
  );

  const user = isAdminView ? fetchedUser : profileSvc.profile;
  const pageSubtitle = user.actingClient && user.role ? `${user.actingClient.name} - ${user.role.name}` : '';
  const subtitle = isAdminView ? pageSubtitle : user.actingClient.name;
  const title = isAdminView ? user.name : 'Notification Preferences';

  const configForSelectedGroupType = user?.actingClient?.configForGroupType(selectedTab || '');

  const caseManagerEnabledForSelectedGroupType = !!configForSelectedGroupType?.caseManager;
  const utilizationManagerEnabledForSelectedGroupType = !!configForSelectedGroupType?.utilizationManager;

  return (
    <SelectedPreferenceTab.Provider value={selectedTab}>
      <FormikNotificationPreferences
        onTabChanged={setSelectedTab}
        onTabClick={setSelectedTab}
        subtitle={subtitle}
        title={title}
        user={user}>
        {notificationPreferencesLoaded && (
          <NotificationPreferencesForm
            actingClientId={user.actingClientId}
            caseManagerEnabled={caseManagerEnabledForSelectedGroupType}
            utilizationManagerEnabled={utilizationManagerEnabledForSelectedGroupType}
            providerTypes={user.enabledProviderTypes}
          />
        )}
      </FormikNotificationPreferences>
    </SelectedPreferenceTab.Provider>
  );
}
