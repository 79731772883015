import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash-es';
import { connect, ConnectedProps } from 'react-redux';

import { ControlledTable } from '~/components/shared/table';
import { SEARCH } from '~/constants/filterKeysConstants';
import {
  clearImportConfigurations,
  fetchImportConfigurations,
  getImportConfigurations,
  getImportConfigurationsLoaded,
  getImportConfigurationsPageCount,
} from '~/ducks/admin/importConfigurations';
import {
  clearFilters,
  getImportConfigurationsFilters,
  getImportConfigurationsFiltersForRequest,
  setFilter,
} from '~/ducks/admin/importConfigurationsFilters';
import { useModel } from '~/lib/hooks';
import { ImportConfiguration } from '~/models';
import { useProfileContext } from '~/services/profile';

import importConfigurationsTableColumns from './importConfigurationsTableColumns';

const mapStateToProps = (state: any) => ({
  importConfigurations: getImportConfigurations(state),
  filters: getImportConfigurationsFilters(state),
  filtersForRequest: getImportConfigurationsFiltersForRequest(state),
  pageCount: getImportConfigurationsPageCount(state),
  loaded: getImportConfigurationsLoaded(state),
});

const mapDispatchToProps = {
  clearImportConfigurations,
  clearFilters,
  fetchImportConfigurations,
  setFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ImportConfigurationsTableProps = ConnectedProps<typeof connector> & {
  handleEdit: (configuration: ImportConfiguration) => void;
};

function ImportConfigurationsTable({
  filters,
  filtersForRequest,
  handleEdit,
  loaded,
  pageCount,
  ...props
}: ImportConfigurationsTableProps) {
  const profileSvc = useProfileContext();
  const [pagingFilters, setPagingFilters] = useState<Record<string, any>>({});
  const [tableProps, setTableProps] = useState<{ setSorting: (sort: any) => void }>({ setSorting: () => undefined });
  const debouncedFetchImportConfigurations = useCallback(
    _.debounce((params) => props.fetchImportConfigurations(params), 50),
    []
  );
  const importConfigurations = useModel(ImportConfiguration, props.importConfigurations);

  useEffect(() => {
    debouncedFetchImportConfigurations({
      ...pagingFilters,
      ...filtersForRequest,
      include: 'client',
    });
  }, [pagingFilters, filtersForRequest]);

  const handlePagingFiltersChange = useCallback((newPagingFilters: object) => {
    setPagingFilters(newPagingFilters);
  }, []);

  const columns = useMemo(
    () =>
      importConfigurationsTableColumns({
        onEdit: handleEdit,
        profileSvc,
      }),
    []
  );

  const defaultSortBy = [{ id: 'clientName', desc: false }];
  const searchValue = filters[SEARCH];

  useEffect(() => {
    const sortBy = searchValue ? [{ id: SEARCH, desc: true }] : defaultSortBy;

    tableProps.setSorting(sortBy);
  }, [searchValue]);

  return (
    <ControlledTable
      data={importConfigurations}
      defaultSortBy={'clientName asc'}
      loading={!loaded}
      columns={columns}
      filters={filters}
      onInitialize={setTableProps}
      onPagingFiltersChange={handlePagingFiltersChange}
      pageCount={pageCount}
    />
  );
}

export default connector(ImportConfigurationsTable);
