import React, { useMemo } from 'react';
import styled from 'styled-components';

import TabFilters from '~/components/shared/TabFilters';
import { Client } from '~/models';

interface CareOptionsTabsProps {
  client: Client;
  onTabChanged: (selectedValue: string) => void;
  onTabClick: (selectedValue: string) => void;
  selectedTabValue: string | null;
}

function CareOptionsTabs({ client, onTabChanged, onTabClick, selectedTabValue }: CareOptionsTabsProps) {
  const tabs = useMemo(() => {
    return client.clientGroupTypes.map((type) => ({
      label: type.groupType.displayName,
      value: type.id,
    }));
  }, [client.clientGroupTypes]);

  const selectedTab = useMemo(() => {
    return tabs.find((tab) => tab.value === selectedTabValue) || tabs[0];
  }, [tabs, selectedTabValue]);

  const visible = !!tabs.length;

  return (
    <>
      <TabFiltersContainer>
        {visible && (
          <TabFilters
            tabs={tabs}
            data-cy='careOptionsTabs'
            onTabChanged={onTabChanged}
            onTabClick={onTabClick}
            selectedTab={selectedTab}
          />
        )}
      </TabFiltersContainer>
    </>
  );
}

export default CareOptionsTabs;

const TabFiltersContainer = styled.div`
  margin-top: 24px;
`;
