import React from 'react';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { ProgressTemplate } from '~/models';
import { PERMISSIONS, ProfileService } from '~/services/profile';

import EditFlyoutMenu, { EditFlyoutMenuProps } from '../../shared/FlyoutMenu/EditFlyoutMenu';

const columnHelper = createColumnHelper<ProgressTemplate>();

type ColumnFnProps = {
  profileSvc: ProfileService;
} & Omit<EditFlyoutMenuProps<ProgressTemplate>, 'row'>;

export default function progressTemplatesTableColumns({ profileSvc, onEdit }: ColumnFnProps) {
  const columns: ColumnDef<ProgressTemplate, any>[] = [
    columnHelper.accessor((row) => row.client, {
      id: 'clientName',
      header: 'Client',
    }),
    columnHelper.accessor((row) => row.groupTypes, {
      id: 'groupTypes',
      header: 'Provider Types',
      enableSorting: false,
      cell: (info) => info.getValue() || 'All',
    }),
    columnHelper.accessor((row) => row.episodeClassifications, {
      id: 'episodeClassifications',
      header: 'Episode Type',
      enableSorting: false,
      cell: (info) => info.getValue() || '—',
    }),
    columnHelper.accessor((row) => row.planTypeClassifications, {
      id: 'planTypeClassifications',
      header: 'Plan Type',
      enableSorting: false,
      cell: (info) => info.getValue() || '—',
    }),
    columnHelper.accessor((row) => row.trackLos, {
      id: 'trackLos',
      header: 'Track Days?',
      cell: (info) => (info.getValue() ? 'Yes' : 'No'),
    }),
    columnHelper.accessor((row) => row.questionCount, {
      id: 'questions',
      header: 'Questions',
    }),
  ];

  if (profileSvc.has(PERMISSIONS.adminQuestionTemplateEdit)) {
    columns.push(
      columnHelper.display({
        id: 'menu',
        cell: (info) => (
          <EditFlyoutMenu row={info.row} onEdit={onEdit} permission={PERMISSIONS.adminQuestionTemplateEdit} />
        ),
        size: 75,
        meta: { style: { justifyContent: 'flex-end' } },
      })
    );
  }

  return columns;
}
