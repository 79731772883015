import React, { useCallback } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { Form, Input, InputGroup } from '~/components/shared/form';
import { createClassification, fetchClassification, updateClassification } from '~/ducks/admin/classifications';
import { addToast } from '~/ducks/toasts';
import { unwrapResult } from '~/lib';
import { useThunk } from '~/lib/hooks';

import { CollaborationFormData } from '../clients/collaborations/CollaborationForm';

import { classificationValidation } from './classificationValidation';

const mapDispatchToProps = {
  addToast,
  createClassification,
  updateClassification,
};

const connector = connect(null, mapDispatchToProps);

type ClassificationFormProps = ConnectedProps<typeof connector> & {
  classificationId: string;
  title: string;
  type: string;
  onComplete: () => void;
};

function ClassificationForm(props: ClassificationFormProps) {
  const { classificationId, onComplete, title, type } = props;
  const { data: classification } = useThunk(fetchClassification, [], {
    condition: !!classificationId,
    params: classificationId,
  });
  const errorMsg = `${title} with that name already exists. ${title} must be unique and not match any existing name.`;

  const handleSubmit = useCallback(
    (values: CollaborationFormData, { setErrors, setSubmitting }: FormikHelpers<CollaborationFormData>) => {
      if (!values.type) values.type = type;

      const saveFunc = values.id ? props.updateClassification : props.createClassification;
      const serializedClassification = values.serialize();

      saveFunc(serializedClassification)
        .then(unwrapResult)
        .then(() => {
          props.addToast({ text: `Classification successfully ${values.id ? 'updated' : 'created'}` });
        })
        .then(() => {
          setSubmitting(false);
          onComplete();
        })
        .catch((e) => {
          if (e?.response?.data?.errors?.name) {
            setErrors({ name: errorMsg });
          } else {
            props.addToast({ text: `There was an error ${values.id ? 'updating' : 'creating'} the classification` });
          }
          setSubmitting(false);
        });
    },
    [classificationId, onComplete, title, type, props]
  );

  return (
    <FormContainer>
      <Formik
        initialValues={classification}
        validationSchema={() => classificationValidation(title)}
        onSubmit={handleSubmit}
        enableReinitialize>
        {({ isSubmitting, isValid }) => (
          <Form>
            <InputGroup name='name' label={title} placeholder={title} component={Input} />
            <Actions>
              <Button color='transparent' onClick={onComplete} text='Cancel' />
              <Button type='submit' disabled={!isValid || isSubmitting} loading={isSubmitting} text='Submit' />
            </Actions>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
}

const Actions = styled(ButtonGroup)`
  justify-content: flex-end;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
`;

export default connector(ClassificationForm);
