import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import styled from 'styled-components';

import { getToasts, removeToast } from '~/ducks/toasts';

import Toast from './toast';

const ListWrapper = styled.ul`
  position: absolute;
  z-index: 999;
  top: 12px;
  left: 0;
  right: 0;
  margin: auto;
  list-style: none;
  width: 60%;
`;

const mapDispatchToProps = { removeToast };

const mapStateToProps = (state: any) => ({ toasts: getToasts(state) });

const connector = connect(mapStateToProps, mapDispatchToProps);

type ToastsProps = ConnectedProps<typeof connector>;

export function Toasts(props: ToastsProps) {
  return (
    <ListWrapper className='toasts' data-cy='toasts'>
      {props.toasts.map((toast: any) => {
        const { id } = toast;

        return <Toast {...toast} key={id} onClick={props.removeToast} />;
      })}
    </ListWrapper>
  );
}

export default connector(Toasts);
