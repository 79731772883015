import React from 'react';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import {
  Form,
  FormHeader,
  FormSection,
  InputGroup,
  MultiSelect,
  MultiSelectLabel,
  SectionHeader,
} from '~/components/shared/form';
import { ADMIN } from '~/constants/clientTypes';
import { fetchClientsForGroups } from '~/ducks/admin/clients';
import { getId, getName } from '~/helpers';
import { useAsyncOptions } from '~/lib/hooks';
import Client from '~/models/Client';

import ContactInfoSection from './ContactInfoSection';

interface GroupFormProps {
  isSubmitting: boolean;
  isValid: boolean;
  onCancel: () => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  submitForm: () => void;
  dirty: boolean;
  status: {
    isEdit: boolean;
  };
  values: {
    client: Client;
  };
}

function GroupForm(props: GroupFormProps) {
  const {
    isSubmitting,
    isValid,
    onCancel,
    setFieldValue,
    submitForm,
    dirty,
    status: { isEdit },
    values,
  } = props;

  const associatedClientsAsyncOptions = useAsyncOptions(fetchClientsForGroups, {
    condition: !!values.client,
    params: { leaf: true, 'clientType.not': ADMIN, 'id.not': values.client?.id },
  });

  const submitButtonDisabled = !dirty || !isValid || isSubmitting;

  const handleSaveAndAddAnother = () => {
    setFieldValue('shouldNavigate', false, false);
    submitForm();
  };

  const handleCreateGroup = () => {
    setFieldValue('shouldNavigate', true, false);
    submitForm();
  };

  return (
    <Form>
      <FormHeader title={isEdit ? 'Edit Group' : 'Add Group'} />
      <ContactInfoSection />
      <FormSection>
        <SectionHeader>Associated Clients</SectionHeader>
        <InputGroup
          {...associatedClientsAsyncOptions}
          getOptionLabel={getName}
          getOptionValue={getId}
          component={MultiSelect}
          labelComponent={MultiSelectLabel}
          name='networkClients'
          data-cy='networkClients'
          label='Associated Clients'
          placeholder='Associated Clients'
          closeMenuOnSelect={false}
        />
      </FormSection>

      <StyledButtonGroup isEdit={isEdit}>
        {!isEdit && (
          <Button
            color='primaryWhite'
            data-cy='saveAndAddButton'
            onClick={handleSaveAndAddAnother}
            disabled={submitButtonDisabled}
            text='Save and Add Another'
          />
        )}
        <ButtonGroup>
          <Button color='transparent' text='Cancel' onClick={onCancel} />
          <Button
            type='submit'
            onClick={handleCreateGroup}
            disabled={submitButtonDisabled}
            data-cy='updateGroup'
            text={isEdit ? 'Update Group' : 'Create Group'}
          />
        </ButtonGroup>
      </StyledButtonGroup>
    </Form>
  );
}

const StyledButtonGroup = styled.div<{ isEdit: boolean }>`
  display: flex;
  justify-content: ${({ isEdit }) => (isEdit ? 'end' : 'space-between')};
  width: 100%;
`;

export default GroupForm;
