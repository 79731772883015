import React from 'react';

import { InlineInputGroups, Input, InputGroup } from '~/components/shared/form';

interface ChartFieldsProps {
  name: string;
}

function ChartFields({ name }: ChartFieldsProps) {
  return (
    <InlineInputGroups>
      <InputGroup
        label='Chart Name'
        name={`${name}.config.chart.chartName`}
        placeholder='Type here..'
        component={Input}
      />
      <InputGroup
        label='Measurement Value (y-axis label)'
        name={`${name}.config.chart.yAxis`}
        placeholder='Type here...'
        component={Input}
      />
    </InlineInputGroups>
  );
}

export default ChartFields;
