import React, { Fragment, useMemo } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { unwrapResult } from '@reduxjs/toolkit';

import { MainPage } from '~/components/shared/pageLayout';
import { ImportAction } from '~/constants/imports';
import { fetchImport, updateImport } from '~/ducks/admin/imports';
import { useThunk } from '~/lib/hooks';
import { ImportStep } from '~/models/imports';

import ImportActions from './history/ImportActions';
import StatusDetailsCard from './StatusDetailsCard';
import StatusDetailsHeader from './StatusDetailsHeader';

const mapDispatchToProps = {
  fetchImport,
  updateImport,
};

const connector = connect(null, mapDispatchToProps);

type StatusDetailsProps = ConnectedProps<typeof connector>;

function StatusDetails(props: StatusDetailsProps) {
  const params = useParams();
  const importId = params.id;
  const { data: importObj, setData: setImportObj } = useThunk(fetchImport, [importId], {
    condition: Boolean(importId),
    params: {
      id: importId,
      include: 'child,client,steps',
    },
  });

  const navigate = useNavigate();

  const goToImports = () => navigate('/import-history');

  const handleImportAction = async (action: ImportAction) => {
    const handleImportAction = {
      id: importObj.id,
      invokeAction: action,
      include: 'child,client,steps',
    };

    const navigatableActions = [ImportAction.CANCEL, ImportAction.RESTART];

    await props
      .updateImport(handleImportAction)
      .then(unwrapResult)
      .then(setImportObj)
      .then((navigatableActions.includes(action) || undefined) && goToImports);
  };

  const steps = importObj.steps;
  const sortedSteps = useMemo(() => {
    return importObj.steps.sort((a: ImportStep, b: ImportStep) => a.order - b.order);
  }, [steps]);

  return (
    <MainPage
      title='Import Status Details'
      headerWhite
      rightContent={<ImportActions importObj={importObj} onImportAction={handleImportAction} />}
      headerContent={
        <Fragment>
          <StatusDetailsHeader importObj={importObj} />
        </Fragment>
      }>
      {sortedSteps.map((step: ImportStep, i: number) => (
        <StatusDetailsCard key={i} step={step} />
      ))}
    </MainPage>
  );
}

export default connector(StatusDetails);
