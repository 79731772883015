import React from 'react';

import theme from '~/styles/theme';

interface DeactivateIconProps {
  fill?: string;
  secondaryFill?: string;
  size?: number;
}

function DeactivateIcon({ fill = theme.colors.accentRed, size = 12 }: DeactivateIconProps) {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <title>icon / deactivate</title>
      <g id='Iconography' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g id='icons' transform='translate(-391.000000, -167.000000)' fill={fill}>
          <g id='icon-/-deactivate' transform='translate(391.000000, 167.000000)'>
            <path
              d='M12,0 C5.372583,0 0,5.372583 0,12 C0,18.627417 5.372583,24 12,24 C18.627417,24 24,18.627417 24,12 C23.9807773,5.38056166 18.6194383,0.0192227178 12,0 Z M18,13 L6,13 L6,11 L18,11 L18,13 Z'
              id='Fill'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export default DeactivateIcon;
