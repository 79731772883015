import React, { useMemo } from 'react';
import { Formik, FormikHelpers } from 'formik';
import styled from 'styled-components';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { Form, FormHeader, InputGroup, MultiSelect, MultiSelectLabel } from '~/components/shared/form';
import { Client, Collaboration } from '~/models';

export type CollaborationFormData = Collaboration & {
  collaborators: any[];
  type?: string;
};

interface CollaborationFormProps {
  clients: Client[];
  collaboration: Collaboration;
  onCancel: () => void;
  onSubmit: (values: CollaborationFormData, formikHelpers: FormikHelpers<CollaborationFormData>) => void;
}

function CollaborationForm(props: CollaborationFormProps) {
  const { clients, collaboration, onCancel, onSubmit } = props;

  const byName = ({ name }: { name: string }) => name;
  const byId = ({ id }: { id: string }) => id;

  const initialValues = useMemo(() => collaboration.toFormValues(clients), [collaboration, clients]);

  return (
    <FormContainer>
      <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
        {({ isSubmitting }) => (
          <Form>
            <FormHeader subtitle={`Primary Owner: ${collaboration.name}`} />
            <InputGroup
              name='collaborators'
              label='Collaborating Clients'
              data-cy='collaboratingClients'
              options={clients}
              getOptionLabel={byName}
              getOptionValue={byId}
              component={MultiSelect<Client>}
              labelComponent={MultiSelectLabel}
              menuPortalTarget={document.body}
              styles={{ menuPortal: { zIndex: 9999 } }}
            />
            <Actions>
              <Button color='transparent' text='Cancel' onClick={onCancel} />
              <Button type='submit' disabled={isSubmitting} text='Save Collaborations' />
            </Actions>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
}

export default CollaborationForm;

const Actions = styled(ButtonGroup)`
  margin-top: 16px;
  justify-content: flex-end;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
