import React, { useCallback } from 'react';
import styled from 'styled-components';

import CheckboxOffIcon from '~/components/shared/svg/CheckboxOffIcon';
import CheckboxOnIcon from '~/components/shared/svg/CheckboxOnIcon';
import { Label } from '~/components/shared/typography';
import useField from '~/lib/hooks/useField';
import colors from '~/styles/theme/colors';

interface CheckboxProps {
  checked?: boolean;
  checkedColor?: string;
  color?: string;
  disabled?: boolean;
  id?: string;
  label?: string;
  labelIcon?: React.ReactNode;
  labelMargin?: string;
  labelSize?: string;
  name?: string;
  onChange?: (val: boolean, name: string, setValue: (val: boolean) => void) => void;
  overrideFormik?: boolean;
  overrideSetValue?: boolean;
  size?: number;
  style?: {
    checkboxContainer?: React.CSSProperties;
  };
  [key: string]: any;
}

const noop = () => undefined;

function Checkbox(props: CheckboxProps) {
  const {
    checked = false,
    checkedColor = '',
    className,
    color = colors.black,
    disabled = false,
    label = '',
    labelIcon,
    labelMargin = '10px',
    labelSize = '16px',
    onChange = noop,
    overrideSetValue,
    size = 24,
    style = {},
    ...rest
  } = props;

  const [field, , helpers] = useField(props);
  const { name, value } = field;
  const isChecked = checked || (!Array.isArray(value) && value) || false;

  const handleClick = useCallback(() => {
    const val = !isChecked;

    if (!disabled) {
      if (!overrideSetValue) helpers.setValue(val);
      onChange(val, name, helpers.setValue);
    }
  }, [disabled, overrideSetValue, isChecked, onChange, props.name]);

  const iconStyles = {
    fill: color,
    width: size,
    height: size,
  };

  return (
    <StyledCheckbox
      className={className}
      onClick={handleClick}
      disabled={disabled}
      color={color}
      labelMargin={labelMargin}
      {...rest}>
      <CheckboxContainer disabled={disabled} style={style.checkboxContainer} size={size}>
        {isChecked ? (
          <CheckboxOnIcon {...iconStyles} fill={checkedColor || color} data-cy='checkboxOn' />
        ) : (
          <CheckboxOffIcon {...iconStyles} data-cy='checkboxOff' />
        )}
      </CheckboxContainer>
      <Label fontSize={labelSize}>{label}</Label>
      {!!labelIcon && <LabelIcon>{labelIcon}</LabelIcon>}
    </StyledCheckbox>
  );
}

export default Checkbox;

const StyledCheckbox = styled.div<{ disabled?: boolean; color?: string; labelMargin?: string }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: inline-flex;
  align-items: center;
  & label {
    margin-left: ${({ labelMargin }) => labelMargin};
    color: ${({ color }) => color};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
`;

// Don't remove min-width and min-height!
// I know it seems pointless but IE is dumb.
const CheckboxContainer = styled.div<{ size?: number; disabled?: boolean }>`
  display: flex;
  flex: 0;
  align-items: center;
  min-width: ${({ size }) => `${size}px`};
  min-height: ${({ size }) => `${size}px`};
  opacity: ${({ disabled }) => (disabled ? 0.25 : 1)};
`;

const LabelIcon = styled.div`
  margin-left: 5px;
`;
