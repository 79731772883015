/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import AttributeValuesFilterBar from '~/components/attributes/AttributeValuesFilterBar';
import AttributeValuesTable from '~/components/attributes/AttributeValuesTable';
import MainPage from '~/components/shared/pageLayout/MainPage';
import TabFilters from '~/components/shared/TabFilters';
import useTabs, { TabDef } from '~/lib/hooks/useTabs';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import colors from '~/styles/theme/colors';

import { Button } from '../shared/buttons';
import { PlusIcon } from '../shared/svg';

type AttributeValuesListProps = {
  tabDefs: TabDef[];
};

function AttributesValuesList({ tabDefs }: AttributeValuesListProps) {
  const profileSvc = useProfileContext();
  const canAddValue = profileSvc.has(PERMISSIONS.adminAttrValueCreate);
  const navigate = useNavigate();
  const navigateToNewValue = () => navigate('/attributes/values/new');
  const addValueButton = (
    <Button onClick={navigateToNewValue} icon={<PlusIcon size={14} fill={colors.white} />} text='Add Value' />
  );

  const tabinfo = useTabs(tabDefs, 'Values');

  return (
    <MainPage
      title='Values'
      headerWhite
      rightContent={canAddValue && addValueButton}
      headerContent={
        <Fragment>
          <AttributeValuesFilterBar />
          <TabFilters {...tabinfo} />
        </Fragment>
      }>
      <AttributeValuesTable />
    </MainPage>
  );
}

export default AttributesValuesList;
