import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useBlocker, useNavigate } from 'react-router-dom';

import SaveChangesModal from './SaveChangesModal';

type SaveChangesWrapperProps<T> = {
  bindShowModal: (showModal: () => void) => void;
  onSaveChangesConfirm: () => void;
  children?: ReactNode;
  historyBlockCondition?: boolean;
  component: React.ComponentType<T>;
} & T;

function SaveChangesWrapper<T>(props: SaveChangesWrapperProps<T>) {
  const {
    bindShowModal,
    historyBlockCondition = false,
    children,
    onSaveChangesConfirm,
    component: Component,
    ...rest
  } = props;

  const navigate = useNavigate();

  const [blockedPathname, setBlockedPathname] = useState<string | null>(null);
  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const confirmedLeaving = useRef<boolean>(false);

  const handleSaveChangesCancel = useCallback(() => {
    setShowSaveChangesModal(false);
  }, [setShowSaveChangesModal]);

  const handleSaveChangesConfirm = useCallback(() => {
    if (blockedPathname) {
      confirmedLeaving.current = true;
      navigate(blockedPathname);
    } else {
      onSaveChangesConfirm();
    }
    setShowSaveChangesModal(false);
  }, [blockedPathname, setShowSaveChangesModal, onSaveChangesConfirm, navigate]);

  useEffect(() => {
    bindShowModal(() => {
      setShowSaveChangesModal(true);

      // Clear the pathname in case it was shown via blocked logic before
      // so it's not inadvertently redirected on confirm of save changes.
      setBlockedPathname(null);
    });
  }, []);

  useBlocker(({ nextLocation }) => {
    if (historyBlockCondition && !confirmedLeaving.current) {
      setBlockedPathname(nextLocation.pathname);
      setShowSaveChangesModal(true);
      return true; // This blocks the navigation
    }
    return false; // This allows the navigation
  });

  return (
    <Component {...(rest as T)}>
      {children}
      <SaveChangesModal
        onCancel={handleSaveChangesCancel}
        onConfirm={handleSaveChangesConfirm}
        show={showSaveChangesModal}
      />
    </Component>
  );
}

export default SaveChangesWrapper;
