/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';

import AttributesFilterBar from '~/components/attributes/AttributesFilterBar';
import MainPage from '~/components/shared/pageLayout/MainPage';
import TabFilters from '~/components/shared/TabFilters';
import useTabs, { TabDef } from '~/lib/hooks/useTabs';
import { PERMISSIONS, useProfileContext } from '~/services/profile';
import colors from '~/styles/theme/colors';

import { Button } from '../shared/buttons';
import { PlusIcon } from '../shared/svg';

import AttributesTable from './AttributesTable';

type AttributeListProps = {
  tabDefs: TabDef[];
};

function AttributesList({ tabDefs, ...props }: AttributeListProps) {
  const profileSvc = useProfileContext();
  const canAddAttrs = profileSvc.has(PERMISSIONS.adminAttrCreate);
  const navigate = useNavigate();
  const navigateToNewAttribute = () => navigate('/attributes/new');
  const addAttributeButton = (
    <Button onClick={navigateToNewAttribute} icon={<PlusIcon size={14} fill={colors.white} />} text='Add Attribute' />
  );
  const tabinfo = useTabs(tabDefs, 'Attributes');

  return (
    <MainPage
      title='Attributes'
      headerWhite
      rightContent={canAddAttrs && addAttributeButton}
      headerContent={
        <Fragment>
          <AttributesFilterBar></AttributesFilterBar>
          <TabFilters {...tabinfo} />
        </Fragment>
      }>
      <AttributesTable {...props} />
    </MainPage>
  );
}

export default AttributesList;
