import React from 'react';
import styled from 'styled-components';

import { convertColorThemeToColors } from './colorTheme';

interface HeaderProps {
  children: React.ReactNode;
  colorTheme?: string;
  onCancel?: () => void;
  showCloseButton?: boolean;
  [key: string]: any; // To allow any other props
}

function Header({ children, onCancel = () => undefined, showCloseButton = true, ...rest }: HeaderProps) {
  return (
    <StyledHeader {...rest}>
      <HeaderContent>{children}</HeaderContent>
      {showCloseButton && (
        <CloseButton onClick={onCancel} {...rest}>
          &times;
        </CloseButton>
      )}
    </StyledHeader>
  );
}

export default Header;

const StyledHeader = styled.div.attrs(convertColorThemeToColors)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: none;
  flex-grow: 1;

  position: relative;
  height: 60px;
  padding: 20px;

  background-color: ${({ colors }) => colors.header.background};

  color: ${({ colors }) => colors.header.font};
  font-size: 24px;
  font-weight: 100;

  border-radius: 3px 3px 0px 0px;
`;

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CloseButton = styled.div.attrs(convertColorThemeToColors)`
  position: absolute;
  right: 0px;
  top: 0px;

  height: 48px;
  width: 48px;

  color: ${({ colors }) => colors.header.closeButton};
  font-size: 32px;
  font-weight: 100;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;
