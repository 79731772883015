import React, { useCallback, useContext, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';

import { Button, ButtonGroup } from '~/components/shared/buttons';
import { MainPage } from '~/components/shared/pageLayout';
import { SaveChangesWrapper } from '~/components/shared/SaveChangesModal';
import { Client } from '~/models';

import CareOptionsTabs from './CareOptionsTabs';
import { ConfigureCareOptionsContext } from './context';
import { ReducedConfigs } from './types';

export interface ConfigureCareOptionsMainPageProps {
  children?: React.ReactElement<{ selectedTabValue: string | null }>;
  client: Client;
  onTabChanged: (selectedValue: string) => void;
  onTabClick: (selectedValue: string) => void;
  selectedTabValue?: string;
}

function ConfigureCareOptionsMainPage(props: ConfigureCareOptionsMainPageProps) {
  const { children, client, onTabClick, onTabChanged } = props;

  const {
    dirty,
    isSubmitting,
    isValid,
    resetForm,
    status: { disabled },
    submitForm,
  } = useFormikContext<ReducedConfigs>();

  const selectedTabValue = useContext(ConfigureCareOptionsContext);
  const [showModalFunc, setShowModalFunc] = useState<() => void>(() => () => undefined);
  const discardDisabled = useMemo(() => disabled || !dirty || isSubmitting, [dirty, isSubmitting]);
  const saveDisabled = useMemo(() => disabled || !dirty || isSubmitting || !isValid, [dirty, isSubmitting, isValid]);
  const handleDiscardClick = useCallback(() => showModalFunc(), [showModalFunc]);
  const handleBindShowModal = useCallback(
    (func: () => void) => {
      setShowModalFunc(() => func);
    },
    [setShowModalFunc]
  );

  const buttonGroup = useMemo(
    () => (
      <ButtonGroup>
        <Button color='transparent' disabled={saveDisabled} onClick={handleDiscardClick} text='Discard Changes' />
        <Button
          data-cy='saveConfigureCareOptionsButton'
          disabled={discardDisabled}
          onClick={submitForm}
          text='Save Changes'
        />
      </ButtonGroup>
    ),
    [saveDisabled, handleDiscardClick, discardDisabled, submitForm]
  );

  return (
    <SaveChangesWrapper
      component={MainPage}
      title='Configure Care Options'
      onSaveChangesConfirm={resetForm}
      bindShowModal={handleBindShowModal}
      headerWhite
      historyBlockCondition={dirty || isSubmitting}
      rightContent={buttonGroup}
      subtitle={`${client.name}`}
      headerContent={
        <CareOptionsTabs
          client={client}
          onTabChanged={onTabChanged}
          onTabClick={onTabClick}
          selectedTabValue={selectedTabValue}
        />
      }>
      {React.Children.map(
        children,
        (child) =>
          child &&
          React.cloneElement(child, {
            selectedTabValue,
          })
      )}
    </SaveChangesWrapper>
  );
}

export default ConfigureCareOptionsMainPage;
