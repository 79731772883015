import { addToast } from '~/ducks/toasts';
import { createAsyncThunk } from '~/lib';
import { Classification } from '~/models';
import { Paginated } from '~/models/Paginated';
import { adminClassificationsApi } from '~/services/api';

export const sliceName = 'admin/classifications';

export const fetchClassifications = createAsyncThunk<Paginated<Classification>>(
  `${sliceName}/fetch`,
  async (params) => {
    const defaults = { pageSize: 1000, sortBy: 'name asc' };
    const res = await adminClassificationsApi.fetch.invoke({ ...defaults, ...params });

    return res.data;
  },
  {
    defaultValue: [],
    modelClass: Classification,
  } as any
);

export const fetchClassification = createAsyncThunk(
  `${sliceName}/fetchById`,
  async (id) => {
    const res = await adminClassificationsApi.fetchById.invoke(id);

    return res.data;
  },
  {
    modelClass: Classification,
  } as any
);

export const createClassification = createAsyncThunk(`${sliceName}/create`, async (params, { dispatch }) => {
  const res = await adminClassificationsApi.create.invoke(params).catch((e) => {
    const errorMessage = e.response?.data?.error || 'There was an error creating the classification.';

    dispatch(addToast({ text: errorMessage }));
    throw e;
  });

  return res.data;
});

export const updateClassification = createAsyncThunk(`${sliceName}/update`, async (params, { dispatch }) => {
  const res = await adminClassificationsApi.update.invoke(params.id, params).catch((e) => {
    const errorMessage = e.response?.data?.error || 'There was an error updating the classification.';

    dispatch(addToast({ text: errorMessage }));
    throw e;
  });

  return res.data;
});
