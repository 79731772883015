import QuestionInput, { Form, QuestionInputProps } from './QuestionInput';

type Props = QuestionInputProps & {
  form: Form;
};

const NotesQuestion = (props: Props) => {
  const {
    field: { name, value },
    form,
  } = props;

  const handleConfigChange = (inputVal: string) => {
    form.setFieldValue(name, { ...value, config: { optional: inputVal === 'yes' } });
  };

  return (
    <QuestionInput {...props} configLabel='Optional?' configName='optional' handleConfigChange={handleConfigChange} />
  );
};

export default NotesQuestion;
